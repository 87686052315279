import { Button, Input, InputNumber, message, Modal, Select, Tabs, Tooltip, Upload } from "antd"
import React,{useEffect,useState,useContext} from "react"
import {  StateContext } from '../../../state';
import { useHistory, } from 'react-router-dom'
import { UploadOutlined,EnvironmentOutlined} from '@ant-design/icons';
import http from "../../../../../utils/server";
import { StateDeviceContext } from '../../../state_device';
import { Mode } from "../../../../../App";
import smoke from '../../../../../../public/assets/Smoke.png'
import smokeWarn from '../../../../../../public/assets/SmokeWarn.png'
import './plane.less'

function Plane(props) {
    const { state_device, dispatch_device } = useContext(StateDeviceContext)
    const { state, dispatch } = useContext(StateContext)
    const[disID, setDisID] = useState(0);//区域ID
    const[devices, setDevices] = useState(0);//区域内设备
    const[editVisb, setEditVisb] = useState(false);//编辑窗口状态
    const[backImgURL, setBackImgURL] = useState('');//平面图片地址
    const[xRelative, setXRelative] = useState(0);//添加测点的X坐标
    const[yRelative, setYRelative] = useState(0);//添加测点的Y坐标
    const[newVisb, setNewVisb] = useState(false);//添加测点弹窗
    const[deviceInfo, setDeviceInfo] = useState({fontColor:'blue',fontSize:18});//当前测点的信息
    const[devicesInfo, setDevicesInfo] = useState([]);//平面图内所有测点的信息
    const[addMapEle, setAddMapEle] = useState({});
    const[planeName, setPlaneName] = useState('');
    const[planeIndex, setPlaneIndex] = useState(-1);
    const[planesInfo, setPlanesInfo] = useState([]);//获取的所有平面图信息
    const[showWidth, setShowWidth] = useState(0);
    const[showHeight, setShowHeight] = useState(0);
    const history = useHistory();
    const showPlane = React.createRef();
    const { TabPane } = Tabs;
    let interval = '';
    useEffect(()=>{
        // console.log(process.env);
        let disID = props.match.params.id*1;
        setDisID(disID);
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                var temp=data.data.filter(x=>x.district===disID);
                setDevices(temp);
            } 
        })
        http.get("/v1/layoutdis?district="+disID, {}).then(data => {
            if (data.code === 0) {
                setPlanesInfo(data.data);
                if(data.data&&data.data.length>0){
                    let img = Mode==='soc'? data.data[0].image: 'http://127.0.0.1:8086/'+data.data[0].image;
                    let imgObj = new Image();
                    imgObj.src = img;
                    setShowWidth(imgObj.width)
                    setShowHeight(imgObj.height)
                }
            } 
        })
        http.get("/v1/devicestatus", {}).then(data => {
            if (data.code === 0 && data.data != null) {
                dispatch_device({ type: 'devicestate', devicestate: data.data });
            }
        })
        clearInterval(interval);
        interval = setInterval(() => {
            http.get("/v1/devicestatus", {}).then(data => {
                if (data.code === 0 && data.data != null) {
                    dispatch_device({ type: 'devicestate', devicestate: data.data });
                }
            })
        }, 10000)
        return () => clearInterval(interval)

       
    },[])
    // useEffect(()=>{
    //     showPlane.current&&setShowWidth(showPlane.current.scrollWidth);
    //     showPlane.current&&setShowHeight(showPlane.current.scrollHeight);
    // },[showPlane])
    // useEffect(()=>{
    //     if(state.district&&state.district.length>0){
    //         let img = Mode==='soc'? state.district[0].image: 'http://127.0.0.1:8086/'+state.district[0].image;
    //         let imgObj = new Image();
    //         imgObj.src = img;
    //         setShowWidth(imgObj.width)
    //         setShowHeight(imgObj.height)
    //     }
    // },[showWidth,showHeight])

    function handleChange(info) {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            setBackImgURL(info.file.response.data.path);
            // dispatchUser({ type: 'update', user: { ...actUser, logo: info.file.response.data.path } })
        }
    };
    return(
        <div style={{padding:10}}>
            
            {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div></div>
                <div style={{textAlign:'center',fontSize:14}}><span >平面图</span></div>
                <Button type="text" style={{borderRadius:10}} onClick={()=>history.push('/monitor/data')}>返回</Button>
            </div> */}
            <Tabs destroyInactiveTabPane tabBarExtraContent={<Button type="text" style={{borderRadius:10}} onClick={()=>history.push('/monitor/data')}>返回</Button>}
                onChange={e=>{
                    let img =  Mode==='soc'?planesInfo[e].image:'http://127.0.0.1:8086/'+planesInfo[e].image;
                    let imgObj = new Image();
                    imgObj.src = img;
                    if(imgObj.width!==0){
                        setShowWidth(imgObj.width)
                        setShowHeight(imgObj.height)
                    }
                }}
            >
                {
                    planesInfo.map((item,index)=>{
                        return(
                            <TabPane tab={item.name} key={index}>
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <div style={{width:'144vh', height:'81vh',position:'relative',overflow:'scroll',padding:0,margin:0}} ref={showPlane} id={'show'+index}>
                                    <img src={Mode==='soc'?item.image:'http://127.0.0.1:8086/'+item.image } alt="" srcset="" />
                                    {
                                        item.layout!==''&&
                                        JSON.parse(item.layout).map((layItem,layIndex)=>{
                                            
                                            if(state_device.devicestate.length>0){
                                                let tempDev =state_device.devicestate.filter(x=>x.ID===layItem.devID)[0]
                                                console.log(tempDev);
                                                return(
                                                    <Tooltip title={tempDev.MName+'-'+tempDev.Name}>
                                                        {
                                                            layItem.devSensorType===1?
                                                            <div style={{position:'absolute',left:layItem.xPercent*showWidth,top:layItem.yPercent*showHeight,width:80,height:60,display:'flex',alignItems:'center',flexDirection:'row',alignItems:'center',justifyContent:'space-between',zIndex:99}} key={layIndex}>
                                                                {/* <img style={{ width: 30, height: 30 }}src={ smoke } alt=""  className ={tempDev.Sensor.Smoke?'':'somkerWarning'}/> */}
                                                                {
                                                                    tempDev.Sensor.Smoke ?
                                                                    <img style={{ width: 30, height: 30 }}src={ smokeWarn } alt="" className ='somkerWarning' />
                                                                    :
                                                                    <img style={{ width: 30, height: 30 }}src={ smoke } alt=""  />
                                                                }
                                                                <div style={{color:tempDev.Sensor.Smoke? 'orange' :layItem.fontColor,fontSize:layItem.fontSize}}>
                                                                {/* <div style={{color:layItem.fontColor,fontSize:layItem.fontSize}} className ={tempDev.Sensor.Smoke?'':'somkerWarning'} > */}
                                                                    {tempDev.Sensor.Smoke?'报警':'正常'}
                                                                </div>
                                                            </div>
                                                            :
                                                            <div style={{position:'absolute',left:layItem.xPercent*showWidth,top:layItem.yPercent*showHeight-10,width:80,height:60}} key={layIndex}>
                                                                {/* <div style={{color:layItem.fontColor}} >{tempDev.Name}</div> */}
                                                                <div style={{display:'flex',alignItems:'center',flexDirection:'row',alignItems:'center',justifyContent:'space-between',height:60}}>
                                                                    <Button type="text"  icon={<EnvironmentOutlined style={{color:(tempDev.Sensor.TempAlarm||tempDev.Sensor.HumiAlarm)? 'red' :(tempDev.Sensor.TempWarning||tempDev.Sensor.HumiWarning)?'orange':layItem.fontColor,fontSize:layItem.fontSize}} /> } /> 
                                                                    <div>
                                                                        <div style={{color:tempDev.Sensor.TempAlarm? 'red' :tempDev.Sensor.TempWarning?'orange':layItem.fontColor,fontSize:layItem.fontSize}}>{tempDev.Sensor.TempValue>102?'--':tempDev.Sensor.TempValue}{tempDev.Type===205?'Pa':'℃'}</div>
                                                                        {
                                                                            tempDev.Type!==205&&tempDev.Humi===1&&
                                                                            <div style={{color:tempDev.Sensor.HumiAlarm? 'red' :tempDev.Sensor.HumiWarning?'orange':layItem.fontColor,fontSize:layItem.fontSize}}> {tempDev.Sensor.HumiValue>102?'--':tempDev.Sensor.HumiValue}%RH</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        }
                                                        
                                                    </Tooltip>
                                                )
                                            }
                                            
                                        })
                                    }
                                    </div>
                                </div>
                            </TabPane>
                        )
                    })
                }
            </Tabs>
            <Modal title='新建平面图' width={1000} zIndex={9}
                visible={editVisb}
                style={{top:60}}
                destroyOnClose={true}
                onCancel={()=>setEditVisb(false)}
                onOk={()=>{
                    if(planeName==='') return message.error('平面图名称为空');
                    http.post('/v1/layout',{ name:planeName, layout:JSON.stringify(devicesInfo),index:planeIndex,district:disID ,image:backImgURL})
                    .then(data=>{
                        if(data.code===0){
                            http.get("/v1/layoutdis?district="+disID, {}).then(data => {
                                if (data.code === 0) {
                                    setPlanesInfo(data.data);
                                } 
                            })
                        }
                    })
                    setEditVisb(false);
                }}
            >
                <div>
                    {/* 上传平面图 */}
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <span style={{width:300}}>平面图名称: <Input style={{width:200}} onChange={val=>setPlaneName(val.target.value)}/></span>
                        <span>显示顺序: <InputNumber min={0} onChange={val=>val!=null&&setPlaneIndex(val)}/></span>
                        
                        <Upload
                            name="avatar"
                            // listType="picture-card"
                            // className="avatar-uploader"
                            showUploadList={false}
                            headers={{ 'Authorization': `Bearer ${localStorage.getItem('token')}` }}
                            action="/v1/upimage"
                            // beforeUpload={beforeUpload}
                            onChange={handleChange}
                        >
                            {
                                
                                <Button icon={<UploadOutlined />}>上传平面图</Button>
                            }
                        </Upload>
                    </div>
                    <div style={{height:'70VH',marginTop:10, backgroundImage: `url(http://wsd.hbpckj.com/${backImgURL})`,backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',position:'relative'}} id='view'
                         onContextMenu={e=>{
                            e.preventDefault();
                            let tempEle= document.getElementById('view');
                            setAddMapEle(tempEle);
                            let menuListEle= document.getElementById('menuList');
                            let tempEleX = Number.parseInt(tempEle.getBoundingClientRect().x);
                            let tempEleY = Number.parseInt(tempEle.getBoundingClientRect().y);
                            let xRelative = e.clientX-tempEleX;
                            let yRelative = e.clientY-tempEleY;
                            setXRelative(xRelative);
                            setYRelative(yRelative);
                            let xPercent = xRelative/tempEle.getBoundingClientRect().width;
                            let yPercent = yRelative/tempEle.getBoundingClientRect().height;
                            setDeviceInfo({...deviceInfo,xPercent:xPercent,yPercent:yPercent});
                            menuListEle.style.display='block';
                        }}
                    >
                        {
                            devicesInfo&&devicesInfo.map((item,index)=>{
                                return(
                                    <div style={{position:'absolute',left:item.xPercent*addMapEle.getBoundingClientRect().width-50,top:item.yPercent*addMapEle.getBoundingClientRect().height-30,width:100,height:30,display:'flex',alignItems:'center',flexDirection:'column'}} key={index}>
                                        <span style={{color:item.fontColor,fontSize:item.fontSize}} >25℃ 60%HF</span>
                                        <Button type="text"  icon={<EnvironmentOutlined  style={{color:item.fontColor,fontSize:item.fontSize}}/> } /> 
                                    </div>
                                   
                                )
                            })
                        }
                        <div style={{position:'absolute',left:xRelative,top:yRelative,width:50,height:50,display:'none'}} id="menuList">
                            <Button onClick={()=>{
                                setNewVisb(true)
                                let menuListEle= document.getElementById('menuList');
                                menuListEle.style.display='none';
                            }}>创建测点</Button>
                            <Button>修改测点</Button>
                            <Button>删除测点</Button>
                        </div>
                    </div>
                    
                </div>
            </Modal>
            <Modal title='创建测点' visible={newVisb} 
                onCancel={()=>setNewVisb(false)}
                onOk={()=>{
                    if(deviceInfo.devID===undefined) return message.error('没有选择测点');
                    setNewVisb(false);
                    let tempArr=devicesInfo;
                    tempArr.push(deviceInfo)
                    setDevicesInfo(tempArr);                   
                }}
            >
                <div>
                    <div>
                    <span>显示颜色</span>
                    <Select style={{width:100,marginLeft:10}}
                       defaultValue='blue'
                       onChange={val=>setDeviceInfo({...deviceInfo,fontColor:val})}
                    >
                        <Select.Option key={1} value='black'>黑色</Select.Option>
                        <Select.Option key={2} value='white'>白色</Select.Option>
                        <Select.Option key={3} value='blue'>蓝色</Select.Option>
                        <Select.Option key={4} value='green'>绿色</Select.Option>
                        <Select.Option key={5} value='brown'>棕色</Select.Option>
                    </Select>
                    </div>
                    <div>
                    <span>字体大小</span>
                    <Select style={{width:100,marginLeft:10}}
                       defaultValue={18}
                       onChange={val=>setDeviceInfo({...deviceInfo,fontSize:val})}
                    >
                        <Select.Option key={1} value={10}>小</Select.Option>
                        <Select.Option key={2} value={14}>中</Select.Option>
                        <Select.Option key={3} value={18}>大</Select.Option>
                    </Select>
                    </div>
                    <span>选择测点</span>
                    <Select style={{width:300,marginLeft:10}}
                        onChange={val=>setDeviceInfo({...deviceInfo,devID:val*1})}
                    >
                        {
                            devices&&
                            devices.map(item=>(
                                <Select.Option key={item.id} value={item.id}>
                                    {item.m_name+'-'+item.name}
                                </Select.Option>
                            ))
                        }
                    </Select>

                </div>
            </Modal>
            
        </div>
    )
}

export default Plane