import React, { useState, useContext, useEffect } from 'react'
import { Table, DatePicker, Select, Button, message, Modal, Form, Input, Tag, Space, Cascader,TreeSelect,Switch,Tooltip, Dropdown,Menu} from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { Html5TwoTone, SearchOutlined ,SyncOutlined} from '@ant-design/icons';
import moment from 'moment';
import { Mode } from '../../../../../App';
import audioSrc from '../../../../../../public/assets/warning.mp3'
const { RangePicker } = DatePicker;

function exportCsv(obj) {
    var title = obj.title;
    var titleForKey = Object.keys(obj.data[0]);
    var data = obj.data;
    var str = [];
    str.push(obj.title.join(",") + "\n");
    for (var i = 0; i < data.length; i++) {
        var temp = [];
        for (var j = 0; j < titleForKey.length; j++) {
            temp.push(data[i][titleForKey[j]]);
        }
        str.push(temp.join(",") + "\n");
    }
    var blob = new Blob(['\uFEFF' + str.join('')], {
        type: 'text/plain;charset=utf-8',
    });
    var downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "export.csv";
    downloadLink.click();
}

function findUserDis(nop, list, districts,userDis) {
    if (list&&districts) {
        const nops = list.filter(x => { return x.parent === nop })
        if (nops.length === 0) {
            return
        } else {
            for (var j = 0; j < nops.length; j++) {
                let tempDis=districts.filter(x=>x.user===nops[j].id);
                tempDis.length>0&&userDis.push(...tempDis);
                findUserDis(nops[j].id, list,districts,userDis);
            }
        }
    }
}
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};
const alarmtype = [
    '设备电源断电报警',
    '设备电源恢复报警',
    '设备监测开门报警',
    '设备监测关门报警',
    '探头电源异常报警',
    '探头电源恢复正常',
    '探头监测开门报警',
    '探头监测关门报警',
    '探头连接失败报警',
    '探头连接恢复正常',
    '探头温度超标报警',
    '探头温度恢复报警',
    '探头湿度超标报警',
    '探头湿度恢复报警',
    '探头温度预警',
    '探头湿度预警',
    '探头压强超标报警',
    '探头压强恢复报警',
]

function Alarm() {
    const [form] = Form.useForm();
    const { state, dispatch } = useContext(StateContext)
    // const [selDevice, setSelDevice] = useState([]);//选择区域内同主机的测点
    // const [selDeviceNodes, setSelDeviceNodes] = useState([]);//测点方式查询 选择的测点设备
    // const [alarmDevOption, setAlarmDevOption] = useState([]);//区域内主机的选项
    // const [selAlarmDev, setSelAlarmDev] = useState('');//主机方式查询 选择的主机设备
    // const [disNodes, setDisNodes] = useState([])//选择区域内的所有测点
    // const [nodeFliters, setNodeFliters] = useState([])//所有测点
    // const [devFliters, setDevFliters] = useState([])//所有主机
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [data, setData] = useState([]);//显示的报警数据
    const [dataSave, setDataSave] = useState([]);//保存查询的报警数据
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);//选中的关键值
    const [showDeal, setShowDeal] = useState(false);
    const [dealList, setDealList] = useState([]);
    const [showDealMsgBox, setShowDealMsgBox] = useState(false);
    const [showDealMsg, setShowDealMsg] = useState([]);
    const [showData, setShowData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [defExpend,setDefExpend]=useState([]);//树默认展示列表
    const [selUser, setSelUser] = useState(0);//树选择的用户
    const [selUserDis, setSelUserDis] = useState([]);//用户关联的区域 用户为空选择全部区域
    const [selDis, setSelDis] = useState(0);//选择的区域
    const [closeInterval, setCloseInterval] = useState(false)
    const [selDev,setSelDev] = useState('');
    const [selNode,setSelNode] = useState('');
    let interval;
    function autoRefresh(checked) {
        if (checked) {
          setCloseInterval(false)
        } else {
          setCloseInterval(true)
        }
    }

    function getAlarmData(){
        let audioEle = document.getElementById('audio');
        setIsLoading(true);
        var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
        let url = "/v1/alarm?" ;
        if(selUser!==0){
            if(selDis!==0){
                url+=`user=${selUser}&district=${selDis}&`
            }else{
                url+=`user=${selUser}&`
            }
        }else{
            selDis!==0&&(url=`/v1/alarm?district=${selDis}&`);
        }
        // url+=selUser!==0?`user=${selUser}&`:`user=${state.main.id}&`;
        startTime === ''? url += "starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
        endTime===''? url+="starttime=" + startTime + "&endtime=" + formatDateTimes(new Date().getTime()):
        url+="starttime=" + startTime + "&endtime=" + endTime;
        http.get(url,{}).then((data) => {
            setData([]);
            setDataSave([]);
            setIsLoading(false);
            if (data.code === 0) {
                if(data.data.findIndex(x=>x.status===0)!==-1){
                    !state.alarmSound&&audioEle.play();
                    }
                for (var i = 0; i < data.data.length; i++) {
                    data.data[i]['key'] = i;
                }
                let tempData = data.data.filter(x=>x.type<14||x.type>15)
                tempData.sort((a,b)=>{
                    if(a.alarm_date===b.alarm_date){
                        return b.id -a.id;
                    }else{
                       return new Date(b.alarm_date).getTime()-new Date(a.alarm_date).getTime()
                    }
                });

                setData(tempData);
                setDataSave(tempData);
            }
        })
    }
    useEffect(() => {
        let audioEle = document.getElementById('audio');
        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data });
                setSelUserDis(data.data);
            }
        })
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                let temp = data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh'))
                dispatch({ type: 'systemuser', systemuser: temp })
                if(data.data.length>0){
                    var userLogin =state.main;
                    var list = [];
                    temp.filter(x=>x.parent===userLogin.id).map(m=>list.push(m.id+""));
                    setDefExpend(list);         
                } 
            }
        })
        http.get("/v1/userdistricts", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'user_district', user_district: data.data })
            }
        })
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'device', device: data.data })
                let arrDev = new Array();
                let arrDevice = new Array();
                data.data.map(m => {
                    arrDev.push({ label: m.name+'-'+m.sn, value: m.id,sn:m.sn });
                    arrDevice.findIndex(x => x.value === m.sn) === -1 && arrDevice.push({ label: m.m_name+'-'+m.sn, value: m.sn });
                })
                // setNodeFliters(arrDev);
                // setDisNodes(arrDev);
                // setAlarmDevOption(arrDevice);
                // setDevFliters(arrDevice);
            }
        })

        http.get("/v1/alarmday?day=" + formatDateTimes(new Date()), {}).then(data => {
            setIsLoading(false);
            if (data.code === 0) {
                if(data.data.findIndex(x=>x.status===0)!==-1){
                    !state.alarmSound&&audioEle.play();
                  }
                for (var i = 0; i < data.data.length; i++) {
                    data.data[i]['key'] = data.data[i].id;
                }
                let tempData = data.data.filter(x=>x.type<14||x.type>15)
                tempData.sort((a,b)=>b.id-a.id)
                setData(tempData);
                setDataSave(tempData);
            }
        })
        // return () => clearInterval(timer);
    }, []);
    useEffect(() => {
        clearInterval(interval);
        interval = setInterval(() => {
          if (!closeInterval) {
            getAlarmData()
          } else {
            clearInterval(interval)
          }
        }, 5*60*1000)
        return () => clearInterval(interval)
      }, [closeInterval,startTime,endTime,selUser,selDis])
    
    const columns = [
        {
            title: '报警时间',
            dataIndex: 'alarm_date',
            // key: 'id',
            align: 'center',
            width: 100
        },
        Mode!=='soc'?{width:0}:{
            title: '所属用户',
            dataIndex: 'user',
            // key: 'id',
            align: 'center',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='用户信息'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record.user ? record.user.indexOf(value.trim()) !== -1 : ''
        },
        {
            title: '所属区域',
            dataIndex: 'districtname',
            // key: 'id',
            align: 'center',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='区域信息'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => record.districtname ? record.districtname.indexOf(value.trim()) !== -1 : ''
        },
        {
            title: '主机',
            dataIndex: 'device',
            // key: 'id',
            align: 'center',
            render: (text, record) => (
                <div>
                    <div>{record.m_name}</div>
                    <div style={{ fontSize: 10, color: '#aaa' }}>编号:{record.device}</div>
                </div>
            ),
            sorter: {
                compare: (a, b) => a.node - b.node,
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>{
                return (
                    <div style={{ padding: 8 }}>
                        <Input
                            placeholder='主机信息'
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => {
                                setSelDev(selectedKeys[0])
                                confirm()
                            }}
                            style={{ marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button size="small" style={{ width: 90 }} onClick={() => {
                                setSelDev('');
                                clearFilters()
                            }}>
                                重置
                            </Button>
                            <Button
                                type="primary"
                                onClick={() =>{ 
                                    // let info = state.device.filter(x=>x.sn.toString().indexOf(selectedKeys[0])!==-1||x.m_name.indexOf(selectedKeys[0])!==-1);

                                    // setSelDev(info.length>0?info[0].m_name:'')
                                    setSelDev(selectedKeys[0]?selectedKeys[0]:'')
                                    confirm()
                                }}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                查找
                            </Button>
    
                        </Space>
                    </div>
                )
            }
            ,
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            // record.type ? alarmtype[record.type].includes(value) : ''
            onFilter: (value, record) => {
                return record.device ? record.device.toString().indexOf(value.trim()) !== -1 || record.m_name.toString().indexOf(value.trim()) !== -1 : ''
            }
        },
        {
            title: '测点',
            dataIndex: 'node',
            // key: 'id',
            align: 'center',
            render: (text, record) => (
                record.node===0? <div>--</div>:
                <div>
                    <div>{record.name}</div>
                    <div style={{ fontSize: 10, color: '#aaa' }}>编号:{record.node}</div>
                </div>
            ),
            sorter: {
                compare: (a, b) => a.node - b.node,
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='测点信息'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => {
                            setSelNode(selectedKeys[0])
                            confirm()
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                setSelNode(selectedKeys[0])
                                confirm()
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            // record.type ? alarmtype[record.type].includes(value) : ''
            onFilter: (value, record) => record.node ? record.node.toString().indexOf(value.trim()) !== -1 || record.name.toString().indexOf(value.trim()) !== -1 : ''
        },
        {
            title: '报警类型',
            dataIndex: 'type',
            // key: 'type',
            align: 'left',
            width: 150,
            render: (text, record) => {
                return(
                    <div>{alarmtype[record.type]}</div>
                )
            },
            sorter: {
                compare: (a, b) => a.type - b.type,
            },
            filters: [
                {
                    text: '设备电源断电报警',
                    value: 0,
                },
                {
                    text: '设备电源恢复报警',
                    value: 1,
                },
                {
                    text: '设备监测开门报警',
                    value: 2,
                },
                {
                    text: '设备监测关门报警',
                    value: 3,
                },
                {
                    text: '探头电源异常报警',
                    value: 4,
                },
                {
                    text: '探头电源恢复正常',
                    value: 5,
                },
                {
                    text: '探头监测开门报警',
                    value: 6,
                },
                {
                    text: '探头监测关门报警',
                    value: 7,
                },
                {
                    text: '探头连接失败报警',
                    value: 8,
                },
                {
                    text: '探头连接恢复正常',
                    value: 9,
                },
                {
                    text: '探头温度超标报警',
                    value: 10,
                },
                {
                    text: '探头温度恢复报警',
                    value: 11,
                },
                {
                    text: '探头湿度超标报警',
                    value: 12,
                },
                {
                    text: '探头湿度恢复报警',
                    value: 13,
                },
                // {
                //     text: '探头湿度下限',
                //     value: 14,
                // },
                // {
                //     text: '探头湿度上限',
                //     value: 15,
                // },
                {
                    text: '探头压强超标报警',
                    value: 16, 
                },
                {
                    text: '探头压强恢复报警',
                    value: 17,
                },
            ],
            onFilter: (value, record) => {
                return  record?record.type === value :''
                // if(record.type===12){
                //     if(record.humi>record.humi_h){
                //         return true
                //     }
                //     // if(record.humi<record.humi_l){
                //     //     return true
                //     // }
                // }else{
                //     return  record?record.type === value :''
                // }
                
            }
        },
        {
            title: '记录值',
            // dataIndex: 'temp',
            // key: 'temp',
            align: 'center',
            width: 180,
            render: (text, record) =>{ 
                if(record.type===10||record.type===11||record.type===14){
                    return(
                        <div>
                            <div>{record.temp}℃</div>
                            <div style={{ fontSize: 10, color: '#aaa' }}>上限:{record.temp_h}℃-下限:{record.temp_l}℃</div>
                        </div>
                    )
                }
                if(record.type===12||record.type===13||record.type===15) return (
                    <div>
                        <div>{record.humi}%RH</div>
                        <div style={{ fontSize: 10, color: '#aaa' }}>上限:{record.humi_h}%RH-下限:{record.humi_l}%RH</div>
                    </div>
                )
                if(record.type===16||record.type===17) return(
                    <div>
                        <div>{record.temp}Pa</div>
                        <div style={{ fontSize: 10, color: '#aaa' }}>上限:{record.temp_h}Pa-下限:{record.temp_l}Pa</div>
                    </div>
                )
            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            // key: 'status',
            align: 'center',
            width: 100,
            sorter: {
                compare: (a, b) => a.status - b.status,
            },
            render: (text, record) => (
                record.status === 0 ? <Tag color={'red'}>未处理</Tag> :
                <Dropdown overlay={(
                    <Menu>
                      <Menu.Item>
                        <div>报警原因：{showDealMsg.why || "未知"}</div>
                      </Menu.Item>
                      <Menu.Item >
                        <div>处理人员：{showDealMsg.who || "未知"}</div>
                      </Menu.Item>
                      <Menu.Item >
                        <div>处理结果：{showDealMsg.deal || "未知"}</div>
                      </Menu.Item>
                    </Menu>
                  )}>
                    <a className="ant-dropdown-link" onClick={e => {
                        e.preventDefault() ;
                        setShowDealMsg(JSON.parse(record.msg));
                        setShowData(record);
                        setShowDealMsgBox(true)
                        }}
                        onMouseOver={()=>setShowDealMsg(JSON.parse(record.msg))}
                        >已处理</a>
                </Dropdown>
            ),
            filters: [
                {
                    text: '未处理',
                    value: 0
                },
                {
                    text: '已处理',
                    value: 1
                },
            ],
            onFilter: (value, record) => record.status === value
        }
    ]
    const onSelectChange = (selectedRowKeys,selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: record.status === 1, // Column configuration not to be checked
        }),
    };
    return (
        <>
            <div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5, padding: 10,}}>
                    <audio id="audio"  src={audioSrc} />
                    {/* <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10 }}>选择用户</span> */}
                    {
                        Mode==='soc'&&<TreeSelect
                        showSearch
                        style={{ width: 400 }}
                        dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                        placeholder="选择用户"
                        allowClear
                        // multiple
                        optionFilterProp="label"
                        treeDefaultExpandedKeys={defExpend}
                        onChange={value=> {
                            if(value===undefined||value===0){//清空用户选择
                                setData(dataSave);//默认全部
                                setSelUserDis(state.district);//清空已选择用户的区域
                                setSelUser(0);//清空选择用户给区域选择提供判定条件
                            }else{
                                setSelUser(value);
                                let userDis = state.district.filter(x=>x.user===value);
                                findUserDis(value,state.systemuser.list,state.district,userDis);
                                let temp=[];
                                setSelUserDis(userDis);
                                userDis.map(item=>{
                                    temp= temp.concat(dataSave.filter(x=>x.district === item.id));
                                })
                                setData(temp.sort((a,b)=>new Date(b.alarm_date).getTime()-new Date(a.alarm_date).getTime()));
                            }  
                            
                        }}
                        // treeData={[{title:'全部',value:'全部'}].concat(state.systemuser.tree)}
                        treeData={state.systemuser.treeM}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        />
                    }
                    <Select allowClear showSearch optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        // value={selDis}
                        // mode="multiple"
                        style={{ width: 360, marginLeft: 10 }}
                        placeholder="选择区域"
                        onChange={val=>{
                            let temp=[];
                            setSelDis(0);
                            if((val===0||val===undefined)&&selUser===0){
                                temp=dataSave;
                            }else if((val===0||val===undefined)&&selUser>0){
                                selUserDis.map(item=>{
                                    temp= temp.concat(dataSave.filter(x=>x.district === item.id));
                                })
                            }else if(val>0){
                                setSelDis(val);
                                temp= dataSave.filter(x=>x.district === val);
                            }
                            setData(temp);
                        }}
                    >
                        {
                            (()=>{
                                let disData=selUserDis;
                                return disData.map(item => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))
                            })()
                        }
                    </Select>
                    {/* <Cascader style={{ width: 300, marginLeft: 10 }}
                        options={userdistrictlist} expandTrigger="hover" placeholder="选择区域" autoFocus onChange={val => {
                            setSelAlarmDev('');
                            setSelDeviceNodes([]);
                            setSelDevice([]);
                            setDisNodes(nodeFliters);
                            setAlarmDevOption(devFliters);
                            if(val.length===0) return
                            let arrDev = new Array();
                            let arrDevice = new Array();
                            state.device.filter(x => x.district === val[val.length - 1].slice(3)*1).sort((a, b) => a.sn - b.sn).map(m => {
                                arrDev.push({ label: m.name+'-'+m.sn, value: m.id,sn:m.sn });
                                arrDevice.findIndex(x => x.value === m.sn) === -1 && arrDevice.push({ label: m.m_name+'-'+m.sn, value: m.sn });
                            })
                            if (arrDev.length === 0) {
                                message.info('该区域下暂无设备')
                                setDisNodes([]);
                                setAlarmDevOption([]);
                            } else {
                                setDisNodes(arrDev);
                                setAlarmDevOption(arrDevice);
                            }
                            
                        }} showSearch={(inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)} 
                        displayRender={label=>label[label.length - 1]}
                        /> */}
                    {/* <span style={{ fontSize: 14, marginLeft: 20 }}>测点查询</span>
                    <Select allowClear showSearch 
                        mode="multiple"
                        style={{ width: 300, marginLeft: 10 }}
                        value={selDeviceNodes}
                        placeholder="选择测点"
                        optionFilterProp="children"
                        disabled = {selAlarmDev!==undefined &&selAlarmDev!==""}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={d => {
                            setSelDeviceNodes(d);
                            const sd = state.device.filter(x => { return d.indexOf(x.id)!==-1 });
                            if (sd.length > 0) {
                                setSelDevice(sd);
                            } else {
                                setSelDevice([]);
                            }
                            
                        }}
                    >
                        {
                            disNodes.filter(x => selDevice.length === 0 ? x.sn > 0 : x.sn === selDevice[0].sn).map(item => (
                                <Select.Option key={item.value} value={item.value}>
                                    {item.label}
                                </Select.Option>
                            ))
                        }
                    </Select>
                    <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10 }}>主机查询</span>
                    <Select showSearch optionFilterProp="label" allowClear  
                        disabled={selDevice.length>0} 
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={value => {   
                            setSelAlarmDev(value===undefined?'':value);
                        }}
                        options={alarmDevOption}
                        value={selAlarmDev}
                        style={{ width: 260, marginLeft: 10 }}
                        placeholder="选择主机" />*/}
                    <RangePicker style={{ marginLeft: 20 ,borderRadius:10}}
                        allowEmpty={[false,true]}
                        ranges={{
                            今天: [moment().startOf('day'), moment()],
                            '本周': [moment().startOf('week'), moment().endOf('week')],
                            '本月': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        onChange={(dates, dateStrings) => {
                            if(dates===null){
                                setStartTime('');
                                setEndTime('');
                            }else{
                                setStartTime(dateStrings[0]+':00');
                                dates[1]!==null&&setEndTime(dateStrings[1]+':00');
                            }   
                        }}
                    />
                    <div style={{ flex: 1 }}></div>
                    <Button type={"primary"} style={{borderRadius:10}} onClick={getAlarmData}>查询</Button>
                </div>
                <div style={{display:'flex',justifyContent:'space-between',marginBottom:5,alignItems:'center',marginRight:10}}>
                    <div style={{display:'flex',justifyContent:'space-between',flex:1,marginRight:10,marginLeft: 10 ,}} >
                        <div>
                            {
                                selDev!==''&&
                                <div style={{fontSize: 14, border:'1px solid #ccc',padding:'5px 20px',borderRadius:10}}>
                                    <span >主机:{selDev}</span>
                                </div>
                            }
                        </div>
                        <div >
                            {
                                selNode!==""&&
                                <div style={{fontSize: 14, border:'1px solid #ccc',padding:'5px 20px',borderRadius:10}}>
                                    <span>测点:{selNode}</span>
                                </div>
                                
                            }
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <div style={{ width: 200 }}>
                            <span>自动刷新:</span><Switch style={{ marginLeft: 10 }} checkedChildren="开启" unCheckedChildren="关闭" defaultChecked onChange={autoRefresh} />
                            <Tooltip title='刷新'><Button style={{ marginLeft: 30 }} shape="circle" icon={<SyncOutlined style={{ fontSize: '20px' }} onClick={getAlarmData} />} /></Tooltip> 
                        </div>
                    {/* <Button onClick={() => {
                    exportCsv({
                        title: ["id","m_name","device","name","node","districtname","district","type","alarm_date","temp","temp_h","temp_l","humi","humi_h","humi_l",
                            "msg","status","humienable","user"],
                        data: [...data]
                    })
                        }}>导出CSV</Button> */}
                        {/* 下载EXCEL */}
                        {
                            state.super>1&&
                        <Button style={{borderRadius:10}} 
                        onClick={() => {
                            var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                            let url = "/v1/alarmexcel?" ;
                            if(selUser!==0){
                                if(selDis!==0){
                                    url+= `user=${selUser}&district=${selDis}&`
                                }else{
                                    url+=`user=${selUser}&`
                                }
                            }else{
                                if(selDis!==0){
                                    url+= `district=${selDis}&`
                                }
                            }
                            // url+=selUser!==0?`user=${selUser}&`:`user=${state.main.id}&`;
                            // selDis!==0&&(url=`/v1/alarm?`);
                            startTime === ''? url += "starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                            endTime===''? url+="starttime=" + startTime + "&endtime=" + formatDateTimes(new Date().getTime()):
                            url+="starttime=" + startTime + "&endtime=" + endTime;
                            fetch(url, { //downloadFiles 接口请求地址
                                method: 'get',
                                credentials: 'include',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                                })
                            })
                            .then(response => {
                                response.blob().then(blob => {
                                    let blobUrl = window.URL.createObjectURL(blob);
                                    let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                                    let filename = new Date().toLocaleDateString()+ '.xlsx';//设置文件名称
                                    aElement.href = blobUrl;//设置a标签路径
                                    aElement.download = filename;
                                    aElement.click();
                                    window.URL.revokeObjectURL(blobUrl);
                                });
                            }).catch((error) => {
                                console.log('文件下载失败', error);
                            })
                            }}>下载EXCEL</Button>
                        }
                        {/* 下载PDF */}
                        {
                            // Mode==='soc'&&
                        <Button style={{borderRadius:10}} 
                        onClick={() => {
                            var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                            let url = "/v1/alarmpdf?" ;
                            if(selUser!==0){
                                if(selDis!==0){
                                    url+= `user=${selUser}&district=${selDis}&`
                                }else{
                                    url+=`user=${selUser}&`
                                }
                            }else{
                                if(selDis!==0){
                                    url+= `district=${selDis}&`
                                }
                            }
                            // url+=selUser!==0?`user=${selUser}&`:`user=${state.main.id}&`;
                            // selDis!==0&&(url=`/v1/alarm?district=${selDis}&`);
                            startTime === ''? url += "starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                            endTime===''? url+="starttime=" + startTime + "&endtime=" + formatDateTimes(new Date().getTime()):
                            url+="starttime=" + startTime + "&endtime=" + endTime;
                            if(Mode==='soc'){
                                fetch(url, { //downloadFiles 接口请求地址
                                    method: 'get',
                                    credentials: 'include',
                                    headers: new Headers({
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                                    })
                                })
                                .then(response => {
                                    response.blob().then(blob => {
                                        let blobUrl = window.URL.createObjectURL(blob);
                                        let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                                        let filename = new Date().toLocaleDateString()+ '.pdf';//设置文件名称
                                        aElement.href = blobUrl;//设置a标签路径
                                        aElement.download = filename;
                                        aElement.click();
                                        window.URL.revokeObjectURL(blobUrl);
                                    });
                                }).catch((error) => {
                                    console.log('文件下载失败', error);
                                })
                            }
                            else{
                                if(window.CefSharp!==undefined){
                                    window.CefSharp.BindObjectAsync("bound");
                                    let path = window.bound.exportPDF(new Date().toLocaleDateString() + '.pdf');
                                    url+="&path=" + path;
                                    http.get(url, {}).then(data=>{
                                        if(data.code===0&&data.msg==="ok")message.success('PDF导出成功'+data.data,3);
                                    })
                                }
                            }
                            }}>下载PDF</Button>
                        }
                        <Button type={"primary"} style={{ marginLeft: 5,borderRadius:10 }} onClick={() => {
                        const sle = data.filter(x => { return selectedRowKeys.indexOf(x.key) > -1 })
                        // const sle = data.filter(x => x.type<14 && x.status===0);
                        console.log(sle);
                        if(sle.length===0) return message.warn("未选择报警记录",3);
                        var nsle = new Array()
                        sle.map(item => {
                            nsle.push({ id: item.id, date: item.alarm_date })
                        })
                        setDealList(nsle)
                        setShowDeal(true)
                        }}
                        >处理报警</Button>
                        <a id="downloadDiv" style={{ display: 'none' }}></a>
                    </div>
                </div>
                <div>
                    {/* 报警展示 */}
                    <Table rowSelection={rowSelection} size={"small"} bordered columns={columns} pagination={{ hideOnSinglePage: true }} dataSource={data}  loading={isLoading}/>
                    <Modal maskClosable={false}
                        title="报警处理信息"
                        footer={null}
                        visible={showDealMsgBox}
                        onCancel={() => {
                            setShowDealMsgBox(false)
                        }}>
                        <Input defaultValue={showDealMsg.why || "未知"} addonBefore='报警原因：' onChange={e=>{setShowDealMsg({...showDealMsg,why:e.target.value})}}/>
                        <Input defaultValue={showDealMsg.who || "未知"} addonBefore='处理人员：' onChange={e=>{setShowDealMsg({...showDealMsg,who:e.target.value})}}/>
                        <Input defaultValue={showDealMsg.deal || "未知"} addonBefore='处理结果：'onChange={e=>{setShowDealMsg({...showDealMsg,deal:e.target.value})}}/>
                        {/* <div style={{ margin: 5 }}>报警原因：{showDealMsg.why || "未知"}</div> */}
                        {/* <div style={{ margin: 5 }}>处理人员：{showDealMsg.who || "未知"}</div> */}
                        {/* <div style={{ margin: 5 }}>处理结果：{showDealMsg.deal || "未知"}</div> */}
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                            <div></div>
                            <div>
                                <Button onClick={()=>{
                                    http.put('/v1/alarm/'+showData.id,{
                                        ...showData,
                                        msg:JSON.stringify({
                                            why:showDealMsg.why,
                                            who:showDealMsg.who,
                                            deal:showDealMsg.deal
                                        })
                                    }).then(res=>{
                                        getAlarmData();
                                        setShowDealMsgBox(false)
                                    })
                                }}
                                >修改</Button>
                                <Button onClick={()=>{
                                    setShowDealMsgBox(false)
                                }}
                                >关闭</Button>
                            </div>
                        </div>
                    </Modal>
                    <Modal maskClosable={false}
                        title="处理报警"
                        footer={null}
                        visible={showDeal}
                        onCancel={() => {
                            setShowDeal(false)
                        }}>
                        <Form
                            name="basic"
                            form={form}
                            onFinish={values => {
                                http.post("/v1/dealalarm", { alarm: dealList, msg: JSON.stringify(values) }).then(data => {
                                    if (data.code === 0) {
                                        setIsLoading(true);
                                        setSelectedRowKeys([]);
                                        getAlarmData();
                                    }
                                })
                            }}
                        >
                            <Form.Item
                                label="报警原因"
                                name="why"
                                rules={[{ required: true, message: '输入报警原因' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="处理人员"
                                name="who"
                                rules={[{ required: true, message: '输入处理人员' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="处理方法"
                                name="deal"
                                rules={[{ required: true, message: '输入处理方法' }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Form>
                        <Button type="primary" onClick={() => {
                            form.submit()
                        }}>
                            确定
                        </Button>
                    </Modal>
                </div>
            </div>
        </>
    )
}

export default Alarm