import React, { useContext, useState, useEffect, createContext } from 'react'
import { Table, Button, Modal, Descriptions, DatePicker, Select, TreeSelect,Space,Spin, Tooltip ,Input} from 'antd'
import { StateContext, OPERATIONTYPE, TimeFormatSeconds } from '../../../state';
import { SearchOutlined } from '@ant-design/icons';
import http from '../../../../../utils/server'
import moment from 'moment';
import { Mode } from '../../../../../App';

function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};
function formatDate(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
};
const DetailsCreate = createContext()
const { RangePicker } = DatePicker;
function DeTailsModal() {
    const logs = useContext(DetailsCreate)
    const { state, dispatch } = useContext(StateContext)


    const columns = [{
        title: '字段名',
        dataIndex: 'field_name',
        key: 'field_name',
        align: 'center',
    },
    {
        title: '字段注释',
        dataIndex: 'field_comment',
        key: 'field_comment',
        align: 'center',
    },
    {
        title: '新值',
        dataIndex: 'new_value',
        key: 'new_value',
        align: 'center',
        ellipsis: true,
        render: (text, record) => (
            record.new_value.String
        )
    },
    {
        title: '旧值',
        dataIndex: 'old_value',
        key: 'old_value',
        align: 'center',
        ellipsis: true,
        render: (text, record) => (
            record.old_value.String
        )
    },
    {
        title: '说明',
        dataIndex: 'explained',
        key: 'explained',
        align: 'center',
        render: (text, record) => (
            record.explained.String
        )
    }
    ]

    return (<div >
        <Descriptions bordered size='small'
            column={2} >
            <Descriptions.Item label='操作日期' > {logs.details.operation_date} </Descriptions.Item>
            <Descriptions.Item label='操作表名' > {logs.details.operation_table} </Descriptions.Item>
            <Descriptions.Item label='操作名' > {logs.details.operation_name} </Descriptions.Item>
            <Descriptions.Item label='操作类型' > {OPERATIONTYPE[logs.details.operation_type]} </Descriptions.Item>
            <Descriptions.Item label='所属工程' > {logs.details.project_id} </Descriptions.Item>
            <Descriptions.Item label='操作人' > {
                state.systemuser.filter(x => { return x.id === logs.details.user_id }).length > 0 ? state.systemuser.filter(x => { return x.id === logs.details.user_id })[0].username : '无'
            } </Descriptions.Item>
            <Descriptions.Item label='说明' > {logs.details.explained.String} </Descriptions.Item>
        </Descriptions>
        <h1 > 详情 </h1>             {
            <Table bordered pagination={
                { hideOnSinglePage: true }}
                rowKey={record => record.id}
                columns={columns}
                size='small'
                dataSource={state.operationdetails}
            />
        }

    </div>
    )
}

function Operation() {
    const { state, dispatch } = useContext(StateContext)
    const [showDetailsModel, setShowDetailsModel] = useState(false)
    const [details, setDetails] = useState()
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('');
    const [selUser, setSelUser] = useState(0);
    const [oType, setOType] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [pType,setPType]=useState([]);
    const userSerch = React.createRef();
    useEffect(async() => {
        setIsLoading(true);
        let userLogin = state.main;
        await http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
            }
        })
        if(state.main.type<103){
            http.get("/v1/userlog?starttime="+formatDateTimes(new Date().getTime()-1000*60*60*24)+'&endtime='+formatDateTimes(new Date().getTime()), {}).then(data => {
                setIsLoading(false)
                if (data.code === 0) {
                    data.data.reverse();
                    // userLogin.type===101?
                    dispatch(
                        Mode!=='soc'?{ type: 'operation', operation: data.data.filter(x=>x.type!==309&&x.type!==301) }:
                        { type: 'operation', operation: data.data })
                    //     :
                    // dispatch(
                    // Mode!=='soc'?{ type: 'operation', operation: data.data.filter(x=>x.type!==309) }:
                    // { type: 'operation', operation: data.data})
                    // console.log(data.data.filter(x=>x.type===309));
                }
            })

        }else{
            http.get("/v1/userlog?starttime="+formatDateTimes(new Date().getTime()-1000*60*60*24*7)+'&endtime='+formatDateTimes(new Date().getTime()), {}).then(data => {
                setIsLoading(false)
                if (data.code === 0) {
                    data.data.reverse();
                    // userLogin.type===101?
                    dispatch(
                        Mode!=='soc'?{ type: 'operation', operation: data.data.filter(x=>x.type!==309&&x.type!==301) }:
                        { type: 'operation', operation: data.data })
                    //     :
                    // dispatch(
                    // Mode!=='soc'?{ type: 'operation', operation: data.data.filter(x=>x.type!==309) }:
                    // { type: 'operation', operation: data.data})
                    // console.log(data.data.filter(x=>x.type===309));
                }
            })
        }
        
        
        http.get("/v1/permissiontype", {}).then(data => {
            if (data.code === 0) {
                // console.log(data.data);
                dispatch({ type: 'permissiontype', permissiontype: data.data })
                let arrs = [{text:'登录',value:101}];
                data.data.map(m=>{
                    if(Mode==='soc'){
                        m.code!==303&&
                        arrs.push({text:m.value,value:m.code})
                    }else{
                        m.code!==303&&m.code!==309&&
                        arrs.push({text:m.value,value:m.code})
                    }
                    
                })
                setPType(arrs);
            }
        })
    }, []);
    const columns = [{
        title: '操作日期',
        dataIndex: 'time',
        align: 'center',
        width:200
    },
    {
        title: '用户类型',
        dataIndex: 'user',
        align: 'center',
        render:(text,record)=>(
            <div>
                {
                    state.systemuser.list.filter(x=>x.id===record.user).length>0?
                    state.systemuser.list.filter(x=>x.id===record.user)[0].type===104?'普通用户':'管理员':
                    ''
                }
            </div>
        ),
        
    },
    {
        title: '操作人',
        dataIndex: 'username',
        align: 'center',
        render:(text,record)=>{
            if(record.username===''){
                return(
                    <div>
                        {
                            state.systemuser.list.filter(x=>x.id===record.user).length>0?
                            state.systemuser.list.filter(x=>x.id===record.user)[0].name:
                            ''
                        }
                    </div>
                )
            }else{
                return(
                    <div>
                        {
                            record.username
                        }
                    </div>
                )
            }
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={userSerch}
                        placeholder='操作人'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => userSerch.current.focus({cursor: 'all'}), 100);
            }
            },
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

        onFilter: (value, record) =>{
            let arrs =  state.systemuser.list.filter(x=>x.name.includes(value));
            if(arrs.length>0){
                let ids = [];
                arrs.map(m=>ids.push(m.id));
                return ids.includes(record.user);
            }
        }
      
        ,
    },
    {
        title: '操作模块',
        dataIndex: 'type',
        align: 'center',
        render: (text, record) => {
            let ttt =state.permissiontype.filter(x => { return x.code === record.type });
            return (
            <div>
                {
                    record.type===101?'登录':
                    ttt.length > 0 &&
                    ttt[0].value 
                }
            </div>
        )},
        filters:pType,
        onFilter:(value, record) => 
            record.type===value
    },
    {
        title: '操作类型',
        dataIndex: 'method',
        align: 'center',
        render: (text, record) => {
            if(record.method==='POST'){
                let temp= record.log.substr(4,6);
                if(temp==='getdev'){
                    return(<div>查询参数</div>)
                } else if(temp==='setdev'){
                    return(<div>设置参数</div>)
                }else if(temp==='dealal'){
                    return(<div>处理报警</div>)
                }else if(temp==='readde'){
                    return(<div>获取数据</div>)
                }else if(temp==='logout'){
                    return(<div>退出登录</div>)
                }
                else{
                    return(<div>增加</div>)
                }
            }else if(record.method==='PUT'){
                return (<div>修改</div>)
            }else if(record.method==='DELETE'){
                return (<div>删除</div>)
            }
            else if(record.method==='LOGIN'){
                return (<div>登录</div>)
            }
        },
        filters: [
            { text: '查询参数', value: 'getdevice' },
            { text: '设置参数', value: 'setdevice' },
            { text: '处理报警', value: 'dealalarm' },
            { text: '获取数据', value: 'readdevic' },
            { text: '增加', value: 'POST' },
            { text: '修改', value: 'PUT' },
            { text: '删除', value: 'DELETE' },
            { text: '登录', value: 'LOGIN' },
            { text: '退出登录', value: 'logout' },
          ],
        onFilter: (value, record) => {
            if(record.method==='POST'){
                return value==='POST'?
                !record.log.includes('getdevice')&&!record.log.includes('setdevice')&&!record.log.includes('dealalarm')&&!record.log.includes('readdevic')&&!record.log.includes('logout')
                :record.log.includes(value);
            }else{
                return record.method.includes(value);
            }
        },

    },
    {
        title: '操作详情',
        dataIndex: 'log',
        align: 'center',
        width:700,
        render: (text, record) => (
            <Tooltip title={text}>
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' ,whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:700}}>
              {text}
            </div>
            </Tooltip>
          ),
    },

    ]
    return (
        < div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5, padding: 10, }}>
                {
                    Mode==='soc'&&<span>
                        <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10 }}>选择用户</span>
                        <TreeSelect
                            allowClear
                            showSearch
                            treeData={state.systemuser.tree}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            style={{ width: 300, marginLeft: 10 }}
                            placeholder="选择用户"
                            treeDefaultExpandAll
                            optionFilterProp="children"
                            onChange={(value) => {
                                value?setSelUser(value):setSelUser(0);
                            }}
                        />
                    </span>
                }

                <span style={{ fontSize: 14, marginLeft: 20 }}>操作类型</span>
                <Select
                    allowClear
                    showSearch
                    style={{ width: 200, marginLeft: 10 }}
                    placeholder="操作类型"
                    optionFilterProp="children"
                    onChange={(d) => {
                        d?setOType(d):setOType(0);
                    }}
                >
                    {state.permissiontype.map(({ id, value }) => (
                        <Select.Option key={id} value={id}>{value}</Select.Option>
                    ))}
                </Select>
                <RangePicker style={{ marginLeft: 20 ,borderRadius:10}}
                    allowEmpty={[false,true]}
                    ranges={{
                        今天: [moment().startOf('day'), moment()],
                        '本周': [moment().startOf('week'), moment().endOf('week')],
                        '本月': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    onChange={(dates, dateStrings) => {
                        if(dates===null){
                            setStartTime('');
                            setEndTime('');
                        }else{
                            setStartTime(dateStrings[0]+':00');
                            dates[1]!==null?setEndTime(dateStrings[1]+':00'):setEndTime(formatDateTimes(new Date().getTime()));
                        }   
                    }}
                />
                <div style={{ flex: 1 }}></div>

                <Button type={"primary"} style={{borderRadius:10}} onClick={() => {
                    let pam='';
                    selUser>0&& (pam+=`?user=${selUser}`);
                    oType>0&& (pam.indexOf('?')===-1?pam+=`?type=${oType}`:pam+=`&type=${oType}`);
                    startTime!==''&& (pam.indexOf('?')===-1?pam+=`?starttime=${startTime}&endtime=${endTime}`:pam+=`&starttime=${startTime}&endtime=${endTime}`);
                    http.get("/v1/userlog"+pam, {}).then(data => {
                        if (data.code === 0) {
                            dispatch(
                                Mode!=='soc'?{ type: 'operation', operation: data.data.filter(x=>x.type!==309&&x.type!==301) }:
                                { type: 'operation', operation: data.data })
                        }
                    })
                }}>查询</Button>
            </div>
            <Spin  spinning={isLoading} style={{marginLeft:'50%'}}/>
            <Table bordered pagination={{ hideOnSinglePage: true ,defaultPageSize:15}}
                rowKey={record => record.id} columns={columns} size='small'
                style={{width:'100%'}}
                dataSource={state.operation.filter(x=>x.type!==303).sort((a,b)=>b.id-a.id) }
            />

            < Modal title="操作详情"
                width='800px'
                visible={showDetailsModel}
                closable={false}
                footer={[< Button key='ok'
                    onClick={
                        () => {
                            setShowDetailsModel(false)
                        }
                    } >
                    确定 </Button>
                ]} >
                < DetailsCreate.Provider value={
                    { details }} >
                    < DeTailsModal />
                </DetailsCreate.Provider>

            </Modal>
        </div>
    )
}

export default Operation