import React, { createContext, useContext, useEffect, useState, useReducer} from 'react'
import { Table, Button, Input, Select, Modal, Space, DatePicker, TreeSelect, Form, InputNumber, Checkbox ,Tag} from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './index.less'
import moment from 'moment';
import Draggable from 'react-draggable';
import sha1 from '../../../../../utils/sha1';
import { Mode } from '../../../../../App';

var changeDev = false;
const DeviceContext = createContext();

function exportCsv(obj) {
    var title = obj.title;
    var titleForKey = Object.keys(obj.data[0]);
    var data = obj.data;
    var str = [];
    str.push(obj.title.join(",") + "\n");
    for (var i = 0; i < data.length; i++) {
        var temp = [];
        for (var j = 0; j < titleForKey.length; j++) {
            temp.push(data[i][titleForKey[j]]);
        }
        str.push(temp.join(",") + "\n");
    }
    var blob = new Blob(['\uFEFF' + str.join('')], {
        type: 'text/plain;charset=utf-8',
    });
    var downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "export.csv";
    downloadLink.click();
}

function findUserDis(nop, list, districts,userDis) {
    if (list&&districts) {
        const nops = list.filter(x => { return x.parent === nop })
        if (nops.length === 0) {
            return
        } else {
            for (var j = 0; j < nops.length; j++) {
                let tempDis=districts.filter(x=>x.user===nops[j].id);
                tempDis.length>0&&userDis.push(...tempDis);
                findUserDis(nops[j].id, list,districts,userDis);
            }
        }
    }
}
function  getSimByIccid(iccid,simToken) {
    let r ={};
    fetch( `http://hebeijiuchen.com/sim/card/all/query.do?queryString=${iccid}`, { //物联网卡查询 接口请求地址
        method: 'get',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `${simToken}`
        })
    })
    .then((resp) => {
        resp.json().then(res=>{
            if(res.list.length==0){//河北卡查询不到
                let method = 'fc.function.card.info';
                let appKey = 'RL7i4T0Q9024bI7Z';
                let t = Math.floor(new Date().getTime()/1000)
                let sign = callSign(appKey,method,iccid,t);
                fetch(`https://api.quectel.com/openapi/router`,{
                    method:'post',
                    body:`appKey=${appKey}&iccid=${iccid}&method=${method}&sign=${sign}&t=${t}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                }).then(resp=>{
                    resp.json().then(res=>{
                        if(res.resultCode===0){
                            r.iccid_expire =res.expiryDate!==''?res.expiryDate:'2000-01-01';
                            r.sim = res.msisdn;
                            r.status = res.active;
                            r.islock = res.islock===1?true:false;
                        }else{
                            r.iccid_expire='2000-01-01';
                            r.sim='';
                            r.status = '未找到';
                            r.islock = false
                        }
                    })
                })
                
            }else{
                r.iccid_expire =res.list[0].expireTime!==''?res.list[0].expireTime:'2000-01-01';
                r.sim =''+ res.list[0].mobile;
                r.status = '';
                r.islock = false
                let date1 = new Date(res.list[0].expireTime).getTime();
                // let date1 = new Date('2023-8-16').getTime();
                let date2 = new Date().getTime();
                if( date2<date1){
                    r.state = 0
                }else{
                    let days = Math.floor((date2 -date1 )/(24*60*60*1000));
                    if(days<20){
                        r.state = 1;
                    }else{
                        r.state = 2;
                    }
                }
            }
        })
    })
    return r
}
function  getSimBySim(sim,simToken) {
    let r ={};
    fetch( `http://www.hebeijiuchen.com/sim/card/queryCard.do?mobile=${sim}`, { //物联网卡查询 接口请求地址
        method: 'get',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `${simToken}`
        })
    })
    .then((resp) => {
        resp.json().then(res=>{
            r.iccid_expire =res.expireTime!==''?res.expireTime:'2000-01-01';
            r.iccid = res.iccid
            r.status = '';
            r.islock = false
            let date1 = new Date(res.expireTime).getTime();
            
            let date2 = new Date().getTime();
            if( date2<date1){
                r.state = 0
            }else{
                console.log(date2,date1);
                let days = Math.floor((date2 -date1 )/(24*60*60*1000));
                console.log('days',days);
                // if(days<20){
                //     r.state = 1;
                // }
            }
        }).catch((error) => {
            let method = 'fc.function.card.info';
            let appKey = 'RL7i4T0Q9024bI7Z';
            let msisdn = sim;
            let t = Math.floor(new Date().getTime()/1000)
            let sign = callSign(appKey,method,msisdn,t);
            fetch(`https://api.quectel.com/openapi/router`,{
                method:'post',
                body:`appKey=${appKey}&method=${method}&msisdn=${msisdn}&sign=${sign}&t=${t}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            }).then(resp=>{
                resp.json().then(res=>{
                    if(res.resultCode===0){
                        r.iccid_expire =res.expiryDate!==''?res.expiryDate:'2000-01-01';
                        r.sim = res.msisdn;
                        r.status = res.active;
                        r.iccid = res.iccid;
                        r.islock = res.islock===1?true:false
                        if(res.expiryDate!=""){
                            let date1 = new Date(res.expiryDate).getTime();
                            let date2 = new Date().getTime();
                            if( date2<date1){
                                r.state = 0
                            }else{
                                let days = Math.ceil((date2 -date1 )/(24*60*60*1000));
                                console.log('days',days);
                                // if(days<20){
                                //     r.state = 1;
                                // }
                            }
                            
                        }
                    }else{
                        r.iccid_expire='2000-01-01';
                        r.sim='';
                        r.status = '未找到';
                        r.islock = false
                        
                    }
                })
            })
        })
    });
    
    return r
}
function callSign (appKey,method,s,t){
    let Secret = 'RA9026m2';
    let concat =`${Secret}appKey${appKey}`;
    if(s.length===13){
        let msisdn = s;
        concat +=`method${method}msisdn${msisdn}t${t}${Secret}`;
    }else{
        let iccid = s;
        concat +=`iccid${iccid}method${method}t${t}${Secret}`
    }
    let r = sha1.sha1(concat);
    return r
}
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
  };
function DeviceNew() {
    const { actDevice, dispatchDevice, form, onfinish } = useContext(DeviceContext)
    const { state, dispatch } = useContext(StateContext)
    const [lockMName, setLockMName] = useState(false)
    const [master, setMaster] = useState(0)
    const [nodeNum, setNodeNum] = useState(1)
    const [disOptions, setDisOptions] = useState([]);
    const [defExpend,setDefExpend]=useState([]);
    const [simToken,setSimToken] = useState('')
    const [simStatus,setSimStatus] = useState('')
    const formRef = React.createRef();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    useEffect(() => {
        var p = state.systemuser.tree[0];
        let arrDis = new Array();
        Mode === 'soc' && state.district.filter(x => { return x.user === state.systemuser.tree[0].value }).map(item => arrDis.push(item));
        setDisOptions(Mode !== 'soc' ? state.district : arrDis);
        const d_d = state.district.filter(x => { return x.id === actDevice.district })
        if (d_d.length > 0) {
            setMaster(actDevice.sn)
            const d_u = state.systemuser.list.filter(x => { return x.id === d_d[0].user })
            if (d_u.length > 0) {
                p = d_u[0].id
            }
        }
        if(Mode==='soc'){
            form.setFieldsValue({ ...actDevice, num: 1, humi: true, status: false, expire: moment(actDevice.expire, 'YYYY-MM-DD 00:00:00'), time: moment(actDevice.time, 'YYYY-MM-DD 00:00:00'), parent: p,
            iccid_expire: moment(actDevice.iccid_expire, 'YYYY-MM-DD'),
            temp_alarm_high:50,temp_alarm_low:-50,humi_alarm_high:100,humi_alarm_low:0,power_alarm:true,simdate:moment(actDevice.simdate, 'YYYY-MM-DD'),
            })
        }else{
            form.setFieldsValue({ ...actDevice, num: 1, humi: true, status: false, expire: moment(actDevice.expire, 'YYYY-MM-DD 00:00:00'), time: moment(actDevice.time, 'YYYY-MM-DD 00:00:00'), parent: p,
                temp_alarm_high:50,temp_alarm_low:-50,humi_alarm_high:100,humi_alarm_low:0,power_alarm:true
            })
        }
        var list = [];
        state.systemuser.list.filter(x=>x.parent===state.main.id).map(m=>list.push(m.id+""));
        setDefExpend(list);

        fetch(' http://www.hebeijiuchen.com/sim/user/login.do?username=17734552912&password=123456', { //物联网卡登录 接口请求地址
            method: 'get',
        })
        .then((resp) => {
            resp.json().then(res=>{
                setSimToken(res.token)
            })
        }).catch((error) => {
            console.log(error);
        });


}, [])

    return (
        <Form
            form={form}
            {...formItemLayout}
            ref={formRef}
            onFinish={onfinish}
        >

            {
                Mode === 'soc' && (<Form.Item
                    label="所属用户"
                    name="parent"
                    rules={[
                        {
                            required: true,
                            message: '选择上级用户'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const u = state.systemuser.list.filter(x => { return x.id === value || x.id == value.id })
                                if (u.length > 0 && u[0].type < 104) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('普通用户不可选'));
                            },
                        }),
                    ]}>
                    <TreeSelect
                        // treeDefaultExpandAll
                        showSearch
                        treeNodeFilterProp='name'
                        treeDefaultExpandedKeys={defExpend}
                        treeData={state.systemuser.treeM}
                        placeholder="上级用户"
                        onChange={value => {
                            const u = state.systemuser.list.filter(x => { return x.id === value*1 })
                            if (u.length > 0 && u[0].type < 104) {
                                // setSelectUser(u[0]);
                                let arrDis = new Array();
                                state.district.filter(x => { return x.user === value }).map(item => arrDis.push(item));
                                setDisOptions(arrDis);
                            }
                        }}
                    />
                </Form.Item>)
            }
            <Form.Item
                label="所属区域"
                name="district"
                rules={[{ required: true, message: '选择所属区域' }]}>
                <Select
                    placeholder="选择区域"
                >
                    {
                        disOptions.map(item => (
                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 300 }}
                    label="主机类型"
                    labelCol={{ span: 8 }}
                    name="type"
                    rules={[{ required: true, message: '选择主机类型' }]}>
                    <Select style={{ width: 150 }}
                        placeholder="主机类型"
                        onChange={value => {
                            switch (value) {
                                case 201: formRef.current.setFieldsValue({
                                    location: 2, humi: true,power_alarm:true
                                }); return;
                                case 202: formRef.current.setFieldsValue({
                                    location: 1, humi: false,power_alarm:false
                                }); return;
                                case 203: formRef.current.setFieldsValue({
                                    location: 0, humi: false,power_alarm:false
                                }); return;
                                case 204: formRef.current.setFieldsValue({
                                    location: 2, humi: true,power_alarm:false
                                }); return;
                                case 205: formRef.current.setFieldsValue({
                                    location: 2, humi: false,power_alarm:false
                                }); return;
                                case 207: formRef.current.setFieldsValue({
                                    location: 2, humi: true,power_alarm:true
                                }); return;
                                case 208: formRef.current.setFieldsValue({
                                    location: 2, humi: true,power_alarm:true
                                }); return;
                                case 209: formRef.current.setFieldsValue({
                                    location: 1, humi: false,power_alarm:false
                                }); return;
                            }
                        }}
                    >
                    {
                        state.devicetype.map(item => (
                            // item.code!==204&&
                            <Select.Option key={item.code} value={item.code}>{item.value}</Select.Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="主机编号"
                    name="sn"
                    onChange={e => {
                        const s = state.device.filter(x => { return x.sn === Number(e.target.value)&&x.node!==0 })
                        if (s.length > 0) {
                            setMaster(s.length);
                            setLockMName(true);
                            formRef.current.setFieldsValue({
                                m_name: s[0].m_name,
                                sim: s[0].sim,
                                node:s.length+1,
                                name:"测点"+(s.length+1)
                            })
                        } else {
                            setLockMName(false)
                        }
                    }}
                    rules={[{ required: true, message: '输入主机编号' }]}>
                    <Input disabled={changeDev} />
                </Form.Item>
            </div>

            {
                Mode === "local" ?
                    <div style={{ display: 'flex' }}>
                        <Form.Item style={{ width: 400 }}
                            labelCol={{ span: 6 }}
                            label="主机名称"
                            name="m_name"
                            rules={[{ required: true, message: '输入主机名称' }]}>
                            <Input disabled={lockMName || changeDev} />
                        </Form.Item>
                        <Form.Item style={{ width: 200 }}
                            labelCol={{ span: 12 }}
                            label="平台设备"
                            name="status"
                            valuePropName="checked"
                        >
                            <Checkbox>选择</Checkbox>
                        </Form.Item>
                    </div> :
                    <Form.Item
                        label="主机名称"
                        name="m_name"
                        rules={[{ required: true, message: '输入主机名称' }]}>
                        <Input disabled={lockMName || changeDev} />
                    </Form.Item>
            }
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 340 }}
                    labelCol={{ span: 7 }}
                    label="测点名称"
                    name="name"
                    rules={[{ required: true, message: '输入测点名称' }]}>
                    <Input disabled={nodeNum > 1} />
                </Form.Item>
                <Form.Item style={{ width: 120,}}
                    labelCol={{ span: 10 }}
                    label="断电"
                    name="power_alarm"
                    valuePropName="checked"
                >
                    <Checkbox 
                    // onChange={e=>{
                    //     if(e.target.checked){
                    //         form.setFieldsValue({node:0,num:1});
                    //         setNodeNum(1);
                    //         setNodeStatus1(true);
                    //     }else{
                    //         setNodeStatus1(false);
                    //         !nodeStatus2&&form.setFieldsValue({node:1});
                    //     }
                    // }}
                    >
                        开启
                    </Checkbox>
                </Form.Item>
                <Form.Item style={{ width: 140, }}
                    labelCol={{ span: 12 }}
                    label="开关门"
                    name="door_alarm"
                    valuePropName="checked"
                >
                    <Checkbox>
                        开启
                    </Checkbox>
                </Form.Item>
            </div>
            <div style={{ display: 'flex'}}>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                    label="测点ID"
                    name="node"
                    rules={[{ required: true, message: '输入测点ID' }]}>
                    <Input disabled={nodeNum > 1 } onChange={e=>
                        e.target.value!==''&&e.target.value*1===0&&form.setFieldsValue({power_alarm:true})
                    }/>
                </Form.Item>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 11 }}
                    wrapperCol={{ span: 13 }}
                    label="测点数量"
                    name="num"
                    rules={[{ required: true, message: '输入测点数量' }]}>
                    <InputNumber min={1} max={99}  onChange={value => {
                        setNodeNum(value)
                    }} />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 20 }}
                    wrapperCol={{ span: 13 }}
                    label="已添加测点"
                    name="s">
                    {
                        master
                    }
                </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="定位方式"
                    name="location"
                    rules={[{ required: true, message: '选择定位方式' }]}>
                    <Select
                        placeholder="定位方式"
                    >
                        {
                            [{ id: 0, name: 'GPS定位' }, { id: 1, name: 'LBS定位' }, { id: 2, name: '无定位' }].map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                {
                    Mode === "soc"&&state.main.type<103&&
                    <div style={{width:230}}>
                        <Form.Item 
                            labelCol={{ span: 10}}
                            label="卡到期时间"
                            name="iccid_expire"
                        >
                            <DatePicker format={"YYYY-MM-DD"} style={{width:130}}/>
                        </Form.Item>
                    </div>
                    
                }
            </div>
            {/* sim卡号和编辑时间 */}
            <div style={{display: 'flex'}}>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="SIM卡号"
                    name="sim"
                    rules={[{ required: true, message: '输入SIM卡号' }]}>
                    <Input controls={false} disabled={lockMName || changeDev}  style={{width:140}} onChange=
                    {e=>
                        {
                            form.setFieldsValue({
                                iccid_expire_real:moment('2000-01-01', 'YYYY-MM-DD'),
                                simdate:moment(new Date(), 'YYYY-MM-DD'),
                                iccid:''
                            })
                            let val = e.target.value;
                            if(val.length===13){
                                let r =getSimBySim(val,simToken);
                                setTimeout(() => {
                                    setSimStatus(r.status);
                                    form.setFieldsValue({
                                        iccid_expire_real:moment(r.iccid_expire, 'YYYY-MM-DD'),
                                        iccid:r.iccid,
                                        simdate:moment(new Date(), 'YYYY-MM-DD'),
                                    })
                                }, 1000);
                                
                            }else{
                                // setSimStatus('卡号错误');
                                
                            }
                        }
                    }/>
                </Form.Item>
                {
                    Mode==='soc'&&
                    <Form.Item  
                        labelCol={{ span: 8 }}
                        label="编辑日期"
                        name="simdate"
                    >
                        <DatePicker format={"YYYY-MM-DD"} style={{width:130}} disabled />
                    </Form.Item>
                }
                {
                    Mode === "soc"&&state.main.type<101&&
                    <div style={{color:'red'}}>
                        {
                            simStatus
                        }
                    </div>
                }
            </div>
            {/* 卡iccid和到期时间 */}
            <div style={{ display:  Mode==='soc'?'flex':'none'}}>
                <div style={{width:260}}>
                    <Form.Item 
                        labelCol={{ span: 6 }}
                        label="SIMid"
                        name="iccid"
                    >
                        <Input controls={false} disabled={lockMName || changeDev}  style={{width:185}} onChange=
                        {e=>
                            {
                                form.setFieldsValue({
                                    sim:'',
                                    iccid_expire_real:moment('2000-01-01', 'YYYY-MM-DD'),
                                    simdate:moment(new Date(), 'YYYY-MM-DD'),
                                })
                                let val = e.target.value;
                                if(val.length===20){
                                    let r =getSimByIccid(val,simToken);
                                    setTimeout(() => {
                                        setSimStatus(r.status);
                                        form.setFieldsValue({
                                            iccid_expire_real:moment(r.iccid_expire, 'YYYY-MM-DD'),
                                            sim:r.sim,
                                            simdate:moment(new Date(), 'YYYY-MM-DD'),
                                        })
                                    }, 1000);
                                    
                                }else{
                                    // setSimStatus('iccid错误');
                                }
                            }
                        }/>
                    </Form.Item>
                </div>
                {
                    Mode === "soc"&&state.main.type<101&&
                    <div style={{width:230}}>
                        <Form.Item 
                            labelCol={{ span: 10}}
                            label="到期时间"
                            name="iccid_expire_real"
                        >
                            <DatePicker format={"YYYY-MM-DD"} style={{width:130} } disabled/>
                        </Form.Item>
                    </div>
                    
                }
            </div>
            
            <div style={{ display: 'none' ,marginLeft:10}}>
                <Form.Item style={{width:150}}
                    labelCol={{ span: 14}}
                    label="温度上限"
                    name="temp_alarm_high"
                    rules={[{ required: true, message: '输入温度上限' }]}>
                    <input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item style={{width:150}}
                    labelCol={{ span: 14 }}
                    label="温度下限"
                    name="temp_alarm_low"
                    rules={[{ required: true, message: '输入温度下限' }]}>
                    <Input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item style={{width:150}}
                    labelCol={{ span: 14 }}
                    label="湿度上限"
                    name="humi_alarm_high"
                    rules={[{ required: true, message: '输入湿度上限' }]}>
                    <input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item style={{width:150}}
                    labelCol={{ span: 14 }}
                    label="湿度下限"
                    name="humi_alarm_low"
                    rules={[{ required: true, message: '输入湿度下限' }]}>
                    <Input  style={{ width: 50 }}/>
                </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item style={{width:300}}
                    labelCol={{ span: 8 }}
                    label="测点隐藏"
                    name="hide"
                    valuePropName="checked"
                >
                    <Checkbox style={{ width: 80 }}>隐藏</Checkbox>
                </Form.Item>
                {
                    state.main.type < 103 &&
                    <Form.Item style={{ width: 300 }}
                        labelCol={{ span: 8 }}
                        label="安装时间"
                        name="time"
                        rules={[{ required: true, message: '选择安装时间' }]}>
                        <DatePicker format={"YYYY-MM-DD 00:00:00"}  disabledDate={current=>current&&current > moment().endOf('day')}/>
                    </Form.Item>
                }
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 8 }}
                    label="湿度数据"
                    name="humi"
                    valuePropName="checked"
                >
                    <Checkbox >开启</Checkbox>
                </Form.Item>

                {
                    state.main.type <103 &&Mode==='soc'&&
                    <Form.Item style={{ width: 300 }}
                        labelCol={{ span: 8 }}
                        label="到期时间"
                        name="expire"
                        rules={[{ required: true, message: '选择到期时间' }]}>
                        <DatePicker format={"YYYY-MM-DD 00:00:00"} disabled={state.main.type>101}/>
                    </Form.Item>
                }
            </div>

        </Form>
    )
}
function DeviceFix() {
    const { actDevice, dispatchDevice, form, onfinish } = useContext(DeviceContext)
    const { state } = useContext(StateContext)
    const [selectUser, setSelectUser] = useState([])
    const [simToken,setSimToken] = useState('')
    const [simStatus,setSimStatus] = useState('')
    const [simStatus1,setSimStatus1] = useState('')

    const formRef = React.createRef();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    useEffect(() => {
        let simToken='';
        var p = 1
        const d_d = state.district.filter(x => { return x.id === actDevice.district })
        if (d_d.length > 0) {
            const d_u = state.systemuser.list.filter(x => { return x.id === d_d[0].user })
            if (d_u.length > 0) {
                p = d_u[0].id
                setSelectUser(d_u[0])
            }
        }
        if(Mode==='soc'){
            form.setFieldsValue({
                ...actDevice,
                humi: actDevice.humi === 0 ? false : true,
                door_alarm: actDevice.door_alarm === 0 ? false : true,
                power_alarm: actDevice.power_alarm === 0 ? false : true,
                hide: actDevice.hide === 0 ? false : true,
                expire: moment(actDevice.expire, 'YYYY-MM-DD 00:00:00'), time: moment(actDevice.time, 'YYYY-MM-DD 00:00:00'), parent: p,
                iccid_expire:  moment(actDevice.iccid_expire, 'YYYY-MM-DD')
            })
        }else{
            form.setFieldsValue({
                ...actDevice,
                humi: actDevice.humi === 0 ? false : true,
                door_alarm: actDevice.door_alarm === 0 ? false : true,
                power_alarm: actDevice.power_alarm === 0 ? false : true,
                hide: actDevice.hide === 0 ? false : true,
                expire: moment(actDevice.expire, 'YYYY-MM-DD 00:00:00'), time: moment(actDevice.time, 'YYYY-MM-DD 00:00:00'), parent: p,
            })
        }
        fetch(' http://www.hebeijiuchen.com/sim/user/login.do?username=17734552912&password=123456', { //物联网卡登录 接口请求地址
            method: 'get',
        })
        .then((resp) => {
            resp.json().then(res=>{
                simToken = res.token
                setSimToken(res.token)
                let result
                if(actDevice.iccid.length===20){
                    result = getSimByIccid(actDevice.iccid,simToken);
                }else{
                    result = getSimBySim(actDevice.sim,simToken)
                }
                
                setTimeout(() => {
                    setSimStatus(result.status);
                    console.log(result);
                    if(actDevice.sim!==result.sim){
                        setSimStatus("卡号和ICCID不符")
                    }else if(result.state===1){
                        setSimStatus("停机")
                    }else if(result.state===2){
                        setSimStatus("销号")
                    }
                    form.setFieldsValue({
                        iccid_expire_real:moment(result.iccid_expire, 'YYYY-MM-DD'),
                    })
                    if(actDevice.iccid.length<20){
                        form.setFieldsValue({
                            iccid:result.iccid
                        })
                    }
                }, 1000);
            })
        }).catch((error) => {
            console.log(error);
        });
        http.post("/v1/getdevice", { error:false,type:13,validity:false, device: actDevice.sn, node: actDevice.node })
        .then(data1 => {
            // if(data1.code===200){
            //     setSimStatus1('不在线')
            // }else{
                
            // } 
            http.get("/v1/device", {}).then(data => {
                if (data.code === 0) {
                    let tempEles = data.data.filter(x=>x.id===actDevice.id)
                    if(tempEles.length>0 && tempEles[0].iccid.length===20 ){
                        let r = getSimByIccid(tempEles[0].iccid,simToken);
                        setTimeout(() => {
                            setSimStatus1(data1.code===200?'不在线'+r.status:r.status);
                            form.setFieldsValue({
                                iccid_expire_real1:moment(r.iccid_expire, 'YYYY-MM-DD'),
                                sim1:r.sim,
                                // simdate1:moment(new Date(), 'YYYY-MM-DD'),
                            })
                        }, 1000);

                    }else{
                        setSimStatus1('iccid读取失败')
                    }
                }
            })          
        })
    }, [])

    return (
        <Form
            form={form}
            {...formItemLayout}
            ref={formRef}
            onFinish={onfinish}>


            {
                Mode === 'soc' && <Form.Item
                    label="所属用户"
                    name="parent"
                    rules={[
                        {
                            required: true,
                            message: '选择上级用户'
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                const u = state.systemuser.list.filter(x => { return x.id === value })
                                if (u.length > 0 && u[0].type < 104) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('普通用户不可选'));
                            },
                        }),
                    ]}>
                    <TreeSelect
                        showSearch
                        treeNodeFilterProp='name'
                        treeDefaultExpandAll
                        treeData={state.systemuser.treeM}
                        placeholder="上级用户"
                        onChange={value => {
                            const u = state.systemuser.list.filter(x => { return x.id === value })
                            if (u.length > 0 && u[0].type < 104) {
                                setSelectUser(u[0])
                            }
                        }}
                        
                    />
                </Form.Item>
            }
            <Form.Item label="所属区域"
                name="district"
                rules={[{ required: true, message: '选择所属区域' }]}>
                <Select
                    placeholder="选择区域"
                >
                    {
                        Mode !== 'soc' ? state.district.map(item => (
                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        )) :
                            state.district.filter(x => { return x.user === selectUser.id }).map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))
                    }
                </Select>
            </Form.Item>
            <div style={{ display: 'flex' }}>
                <Form.Item label="主机类型"
                    labelCol={{ span: 8 }}
                    style={{ width: 300 }}
                    name="type"
                    rules={[{ required: true, message: '选择主机类型' }]}>
                    <Select
                        placeholder="主机类型"
                        // location 0:GPS  1:基站 2:无 
                        onChange={value => {
                            switch (value) {
                                case 201: formRef.current.setFieldsValue({
                                    location: 2, humi: true
                                }); return;
                                case 202: formRef.current.setFieldsValue({
                                    location: 1, humi: false
                                }); return;
                                case 203: formRef.current.setFieldsValue({
                                    location: 0, humi: false
                                }); return;
                                case 204: formRef.current.setFieldsValue({
                                    location: 2, humi: true
                                }); return;
                                case 205: formRef.current.setFieldsValue({
                                    location: 2, humi: false
                                }); return;
                            }
                        }}
                    >
                        {
                            state.devicetype.map(item => (
                                // item.code!==204&&
                                <Select.Option key={item.id} value={item.code}>{item.value}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="主机编号"
                    labelCol={{ span: 8 }}
                    style={{ width: 300 }}
                    name="sn"
                    rules={[{ required: true, message: '输入主机编号' }]}>
                    <Input disabled={changeDev} />
                </Form.Item>
            </div>
            {
                Mode === "local" ?
                    <div style={{ display: 'flex' }}>
                        <Form.Item style={{ width: 400 }}
                            labelCol={{ span: 6 }}
                            label="主机名称"
                            name="m_name"
                            rules={[{ required: true, message: '输入主机名称' }]}>
                            <Input  />
                        </Form.Item>
                        <Form.Item style={{ width: 200 }}
                            labelCol={{ span: 12 }}
                            label="平台设备"
                            name="status"
                            valuePropName="checked"
                        >
                            <Checkbox>选择</Checkbox>
                        </Form.Item>
                    </div> :
                    <Form.Item
                        label="主机名称"
                        name="m_name"
                        rules={[{ required: true, message: '输入主机名称' }]}>
                        <Input  />
                    </Form.Item>
            }
            <div style={{ display: 'flex' }}>
                <Form.Item label="测点名称"
                    labelCol={{ span: 6 }}
                    style={{ width: 400 }}
                    name="name"
                    rules={[{ required: true, message: '输入测点名称' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="测点ID"
                    labelCol={{ span: 10 }}
                    style={{ width: 200 }}
                    name="node"
                    rules={[{ required: true, message: '输入测点ID' }]}>
                    <Input disabled={changeDev}/>
                </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item label="断电"
                    labelCol={{ span: 10 }}
                    style={{ width: 150 }}
                    name="power_alarm"
                    valuePropName="checked"
                >
                    <Checkbox disabled={actDevice.node!==0}>
                        开启
                    </Checkbox>
                </Form.Item>
                <Form.Item label="开关门"
                    labelCol={{ span: 8 }}
                    style={{ width: 150 }}
                    name="door_alarm"
                    valuePropName="checked"
                >
                    <Checkbox disabled={actDevice.node!==0}>
                        开启
                    </Checkbox>
                </Form.Item>
                {
                    Mode === "soc"&&state.main.type<101&&
                    <div style={{color:'red'}}>
                        自动读卡：
                        {
                            simStatus1
                        }
                        <div>
                            填写卡
                            <span style={{color:'red'}}>{simStatus}</span>
                        </div>
                    </div>
                }
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item label="定位方式"
                    labelCol={{ span: 7 }}
                    style={{ width: 300 }}
                    name="location"
                    rules={[{ required: true, message: '选择定位方式' }]}>
                    <Select
                        placeholder="定位方式"
                    >
                        {
                            [{ id: 0, name: 'GPS定位' }, { id: 1, name: 'LBS定位' }, { id: 2, name: '无定位' }].map(item => (
                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                {
                    Mode === "soc"&&state.main.type<103&&
                    <div style={{width:230}}>
                        <Form.Item 
                            labelCol={{ span: 10}}
                            label="卡到期时间"
                            name="iccid_expire"
                        >
                            <DatePicker format={"YYYY-MM-DD"} style={{width:130}}/>
                        </Form.Item>
                    </div> 
                }
            </div>
            {/* sim卡号和编辑时间 */}
            <div style={{ display: 'flex' }}>
                <Form.Item style={{ width: 300 }}
                    labelCol={{ span: 7 }}
                    label="SIM卡号"
                    name="sim"
                    rules={[{ required: true, message: '输入SIM卡号' }]}>
                    <Input controls={false}  style={{width:140}} onChange=
                    {e=>
                        {
                            form.setFieldsValue({
                                iccid_expire_real:moment('2000-01-01', 'YYYY-MM-DD'),
                                simdate:moment(new Date(), 'YYYY-MM-DD'),
                                iccid:''
                            })
                            let val = e.target.value;
                            if(val.length===13){
                                let r =getSimBySim(val,simToken);
                                setTimeout(() => {
                                    setSimStatus(r.status);
                                    form.setFieldsValue({
                                        iccid_expire_real:moment(r.iccid_expire, 'YYYY-MM-DD'),
                                        iccid:r.iccid,
                                        simdate:moment(new Date(), 'YYYY-MM-DD'),
                                    })
                                }, 1000);
                                
                            }else{
                                // setSimStatus('卡号错误');
                                
                            }
                        }
                    }/>
                </Form.Item>
                {
                    Mode==='soc'&&
                    <Form.Item  
                        labelCol={{ span: 8 }}
                        label="编辑日期"
                        name="simdate"
                    >
                        <DatePicker format={"YYYY-MM-DD"} style={{width:130}} disabled />
                    </Form.Item>
                }
                
            </div>
            {/* 卡iccid和到期时间 */}
            <div style={{ display:  Mode==='soc'?'flex':'none' }}>
                <div style={{width:260}}>
                    <Form.Item 
                        labelCol={{ span: 6 }}
                        label="SIMid"
                        name="iccid"
                    >
                        <Input controls={false} style={{width:185}} onChange=
                        {e=>
                            {
                                form.setFieldsValue({
                                    sim:'',
                                    iccid_expire_real:moment('2000-01-01', 'YYYY-MM-DD'),
                                    simdate:moment(new Date(), 'YYYY-MM-DD'),
                                })
                                let val = e.target.value;
                                if(val.length===20){
                                    let r =getSimByIccid(val,simToken);
                                    setTimeout(() => {
                                        setSimStatus(r.status);
                                        if(r.state===1){
                                            setSimStatus("停机")
                                        }else if(r.state===2){
                                            setSimStatus("销号")
                                        }
                                        form.setFieldsValue({
                                            iccid_expire_real:moment(r.iccid_expire, 'YYYY-MM-DD'),
                                            sim:r.sim,
                                            simdate:moment(new Date(), 'YYYY-MM-DD'),
                                        })
                                    }, 1000);
                                    
                                }else{
                                    // setSimStatus('iccid错误');
                                }
                            }
                        }/>
                    </Form.Item>
                </div>
                {
                    Mode === "soc"&&state.main.type<101&&
                    <div style={{width:230}}>
                        <Form.Item 
                            labelCol={{ span: 10}}
                            label="到期时间"
                            name="iccid_expire_real"
                        >
                            <DatePicker format={"YYYY-MM-DD"} style={{width:130} } disabled/>
                        </Form.Item>
                    </div>
                }
                {
                    Mode === "soc"&&
                    <div style={{color:'red'}}>
                        {
                            simStatus
                        }
                    </div>
                }
            </div>
            
            {/* 自动读取sim卡号和编辑时间 */}
            <div style={{ display:  Mode==='soc'?'flex':'none' }}>
                {
                    Mode === "soc"&&state.main.type<101&&
                    <Form.Item style={{ width: 300 }}
                        labelCol={{ span: 8 }}
                        label="SIM卡号"
                        name="sim1"
                    >
                        <Input controls={false} disabled style={{width:140}} />
                    </Form.Item>
                }
                {
                    Mode === "soc"&&state.main.type<101&&
                    <Form.Item  
                        labelCol={{ span: 8 }}
                        label="编辑日期"
                        name="simdate1"
                    >
                        <DatePicker format={"YYYY-MM-DD"} style={{width:130}} disabled />
                    </Form.Item>
                }
            </div> 
            {/* 自动读取卡iccid和到期时间 */}
            <div style={{ display:  Mode==='soc'?'flex':'none' }}>
                {
                    Mode === "soc"&&state.main.type<101&&
                    <div style={{width:260}}>
                        <Form.Item 
                            labelCol={{ span: 6 }}
                            label="SIMid"
                            name="iccid1"
                        >
                            <Input controls={false} disabled  style={{width:185}} />
                        </Form.Item>
                    </div>
                }
                
                {
                    Mode === "soc"&&state.main.type<101&&
                    <div style={{width:230}}>
                        <Form.Item 
                            labelCol={{ span: 10}}
                            label="到期时间"
                            name="iccid_expire_real1"
                        >
                            <DatePicker format={"YYYY-MM-DD"} style={{width:130} } disabled/>
                        </Form.Item>
                    </div>
                    
                }
            </div>
            <div style={{ display: 'none' ,marginLeft:10}}>
                <Form.Item label="温度上限"
                    labelCol={{ span: 14}}
                    style={{width:150}} 
                    name="temp_alarm_high"
                    rules={[{ required: true, message: '输入温度上限' }]}>
                    <input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item label="温度下限"
                    labelCol={{ span: 14 }}
                    style={{width:150}}
                    name="temp_alarm_low"
                    rules={[{ required: true, message: '输入温度下限' }]}>
                    <Input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item label="湿度上限"
                    labelCol={{ span: 14 }}
                    style={{width:150}}
                    name="humi_alarm_high"
                    rules={[{ required: true, message: '输入湿度上限' }]}>
                    <input  style={{ width: 50 }}/>
                </Form.Item>
                <Form.Item label="湿度下限"
                    labelCol={{ span: 14 }}
                    style={{width:150}}
                    name="humi_alarm_low"
                    rules={[{ required: true, message: '输入湿度下限' }]}>
                    <Input  style={{ width: 50 }}/>
                </Form.Item>
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item label="测点隐藏"
                    labelCol={{ span: 8 }}
                    style={{ width: 300 }}
                    name="hide"
                    valuePropName="checked"
                >
                    <Checkbox style={{ width: 80 }}>隐藏</Checkbox>
                </Form.Item>
                {
                    (state.main.type < 103) &&
                    <Form.Item label="安装时间"
                        labelCol={{ span: 8 }}
                        style={{ width: 300 }}
                        name="time"
                        rules={[{ required: true, message: '选择安装时间' }]}>
                        <DatePicker format={"YYYY-MM-DD 00:00:00"} disabled={state.main.type >101}/>
                    </Form.Item>
                }
            </div>
            <div style={{ display: 'flex' }}>
                <Form.Item label="湿度数据"
                    labelCol={{ span: 8 }}
                    style={{ width: 300 }}
                    name="humi"
                    valuePropName="checked"
                >
                    <Checkbox>开启</Checkbox>
                </Form.Item>
                {
                    (state.main.type < 103)  &&Mode==='soc'&&
                    <Form.Item label="到期时间"
                        labelCol={{ span: 8 }}
                        style={{ width: 300 }}
                        name="expire"
                        rules={[{ required: true, message: '选择到期时间' }]}>
                        <DatePicker format={"YYYY-MM-DD 00:00:00"} disabled={state.main.type >101}/>
                    </Form.Item>
                }
            </div>
        </Form>
    )
}
function DeviceManage(props) {
    const [form] = Form.useForm()
    const { state, dispatch } = useContext(StateContext)
    const [actDevice, dispatchDevice] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.device
        } else if (action.type === 'clear') {
            let now = new Date();
            Mode==='soc'?now.setFullYear(now.getFullYear() + 1):now.setFullYear(now.getFullYear() + 100);
            if(Mode==='soc'){
                return {
                    name: '测点',//测点名称
                    type: 201,//主机类型
                    sn: '',
                    sim: '',
                    m_name: '',
                    node: 1,
                    district: '',
                    humi: 0,
                    hide: 0,
                    door_alarm: 0,
                    power_alarm: 0,
                    location: 2,
                    status: 0,
                    expire: now,
                    time: new Date(),
                    iccid_expire:new Date('2000-01-01'),
                    iccid:'',
                    simdate:new Date()
                }
            }else{
                return {
                    name: '测点',//测点名称
                    type: 201,//主机类型
                    sn: '',
                    sim: '',
                    m_name: '',
                    node: 1,
                    district: '',
                    humi: 0,
                    hide: 0,
                    door_alarm: 0,
                    power_alarm: 0,
                    location: 2,
                    status: 0,
                    expire: now,
                    time: new Date()
                }
            }
            
        }
        return state
    }, []);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)
    const [tableData, setTableData] = useState([]);//显示表格的数据源
    const [exportData, setExportData] = useState([]);//导出表格的数据源
    const [selDis, setSelDis] = useState([]);//选择的区域
    const [selUser, setSelUser] = useState(0);//树选择的用户
    const [selUserDis, setSelUserDis] = useState([]);//用户关联的区域 用户为空选择全部区域
    const [defExpend,setDefExpend]=useState([]);//树默认展示列表
    const [modalBounds,setModalBounds] = useState({left: 0, top: 0, bottom: 0, right: 0 });//对话框移动坐标
    const [modalMoveDisabled, setModalMoveDisabled] = useState(true);//对话框拖动功能关闭
    const draggleRef = React.createRef();
    const nodeSerch = React.createRef();
    const devSerch = React.createRef();
    const simSerch = React.createRef();
    const [filterCol,setFilterCol] =  useState('');
    const [tableLoad,setTableLoad] =  useState(true);
    const [simToken,setSimToken] = useState('')
    // const [defFilter,setDefFilter] = useState('')
    useEffect(() => {
        // setDefFilter(props.location.state?props.location.state.exp===2?1:props.location.state.exp===1?0:2:null)
        setTableLoad(true)
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) });
                let dev =[]
                if(props.location.state){
                    if(state.selUser_dev&&state.selUser_dev!==''){
                        dev = data.data.filter(x=>x.user_name===state.selUser_dev);
                    }else{
                        dev = data.data.filter(x=>x.user_name===props.location.state.user_name)
                    }
                }else{
                    dev = data.data
                }
                setTableData(dev);
                setTableLoad(false)
                let exportDevList=[];
                
                dev.map(item=>{
                    exportDevList.push({id:item.id,sn:item.sn,node:item.node,name:item.name,time:item.time,
                    type:state.devicetype.filter(x => { return x.code === item.type }).length > 0 ?state.devicetype.filter(x => { return x.code === item.type })[0].value:'不存在',
                    sim:item.sim,expire:item.expire,m_name:item.m_name,iccid:item.iccid,iccid_expire:item.iccid_expire,district_name:item.district_name,user_name:item.user_name,
                    })
                })
                setExportData(exportDevList);
            }
        })
        http.get("/v1/devicetype", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'devicetype', devicetype: data.data });
            }
        })

        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data });
                setSelUserDis(data.data)
            }
        })
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                let temp = data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh'));
                dispatch({ type: 'systemuser', systemuser: temp }) ; 
                if(data.data.length>0){
                    var list = [];
                    temp.filter(x=>x.parent===state.main.id).map(m=>list.push(m.id+""));
                    setDefExpend(list);         
                }     
            }
        })
    }, [state.selUser_dev])
    // useEffect(()=>{
    //     let simToken;
    //     fetch(' http://www.hebeijiuchen.com/sim/user/login.do?username=17734552912&password=123456', { //物联网卡登录 接口请求地址
    //         method: 'get',
    //     })
    //     .then((resp) => {
    //         resp.json().then(res=>{
    //             simToken = res.token
    //             setSimToken(res.token)
    //         })
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    //     http.get("/v1/devicestatus", {}).then(data => {
    //         if (data.code === 0) {
    //           data.data.filter(x=>x.Node===1).map(m=>{
    //             if(m.Online){
    //                 let tempEles = state.device.filter(x=>x.sn===m.Sn);
    //                 if(tempEles.length>0 && tempEles[0].iccid.length===20 ){
    //                     let r = getSimByIccid(tempEles[0].iccid,simToken);
    //                     setTimeout(()=>{
    //                         if(r.iccid_expire&&r.iccid_expire!==''){
    //                             let now = new Date(r.iccid_expire).getTime()-new Date().getTime();
    //                             if(now>365*24*60*60*1000){
    //                                 // form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD').subtract(1,'years')});
    //                             }else{
    //                                 // form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD') })
    //                             } 
    //                         }
    //                         // r.sim&&r.sim!==''&&form.setFieldsValue({sim: r.sim })
    //                     },1000)
    //                     // form.setFieldsValue({iccid: tempEles[0].iccid })
    //                 }
    //             }
    //           })
    //         }
    //     })
    // },[])
    const getDeviceStatus =()=>{
        let simToken;
        fetch(' http://www.hebeijiuchen.com/sim/user/login.do?username=17734552912&password=123456', { //物联网卡登录 接口请求地址
            method: 'get',
        })
        .then((resp) => {
            resp.json().then(res=>{
                simToken = res.token
                setSimToken(res.token)
            })
        }).catch((error) => {
            console.log(error);
        });
        http.get("/v1/devicestatus", {}).then(data => {
            if (data.code === 0) {
              data.data.filter(x=>x.Node===1).map(m=>{
                if(m.Online){
                    let tempEles = state.device.filter(x=>x.sn===m.Sn);
                    if(tempEles.length>0 && tempEles[0].iccid.length===20 ){
                        let r = getSimByIccid(tempEles[0].iccid,simToken);
                        setTimeout(()=>{
                            if(r.iccid_expire&&r.iccid_expire!==''){
                                let now = new Date(r.iccid_expire).getTime()-new Date().getTime();
                                if(now>365*24*60*60*1000){
                                    // form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD').subtract(1,'years')});
                                }else{
                                    // form.setFieldsValue({iccid_expire:moment(r.iccid_expire, 'YYYY-MM-DD') })
                                } 
                            }
                            r.sim&&r.sim!==''&&(tempEles[0].sim = r.sim);
                        },1000)
                        // form.setFieldsValue({iccid: tempEles[0].iccid })
                    }
                }
              })
            }
        })
    }
    const columns = [
        {
            title: '所属用户',
            align: 'center',
            render: (text, record) => (
                <div>
                    {
                        state.district.filter(x => x.id === record.district).length > 0 ?
                        state.systemuser.list.filter(x => x.id === state.district.filter(x => x.id === record.district)[0].user).length > 0 ?
                        state.systemuser.list.filter(x => x.id === state.district.filter(x => x.id === record.district)[0].user)[0].name : '无' : '无'
                    }
                </div>
            ),
            className:Mode==='local'&&'notshow'
        },
        {
            title: '所属区域',
            dataIndex: 'district_name',
            // key: 'id',
            align: 'center',
        },
        //测点名称
        {
            title: '测点名称',
            dataIndex: 'name',
            // key: 'id',
            align: 'center',
            render: (value, record) => (
                <div>
                    <div>
                        {
                            record.name
                        }
                    </div>
                    <div style={{ fontSize: 10, color: '#666' }}>
                        {
                            'ID:' + ((record.node * 1 < 10) ? '0' + record.node : record.node)
                        }
                    </div>
                </div>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={nodeSerch}
                        placeholder='设备信息'
                        value={selectedKeys[0]}
                        onChange={e => {
                            let val = e.target.value;
                            setSelectedKeys(val ? [val] : [])
                        }}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => {
                            clearFilters()
                        }}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => nodeSerch.current.focus({cursor: 'all'}), 100);//全选
                }
              },
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>{
                return record['node']||record['name']? 
                (record['node'].toString().toLowerCase().includes(value.toLowerCase()) ||
                record['name'].toLowerCase().includes(value.toLowerCase()))
                : ''
            }
                ,
        },
        //主机名称
        {
            title: '主机名称',
            dataIndex: 'm_name',
            // key: 'id',
            align: 'center',
            render: (value, record) => (
                <div>
                    <div>
                        {
                            record.m_name
                        }
                    </div>
                    <div style={{ fontSize: 10, color: '#666' }}>
                        {
                            record.sn
                        }
                    </div>
                </div>
            ),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={devSerch}
                        placeholder='主机名称'
                        value={selectedKeys[0]}
                        onChange={e => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => {
                            clearFilters()
                        }}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => devSerch.current.focus({cursor: 'all'}), 100);
                }
              },
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['m_name']||record['sn']
                    ? record['m_name'].toString().toLowerCase().includes(value.toLowerCase())||record['sn'].toString().toLowerCase().includes(value.toLowerCase())
                    : '',
        },
        //设备类型
        {
            title: '设备类型',
            dataIndex: 'type',
            // key: 'id',
            align: 'center',
            sorter: {
                compare:(a, b) => a.type - b.type,
                multiple: 5,
            },
            render: (text, record) => (
                <div>
                    {
                        state.devicetype.filter(x => { return x.code === record.type }).length > 0 ? state.devicetype.filter(x => { return x.code === record.type })[0].value : '无'
                    }
                </div>
            )
        },
        //SIM卡号
        {
            title: 'SIM卡号',
            dataIndex: 'sim',
            // key: 'id',
            align: 'center',
            sorter: {
                compare: (a, b) => a.sim - b.sim,
                multiple: 1,
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={simSerch}
                        placeholder='sim卡号'
                        value={selectedKeys[0]}
                        onChange={e => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => {
                            clearFilters();
                        }}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                  setTimeout(() => simSerch.current.focus({cursor: 'all'}), 100);
                }
              },
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['sim']
                ? record['sim'].includes(value)
                : '',
        },
        //添加时间
        {
            title: '添加时间',
            dataIndex: 'time',
            // key: 'id',
            align: 'center',
            sorter:{
                compare: (a, b) => Date.parse(a.time.replace(/T|Z/g, ' ').trim()) - Date.parse(b.time.replace(/T|Z/g, ' ').trim()),
                multiple: 2,
            },
            render:(text, record)=>text= text.slice(0,10)
            
        },
        //设备到期时间
        {
            title: '设备到期时间',
            dataIndex: 'expire',
            // key: 'id',
            align: 'center',
            sorter: {
                compare:(a, b) => Date.parse(a.expire.replace(/T|Z/g, ' ').trim()) - Date.parse(b.expire.replace(/T|Z/g, ' ').trim()),
                multiple: 3,
            },
            render:(text, record)=>text= text.slice(0,10),
            className:Mode==='local'&&'notshow'
        },
        //卡到期时间
        {
            title: '卡到期时间',
            dataIndex: 'iccid_expire',
            // key: 'id',
            align: 'center',
            sorter: {
                compare:(a, b) => Date.parse(a.iccid_expire.replace(/T|Z/g, ' ').trim()) - Date.parse(b.iccid_expire.replace(/T|Z/g, ' ').trim()),
                multiple: 3,
            },
            render:(text, record)=>text= text?text.slice(0,10):'',
            className:(Mode==='local'||state.main.type>101)&&'notshow'
        },
        //状态
        {
            title: '状态',
            dataIndex: 'expirestatus',
            // key: 'id',
            align: 'center',
            sorter: {
                compare:(a, b) => Date.parse(a.expire.replace(/T|Z/g, ' ').trim()) - Date.parse(b.expire.replace(/T|Z/g, ' ').trim()),
                multiple: 4,
            },
            render: (text, record) => {
                let days = parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24);
                return (
                <div>
                    {
                        days < 0 ? '已到期' : days < 30 ? '即将到期' : '正常'
                    }
                </div>
            )},
            filters: [
                {
                    text: '正常',
                    value: 2
                },
                {
                    text: '即将到期',
                    value: 1
                },
                {
                    text: '已到期',
                    value: 0
                },
            ],
            // filteredValue: defFilter ,
            onFilter: (value, record) => {
                if (value === 2) return parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) >= 30;
                if (value === 1) return (parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) >= 0 && parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) < 30);
                if (value === 0) return parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24) < 0;
            },
            className:Mode==='local'&&'notshow'
        },
        {
            title: 'IMEI',
            dataIndex: 'imei',
            align: 'center',
            className:(Mode==='local'||state.main.type>101)&&'notshow'
        },
        {
            title: '操作',
            width: 150,
            dataIndex: 'operation',
            key: 'id',
            align: 'center',
            render: (text, record) => (
                <div>
                    <Button size='small' style={{ marginLeft: '10px',borderRadius:10 }} type="primary" onClick={() => {
                        setShowFixModel(true)
                        //setActDevice(record)
                        changeDev = true;
                        dispatchDevice({ type: 'update', device: record })
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' ,borderRadius:10}} onClick={() => {
                        Modal.confirm({
                            centered: true,
                            title: `确定要删除设备${record.name}吗？`,
                            icon: <ExclamationCircleOutlined />,
                            content: `编号:${record.sn}测点:${record.node}删除之后将不可恢复！！`,
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/device/" + record.id, { data: record }).then((data) => {
                                    http.get("/v1/device", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) })
                                            let temp=[];
                                            if(selDis.length===0&&selUser===0){
                                                temp=data.data;
                                            }else if(selDis.length===0&&selUser!==0){
                                                let userDis = state.district.filter(x=>x.user===selUser);
                                                findUserDis(selUser,state.systemuser.list,state.district,userDis);
                                                userDis.map(item=>{
                                                    temp= temp.concat(data.data.filter(x=>x.district === item.id));
                                                })
                                            }else if(selDis.length>0){
                                                selDis.map(item=>{
                                                    temp= temp.concat(data.data.filter(x=>x.district === item));
                                                })
                                            }
                                            setTableData(temp);
                                        }
                                    })
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });

                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]
    const onStart = (event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) return;
        setModalBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    const onfinish = (values) => {
        console.log('进入onfinish');
        if (changeDev) {
            if(Mode==='soc'){
                http.put("/v1/device/" + actDevice.id, {
                    ...values,
                    status: actDevice.status, node: Number(values.node),
                    sn: Number(values.sn),
                    humi: values.humi ? 1 : 0,
                    door_alarm: values.door_alarm ? 1 : 0,
                    hide: values.hide ? 1 : 0,
                    power_alarm: values.power_alarm ? 1 : 0,
                    status: values.status ? 1 : 0,
                    expire:values.expire? values.expire.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.expire),
                    time: values.time?values.time.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.time),
                    iccid_expire:values.iccid_expire?values.iccid_expire.format("YYYY-MM-DD 00:00:00"):actDevice.iccid_expire,
                    imei:actDevice.imei
                }).then((data) => {
                    http.get("/v1/device", {}).then(data => {
                        if (data.code === 0) {
                            dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) })
                            let temp=[];
                            if(selDis.length===0&&selUser===0){
                                temp=data.data;
                            }else if(selDis.length===0&&selUser>0){
                                let userDis = state.district.filter(x=>x.user===selUser);
                                findUserDis(selUser,state.systemuser.list,state.district,userDis);
                                userDis.map(item=>{
                                    temp= temp.concat(data.data.filter(x=>x.district === item.id));
                                })
                            }else if(selDis.length>0){
                                selDis.map(item=>{
                                    temp= temp.concat(data.data.filter(x=>x.district === item));
                                })
                            }
                            setTableData(temp);
                        }
                    })
                })
            }else{
                http.put("/v1/device/" + actDevice.id, {
                    ...values,
                    status: values.status?1:0, node: Number(values.node),
                    sn: Number(values.sn),
                    humi: values.humi ? 1 : 0,
                    door_alarm: values.door_alarm ? 1 : 0,
                    hide: values.hide ? 1 : 0,
                    power_alarm: values.power_alarm ? 1 : 0,
                    expire:values.expire? values.expire.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.expire),
                    time: values.time?values.time.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.time),
                }).then((data) => {
                    http.get("/v1/device", {}).then(data => {
                        if (data.code === 0) {
                            dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) })
                            let temp=[];
                            if(selDis.length===0&&selUser===0){
                                temp=data.data;
                            }else if(selDis.length===0&&selUser>0){
                                let userDis = state.district.filter(x=>x.user===selUser);
                                findUserDis(selUser,state.systemuser.list,state.district,userDis);
                                userDis.map(item=>{
                                    temp= temp.concat(data.data.filter(x=>x.district === item.id));
                                })
                            }else if(selDis.length>0){
                                selDis.map(item=>{
                                    temp= temp.concat(data.data.filter(x=>x.district === item));
                                })
                            }
                            setTableData(temp);
                        }
                    })
                })
            }
            
        } else {
            if(Mode==='soc'){
                http.post("/v1/device", {
                    ...values,
                    node: Number(values.node),
                    sn: Number(values.sn),
                    humi: values.humi ? 1 : 0,
                    door_alarm: values.door_alarm ? 1 : 0,
                    power_alarm: values.power_alarm ? 1 : 0,
                    hide: values.hide ? 1 : 0,
                    status: values.status ? 1 : 0,
                    expire: values.expire?values.expire.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.expire),
                    time: values.time?values.time.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.time),
                    iccid_expire:values.iccid_expire?values.iccid_expire.format("YYYY-MM-DD 00:00:00"):actDevice.iccid_expire+' 00:00:00'
                }).then((data) => {
                    http.get("/v1/device", {}).then(data => {
                        if (data.code === 0) {
                            dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) });
                            let temp=[];
                            if(selDis.length===0&&selUser===0){
                                temp=data.data;
                            }else if(selDis.length===0&&selUser>0){
                                let userDis = state.district.filter(x=>x.user===selUser);
                                findUserDis(selUser,state.systemuser.list,state.district,userDis);
                                userDis.map(item=>{
                                    temp= temp.concat(data.data.filter(x=>x.district === item.id));
                                })
                            }else if(selDis.length>0){
                                selDis.map(item=>{
                                    temp= temp.concat(data.data.filter(x=>x.district === item));
                                })
                            }
                            setTableData(temp);
                        }
                    })
                })
            }else{
                console.log('sendpost');
                http.post("/v1/device", {
                    ...values,
                    node: Number(values.node),
                    sn: Number(values.sn),
                    humi: values.humi ? 1 : 0,
                    door_alarm: values.door_alarm ? 1 : 0,
                    power_alarm: values.power_alarm ? 1 : 0,
                    hide: values.hide ? 1 : 0,
                    status: values.status ? 1 : 0,
                    expire: values.expire?values.expire.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.expire),
                    time: values.time?values.time.format("YYYY-MM-DD 00:00:00"):formatDateTimes(actDevice.time),
                }).then((data) => {
                    http.get("/v1/device", {}).then(data => {
                        if (data.code === 0) {
                            dispatch({ type: 'device', device: data.data.sort((a,b)=>a.sn-b.sn) });
                            let temp=[];
                            if(selDis.length===0&&selUser===0){
                                temp=data.data;
                            }else if(selDis.length===0&&selUser>0){
                                let userDis = state.district.filter(x=>x.user===selUser);
                                findUserDis(selUser,state.systemuser.list,state.district,userDis);
                                userDis.map(item=>{
                                    temp= temp.concat(data.data.filter(x=>x.district === item.id));
                                })
                            }else if(selDis.length>0){
                                selDis.map(item=>{
                                    temp= temp.concat(data.data.filter(x=>x.district === item));
                                })
                            }
                            setTableData(temp);
                        }
                    })
                })
            }
            
        }

    }
    return (
        <div style={{ padding: 10 }}>
            <div style={{ float: 'left', marginBottom: 10 }}>
            {
                Mode==='soc'&&<TreeSelect
                showSearch
                style={{ width: 400 }}
                dropdownStyle={{ maxHeight: 500, overflow: 'auto' }}
                placeholder="选择用户"
                allowClear
                // defaultValue = {props.location.state?props.location.state.user_name:state.main.name}
                // multiple
                optionFilterProp="label"
                treeDefaultExpandedKeys={defExpend}
                onChange={value=> {
                    setSelDis([]);//清空关联区域
                    if(value===undefined||value===0){//清空用户选择
                        setTableData(state.device);//默认全部
                        setSelUserDis(state.district);//清空已选择用户的区域
                        setSelUser(0);//清空选择用户给区域选择提供判定条件
                    }else{
                        setSelUser(value);
                        let userDis = state.district.filter(x=>x.user===value);
                        findUserDis(value,state.systemuser.list,state.district,userDis);
                        let temp=[];
                        setSelUserDis(userDis);
                        userDis.map(item=>{
                            temp= temp.concat(state.device.filter(x=>x.district === item.id));
                        })
                        setTableData(temp);
                    }  
                    
                }}
                // treeData={[{title:'全部',value:'全部'}].concat(state.systemuser.tree)}
                treeData={state.systemuser.treeM}
                filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            />
            }
             <Select allowClear showSearch optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={selDis}
                mode="multiple"
                style={{ width: 360, marginLeft: 10 }}
                placeholder="选择区域"
                onChange={val=>{
                    let temp=[];
                    setSelDis(val);
                    if(val.length===0&&(selUser===0)){
                        temp=state.device;
                    }else if(val.length===0&&selUser>0){
                        let userDis = state.district.filter(x=>x.user===selUser);
                        findUserDis(selUser,state.systemuser.list,state.district,userDis);
                        userDis.map(item=>{
                            temp= temp.concat(state.device.filter(x=>x.district === item.id));
                        })     
                    }else if(val.length>0){
                        val.map(item=>{
                            temp= temp.concat(state.device.filter(x=>x.district === item));
                        })
                    }
                    setTableData(temp);
                }}
            >
                {
                    (()=>{
                        // let disData=[]; 
                        let disData=selUserDis;
                        // selUserDis.length>0?disData=selUserDis:disData=state.district
                        return disData.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        ))
                    })()
                }
            </Select>
            <Tag style={{marginLeft:10,fontSize:14}} color="cyan">{`主机数量:${tableData.filter(x=>x.node ===1).length}`}</Tag>
            <Tag style={{fontSize:14}} color="green">{`状态位数量:${tableData.filter(x=>x.node===0).length}`}</Tag>
            <Tag style={{fontSize:14}} color="cyan">{`测点数量:${tableData.filter(x=>x.node!==0).length}`}</Tag>
            <Tag style={{fontSize:14}} color="green">{`显示条数:${tableData.length}`}</Tag>
            </div>
            <div style={{ float: 'right', marginBottom: 10 }}>
            {
                    state.main.type<102&&
                    <Button onClick={() => {
                        // let exDev=JSON.parse(JSON.stringify(tableData));
                        let exDev = [];
                        let exDevIndex =1;
                        tableData.filter(x=>x.node!==0).map(m=>{
                            let temp = {};
                            temp.id = exDevIndex;
                            temp.user_name = m.user_name;
                            temp.district_name = m.district_name;
                            temp.m_name = m.m_name;
                            temp.sn = m.sn;
                            temp.node = m.node;
                            temp.name = m.name;
                            temp.type= m.type===201?'监测主机':m.type===202?'保温箱':m.type===203?'车载':m.type===209?'902保温箱':m.type===208?'无限设备':m.type===205?'压力传感器':'其他'
                            temp.time = m.time;
                            temp.expire = m.expire;
                            temp.sim = m.sim;
                            temp.iccid = m.iccid;
                            temp.iccid_expire = m.iccid_expire;
                            exDevIndex++;
                            exDev.push(temp)
                        })
                        if(filterCol.name){
                            let val = filterCol.name[0];
                            exDev=exDev.filter(x=>x.name.indexOf(val)!==-1||(x.node+'').indexOf(val)!==-1);
                        }
                        if(filterCol.m_name){
                            let val = filterCol.m_name[0];
                            exDev=exDev.filter(x=>x.m_name.indexOf(val)!==-1||(x.sn+'').indexOf(val)!==-1);
                        }
                        if(filterCol.sim){
                            let val = filterCol.sim[0];
                            exDev=exDev.filter(x=>x.sim.indexOf(val)!==-1);
                        }
                        if(filterCol.expirestatus){
                            let len = filterCol.expirestatus.length;
                            let arr = filterCol.expirestatus;
                            let arrDev=[];
                            for (let index = 0; index < len; index++) {
                                let val =arr[index];
                                let t;
                                if(val===0){
                                    t = exDev.filter(x=>(new Date(x.expire).getTime()-new Date().getTime())/ 1000 / 60 / 60 / 24< 0);
                                }
                                if(val===1){
                                    t = exDev.filter(x=>{
                                        let day = (new Date(x.expire).getTime()-new Date().getTime())/ 1000 / 60 / 60 / 24;
                                        return day<30 && day>=0
                                    });
                                }
                                if(val===2){
                                    t = exDev.filter(x=>(new Date(x.expire).getTime()-new Date().getTime())/ 1000 / 60 / 60 / 24 >= 30);
                                }
                                arrDev=arrDev.concat(t);
                            }
                            exDev = arrDev;
                        }
                        // exDev.map(val=>{
                        //     delete val.id;
                        //     delete val.district;
                        //     delete val.status;
                        //     delete val.location;
                        //     delete val.humi;
                        //     delete val.offline_alarm;
                        //     delete val.offline_alarm_time;
                        //     delete val.offline_alarm_interval;
                        //     delete val.offline_alarm_count;
                        //     delete val.link_alarm;
                        //     delete val.power_alarm;
                        //     delete val.door_alarm;
                        //     delete val.hide;
                        //     delete val.temp_warning_high;
                        //     delete val.humi_warning_high;
                        //     delete val.temp_warning_low;
                        //     delete val.humi_warning_low;
                        //     delete val.warning_interval;
                        //     delete val.upSn;
                        //     delete val.temp_alarm_high;
                        //     delete val.temp_alarm_low;
                        //     delete val.humi_alarm_high;
                        //     delete val.humi_alarm_low;
                        //     delete val.last_data_time;
                        //     delete val.imei;
                        //     val.user_name = val.user_name;
                        //     val.type= val.type===201?'监测主机':val.type===202?'保温箱':val.type===203?'车载':val.type===209?'902保温箱':val.type===208?'无限设备':val.type===205?'压力传感器':'其他'
                        // })
                        exportCsv({
                            title: ["序号","用户名",'区域名称',"设备名称",'设备编号',"测点ID",'测点名称',"设备类型",'添加时间',
                                    "到期时间",'sim卡号',"sim卡iccid","sim卡到期时间"],
                            data: exDev
                        })
                    }}>
                        导出CSV
                    </Button>
                }
                {
                    state.main.type<103&&
                    <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 10 }}
                        onClick={() => {
                            setShowNewModel(true)
                            dispatchDevice({ type: 'clear', project_id: state.main.project_id })
                        }}>新建设备
                    </Button>
                }
                
            </div>
            <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' dataSource={tableData}  loading={tableLoad}
                rowClassName={record=>{
                    // let days = parseInt((new Date(record.expire).getTime() - new Date().getTime()) / 1000 / 60 / 60 / 24);
                    // if (days<-20){
                    //     // console.log(record);
                    //     return 'expireLong'
                    // }
                    if(state.super>1){//超级管理权限
                    return record.status===1?'socDevice':record.hide===1?'hideDevice':''
                }}} 
                onChange={(page,fil)=>{
                    setFilterCol(fil)
                }}
            />
            <Modal maskClosable={false} title={
                    <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                    >
                    新增设备
                    </div>
                } visible={showNewModel}
                destroyOnClose 
                modalRender={modal => (
                    <Draggable
                      disabled={modalMoveDisabled}
                      bounds={modalBounds}
                      onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                cancelText='取消'
                width={600}
                okText='确定'
                onOk={() => {
                    console.log('点击增加设备');
                    form.submit();
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchDevice({ type: 'clear' })
                }}>
                <DeviceContext.Provider value={{ actDevice, dispatchDevice, form, onfinish }}>
                    <DeviceNew />
                </DeviceContext.Provider>
            </Modal>
            <Modal maskClosable={false} title={
                    <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={() => {
                        setModalMoveDisabled(false);
                    }}
                    onMouseOut={() => {
                        setModalMoveDisabled(true);
                    }}
                    >
                    修改设备
                    </div>
                }  visible={showFixModel}
                destroyOnClose style={{cursor: 'move'}}
                modalRender={modal => (
                    <Draggable
                      disabled={modalMoveDisabled}
                      bounds={modalBounds}
                      onStart={onStart}
                    >
                      <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}
                cancelText='取消'
                // footer={false}
                width={600}
                okText='确定'
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    changeDev = false;
                    dispatchDevice({ type: 'clear' })
                }}>
                <DeviceContext.Provider value={{ actDevice, dispatchDevice, form, onfinish }}>
                    <DeviceFix />
                </DeviceContext.Provider>
            </Modal>
        </div>
    )
}

export default DeviceManage