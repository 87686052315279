import React, { useState,useContext, useEffect} from 'react'
import { Map, Polyline, Marker } from "react-amap";
import moment from 'moment';
import unLocation from '../../../../../../public/assets/unLocation.png'
import {   CaretRightOutlined,  PauseOutlined,  UpCircleTwoTone} from '@ant-design/icons';
import {  Button, Slider, message, DatePicker } from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { Mode } from '../../../../../App';
import './device.less'
const { RangePicker } = DatePicker;
let interval = ''
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};

function DeviceMap(props) {
    const { state, dispatch } = useContext(StateContext)
    const [dataMap, setDataMap] = useState([]);//地图数据
    const [startMapTime, setStartMapTime] = useState('');//轨迹数据的开始时间
    const [endMapTime, setEndMapTime] = useState('');//轨迹数据的结束时间
    const [mapLine, setMapLine] = useState([]);//未回放的轨迹线
    const [mapLine1, setMapLine1] = useState([]);//已回访的轨迹线
    const [mapCenter, setMapCenter] = useState({ longitude: 116.397451, latitude: 39.909187 });//地图中心
    const [mapZoom, setMapZoom] = useState(15);//地图缩放
    const [markP, setmarkP] = useState({ });//回放轨迹点位置
    const [sliderStatus, setSilderStatus] = useState(0);//回放状态播放 暂停
    const [sliderRun, setSilderRun] = useState([]);//回放进度
    const [markerRota, setMarkerRota] = useState(0);//回放方向
    const [isLocation, setIsLocation] = useState(false);//是否定位
    function datadik(list, end) {
        setMapLine(list.slice(end+1));
        setMapLine1(list.slice(0,end+1));
        if(end===list.length-1)return;
        var ll = 0.1;
        mapZoom>15?ll=0.05:ll=0.1;
        if(Math.abs(list[end+1].longitude-mapCenter.longitude)>ll||Math.abs(list[end+1].latitude-mapCenter.latitude)>ll){
            setMapCenter({ longitude: list[end+1].longitude, latitude: list[end+1].latitude });
        };
        setmarkP({ longitude: list[end+1].longitude, latitude: list[end+1].latitude });
        setMarkerRota(list[end+1].ag);
    }
    useEffect(()=>{
        setStartMapTime(props.start);
        setEndMapTime(props.end);
        console.log(props.item.Status.GPSLng);
        props.item.Status.GPSLng!==0&&setMapCenter({longitude:props.item.Status.GPSLng,latitude: props.item.Status.GPSLat});
        props.item.Status.GPSLng!==0&&setmarkP({longitude:props.item.Status.GPSLng,latitude: props.item.Status.GPSLat});
        setMapZoom(15);
        setIsLocation(props.item.Status.GPSStatus?true:props.item.Status.BaseLac===0?false:true);
    },[])
    useEffect(() => {
        interval = setInterval(() => {
            if (sliderStatus > 0) {
                let a = sliderRun
                if (a === dataMap.length - 1) {
                    a = 0
                    setSilderStatus(0);
                } else {
                    a = a + 1
                }
                setSilderRun(a);
                datadik(dataMap, a);
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [sliderStatus, sliderRun])
  return (
    <div>
        <div style={{ marginBottom: '30px', fontSize: '14px', fontWeight: 'bold', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
            <div>
                <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{props.item.Name}</span>
                <span style={{ marginLeft: '10px' }}>主机名:</span> <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{props.item.MName}</span>
                <span style={{ marginLeft: '10px' }}>主机编号:</span> <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{props.item.Sn}</span>
                {
                    Mode!=='soc'?null:
                    <span><span style={{ marginLeft: '10px' }}>用户:</span><span style={{ fontWeight: 'normal', fontSize: '16px' }}>{state.district.filter(item=>item.id===props.item.District)[0].user_name}</span></span>
                }
                <span style={{ marginLeft: '10px' }}>区域:</span><span style={{ fontWeight: 'normal', fontSize: '16px' }}>{state.district.filter(item=>item.id===props.item.District)[0].name}</span>
            </div>
            {/* 日期选择 */}
            <RangePicker style={{ marginLeft: 20 }}
                ranges={{
                    今天: [moment().startOf('day'), moment()],
                    // '本周': [moment().startOf('week'), moment().endOf('week')],
                    '前一天':[moment().subtract(1,'days'), moment()],
                    '前三天':[moment().subtract(3,'days'), moment()],
                    '前五天':[moment().subtract(5,'days'), moment()],
                    '前七天':[moment().subtract(5,'days'), moment()],
                    '一小时': [moment().subtract(1,'hours'), moment()],
                    '三小时': [moment().subtract(3,'hours'), moment()],
                    '五小时': [moment().subtract(5,'hours'), moment()],
                    '六小时': [moment().subtract(6,'hours'), moment()],

                    }}
                showTime
                format="YYYY-MM-DD HH:mm"
                
                onChange={(dates, dateStrings) => {
                    if (dateStrings[0] !== "") {
                        setStartMapTime(formatDateTimes(dateStrings[0]));
                        setEndMapTime(formatDateTimes(dateStrings[1]))
                    }
                }}
            />
            {/* 查询按钮 */}
            <Button type={"primary"} onClick={() => {
                let url = "/v1/history?device=" + props.item.Sn + "&node=" + props.item.Node + "&starttime=" + startMapTime + "&endtime=" + endMapTime;
                http.get(url,{})
                .then((data) => {
                    if (data.code === 0) {
                        if(data.data===null) return message.warn('暂无数据');
                        const vgpsdata = data.data.filter(x => { return x.gps_lng !== 0 && x.gps_lat !== 0&&(x.gps!==0||x.lbs_cell!==0)})
                        if(vgpsdata.length>0){
                            var newp = [];
                            vgpsdata.map(item=>newp.push({ longitude: item.gps_lng, latitude: item.gps_lat, sp: item.gps_speed, ag: item.gps_dir, data_time:item.data_time }))
                            datadik(newp, 0);
                            setSilderRun(0);
                            setDataMap(newp);
                            setMapLine(newp);
                            setmarkP({longitude:vgpsdata[0].gps_lng,latitude: vgpsdata[0].gps_lat});
                            setMapCenter({longitude:vgpsdata[0].gps_lng,latitude: vgpsdata[0].gps_lat});
                            setIsLocation(true);
                        }else{
                            message.warn('暂无有效定位数据');
                        }
                    }
                })
            }}>查询</Button>
        </div>
        <div style={{ marginTop: 5, height: window.innerHeight - 300, position: 'relative' }}>
            {dataMap.length > 0 ?
                <div style={{ position: 'absolute', bottom: 50, right: '40%', width: 350, background: 'rgba(0,0,0,0.6)', zIndex: 2000, padding: 5 }}>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontSize: 14, marginRight: 10 ,color:'#fff'}}>时间轴</div>
                        <div style={{ flex: 1 }}></div>
                        {/* <FastBackwardOutlined onClick={() => { setSilderStatus(2); }} />
                        <FastForwardOutlined onClick={() => { setSilderStatus(3); }} /> */}
                        {sliderStatus > 0 ? <PauseOutlined style={{color:'#fff'}} onClick={() => { setSilderStatus(0) }} /> : <CaretRightOutlined style={{color:'#fff'}} onClick={() => { setSilderStatus(1) ;setMapZoom(10)}} />}

                    </div>
                    <Slider
                        style={{ flex: 1 }}
                        max={dataMap.length - 1}
                        value={sliderRun}
                        onChange={value => {
                            setSilderRun(value)
                            datadik(dataMap, value)
                        }}
                        tipFormatter={null}
                    />
                    <div style={{color:'#fff'}}>时间点：{dataMap[sliderRun]&&dataMap[sliderRun].data_time}</div>
                </div> : null}

            <Map plugins={['ToolBar']} center={mapCenter} events={{
                // created: (ins) => { console.log(ins) },
                // click: () => { console.log('You Clicked The Map') }
            }} zoom={isLocation?mapZoom:4}>
                <Marker position={markP} >
                {
                    isLocation?
                    <UpCircleTwoTone  style={{fontSize:25}} rotate={markerRota}/>
                    :
                    <img src={unLocation} style={{width:30,height:30}} />
                }
                </Marker>
                <Polyline
                    path={mapLine} showDir={true} style={{ strokeWeight: 8 }}
                />
                <Polyline
                    path={mapLine1}  style={{ strokeWeight: 8,strokeColor:'#2AB9EE' }}
                />
            </Map>
        </div>
    </div>
  )
}

export default DeviceMap