import React, { useContext, useEffect, useState } from 'react'
import { Progress, Modal, Tag, Form, Button, Table, Input,message } from 'antd'
import { Devices, LinkOne, LinkInterrupt } from '@icon-park/react';
import http from '../../../../utils/server'
import { StateContext } from '../../state';
import { Mode } from '../../../../App';
import audioSrc from '../../../../../public/assets/warning.mp3'
import { useHistory } from 'react-router-dom'
function formatDateTimes(inputTime) {
  var date = new Date(inputTime);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? ('0' + m) : m;
  var d = date.getDate();
  d = d < 10 ? ('0' + d) : d;
  var h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? ('0' + minute) : minute;
  second = second < 10 ? ('0' + second) : second;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};
const alarmtype = [
  '设备电源断电报警',
  '设备电源恢复报警',
  '设备监测开门报警',
  '设备监测关门报警',
  '探头电源异常报警',
  '探头电源恢复正常',
  '探头监测开门报警',
  '探头监测关门报警',
  '探头连接失败报警',
  '探头连接恢复正常',
  '探头温度超标报警',
  '探头温度恢复报警',
  '探头湿度超标报警',
  '探头湿度恢复报警',
  '探头温度预警',
  '探头湿度预警',
  '探头压强超标报警',
  '探头压强恢复报警',
]

function DashBoard() {
  let history = useHistory();
  const [form] = Form.useForm();
  const { state, dispatch } = useContext(StateContext)
  const [Online, setOnline] = useState(0)
  const [data, setData] = useState([])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [showDeal, setShowDeal] = useState(false)
  const [dealList, setDealList] = useState([])
  const [showDealMsgBox, setShowDealMsgBox] = useState(false)
  const [showDealMsg, setShowDealMsg] = useState([])
  const [tableLoad,setTableLoad] =  useState(true);
  let timer;
  let interval;

  function getAlarmData(){
    let audioEle = document.getElementById('audio');
    http.get("/v1/alarmday?day=" + formatDateTimes(new Date()), {}).then(data => {
      setTableLoad(false)
      if (data.code === 0) {
        if(data.data.findIndex(x=>x.status===0)!==-1){
          !state.alarmSound &&audioEle.play();
        }
        for (var i = 0; i < data.data.length; i++) {
          data.data[i]['key'] = data.data[i].id
        }
        setData(data.data.sort((a,b)=>b.id-a.id))
      }
    })
  }

  function quitIE(){
    localStorage.clear();
    history.push('/login')
  }

  useEffect(() => {
    setTableLoad(true)
    http.get("/v1/devicestatus", {}).then(data => {
      if (data.code === 0 && data.data !== null&&data.data.length>0) {
        var onlinep = Math.round(data.data.filter(x => { return x.Online }).length / data.data.length * 100)
        setOnline(onlinep)
        dispatch({ type: 'devicestate', devicestate: data.data })
      }
    })
    http.get("/v1/district", {}).then(data => {
      if (data.code === 0) {
        dispatch({ type: 'district', district: data.data })
      }
    })
    http.get("/v1/users", {}).then(data => {
      if (data.code === 0) {
        dispatch({ type: 'systemuser', systemuser: data.data })
        if(data.data.length>0){
          let d = data.data[0].todo_time;
          // if(d==='0001-01-01 00:00:00') return;
          var date = new Date(d).getTime()-new Date().getTime();
          if(date*1<0) return;
          // parseInt(date/(1000*60*60*24))<=0 &&
          // Modal.warning({
          //   title: '年检提醒',
          //   content: `年检时间已过,设定的年检时间:${d.slice(0,10)}`,
          //   okText: '确认',
          //   cancelText: '取消',
          // }) 
          parseInt(date/(1000*60*60*24))<30 &&parseInt(date/(1000*60*60*24))>0&&
          Modal.warning({
            title: '年检提醒',
            content: `即将开始年检,年检时间:${d.slice(0,10)}`,
            okText: '确认',
            cancelText: '取消',
          })
        }
      }
    })
    http.get("/v1/userdistricts", {}).then(data => {
      if (data.code === 0) {
        dispatch({ type: 'user_district', user_district: data.data })
      }
    })
    http.get("/v1/device", {}).then(data => {
      if (data.code === 0) {
        dispatch({ type: 'device', device: data.data })
      }
    })

    http.get("/v1/alarmsuer", {}).then(data => {
      if (data.code === 0) {
          dispatch({ type: 'alarmsuer', alarmsuer: data.data })
      }
    })
    getAlarmData()

    // Mode==='soc'&&Modal.info({
    //   title: '更新提示',
    //   width:600,
    //   content: (
    //     <div style={{fontSize:20}}>
    //       <p>实时数据双击测点更改为查询主机多测点历史数据</p>
    //       <p>实时数据中单击参数设置或者设备编号进行参数设置</p>
    //       <p>添加报警联系人时必须要用手机扫描二维码关注公众号才能收到微信报警</p>
    //     </div>
    //   ),
    //   onOk() {},
    // });
    return () => clearInterval(timer)
  }, [])
  useEffect(() => {
    clearInterval(interval);
    interval = setInterval(() => {
      getAlarmData()
    }, 5*60*1000)
    return () => clearInterval(interval)
  }, [])
  const columns = [
    {
      title: '报警时间',
      dataIndex: 'alarm_date',
      key: 'alarm_date',
      align: 'center',
  },
  Mode!=='soc'?{width:0}:{
      title: '所属用户',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
  },
  {
      title: '所属区域',
      dataIndex: 'districtname',
      key: 'districtname',
      align: 'center',
  },
  {
      title: '主机',
      dataIndex: 'device',
      key: 'device',
      align: 'center',
      render:(text,record)=>(
          <div>
              <div>{record.m_name}</div>
              <div style={{fontSize:8,color:'#ccc'}}>编号:{record.device}</div>
          </div>
      )
  },
  {
      title: '测点',
      dataIndex: 'node',
      key: 'node',
      align: 'center',
      render:(text,record)=>(
          <div>
              <div>{record.name}</div>
              <div style={{fontSize:8,color:'#ccc'}}>编号:{record.node}</div>
          </div>
      )
  },
  {
      title: '报警类型',
      dataIndex: 'type',
      key: 'type',
      align: 'left',
      width:150,
      render: (text, record) => (
          <div>{alarmtype[record.type]}</div>
      )
  },
  {
    title: '记录值',
    // dataIndex: 'temp',
    // key: 'temp',
    align: 'center',
    width: 180,
    render: (text, record) =>{ 
        if(record.type===10||record.type===11||record.type===14){
            return(
                <div>
                    <div>{record.temp}℃</div>
                    <div style={{ fontSize: 10, color: '#aaa' }}>上限:{record.temp_h}℃-下限:{record.temp_l}℃</div>
                </div>
            )
        }
        if(record.type===12||record.type===13||record.type===15) return (
            <div>
                <div>{record.humi}%RH</div>
                <div style={{ fontSize: 10, color: '#aaa' }}>上限:{record.humi_h}%RH-下限:{record.humi_l}%RH</div>
            </div>
        )
        if(record.type===16||record.type===17) return(
            <div>
                <div>{record.temp}Pa</div>
                <div style={{ fontSize: 10, color: '#aaa' }}>上限:{record.temp_h}Pa-下限:{record.temp_l}Pa</div>
            </div>
        )
    }
  },
  {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record) => (
          record.status === 0 ? <Tag color={'red'}>未处理</Tag> :
              <Button size={"small"} onClick={() => {
                  setShowDealMsg(JSON.parse(record.msg))
                  setShowDealMsgBox(true)
              }}>已处理</Button>
      )
  }
  ]
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys)
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.status === 1, // Column configuration not to be checked
    }),
  };
  return (
    <div>
      <audio id="audio"  src={audioSrc} />
      <div style={{ display: 'flex', padding: '10px 0px', background: 'rgb(255, 255, 255)', borderRadius: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexGrow: 1, margin: 10, borderRight: '1px solid #ccc' }}>
          <div style={{ margin: 10 }}>
            <Progress width={80} type="circle" strokeColor={'#1890ff'} strokeWidth={10} percent={Online} format={Online == 100 ? () => '全部在线' : percent => <div style={{ color: '#1890ff' }}>{percent}%</div>} />
          </div>
          <div style={{ margin: 5 }}>
            <div style={{ textAlign: 'center', fontSize: 30, margin: 5, }}>{Online}%</div>
            <div style={{ textAlign: 'center', fontSize: 16, color: '#ccc', }}> 在线比例</div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexGrow: 1, margin: 10, borderRight: '1px solid #ccc' }}>
          <div style={{ margin: 10 }}>
            <Devices theme="outline" size="24" fill="#333" />
          </div>
          <div style={{ margin: 5 }}>
            <div style={{ textAlign: 'center', fontSize: 30, margin: 5, }}>{state.device!==null&&state.device.filter(x=>x.node!==0).length}</div>
            <div style={{ textAlign: 'center', fontSize: 16, color: '#ccc', }}> 设备总数</div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexGrow: 1, margin: 10, borderRight: '1px solid #ccc' }}>
          <div style={{ margin: 10 }}>
            <LinkOne theme="outline" size="24" fill="#333" />
          </div>
          <div style={{ margin: 5 }}>
            <div style={{ textAlign: 'center', fontSize: 30, margin: 5 }}>{state.devicestate!==null&&state.devicestate.filter(x => { return x.Online &&x.Node!==0 }).length}</div>
            <div style={{ textAlign: 'center', fontSize: 16, color: '#ccc' }}> 在线设备</div >
          </div >
        </div >
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexGrow: 1, margin: 10, borderRight: '1px solid #ccc' }}>
          <div style={{ margin: 10 }}>
            <LinkInterrupt theme="outline" size="24" fill="#333" />
          </div>
          <div style={{ margin: 5 }}>
            {/* <div style={{ textAlign: 'center', fontSize: 30, margin: 5 }}>{state.devicestate.filter(x => { return !x.Online }).length}</div>
            <div style={{ textAlign: 'center', fontSize: 16, color: '#ccc' }}> 离线设备</div > */}
            <div style={{ textAlign: 'center', fontSize: 30, margin: 5 }}>{data.length}</div>
            <div style={{ textAlign: 'center', fontSize: 16, color: '#ccc' }}> 当日报警</div >
          </div >
        </div >
        {
          Mode==='soc'&&<div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexGrow: 1, margin: 10 }}>
          <div style={{ margin: 5 }}>
            <div style={{ textAlign: 'center', fontSize: 30, margin: 5 }}>{state.systemuser.list.length>0&&state.systemuser.list[0].smscount-state.systemuser.list[0].smssendcount}</div>
            <div style={{ textAlign: 'center', fontSize: 16, color: '#ccc' }}> 短信条数</div >
          </div >
        </div >
        }
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '5px 0px'
      }}>
        <div style={{ border: '1px solid #f0f0f0', padding: "4px 10px", color: '#1890ff', textShadow: '0 0 .25px currentColor' }}>
          当日报警数据看板
        </div>
        <div style={{ flex: 1, borderBottom: '1px solid #f0f0f0' }}></div>
        <Button type={"primary"} onClick={() => {
          const sle = data.filter(x => { return selectedRowKeys.indexOf(x.id) > -1 })
          if(sle.length===0) return message.warn("未选择报警记录",3);
          var nsle = new Array()
          sle.map(item => {
            nsle.push({ id: item.id, date: item.alarm_date })
          })
          setDealList(nsle)
          setShowDeal(true)
        }}>处理报警</Button>
      </div>
      <Table size={'small'} rowSelection={rowSelection} bordered columns={columns} pagination={{ hideOnSinglePage: true }} dataSource={data} loading={tableLoad} />
      <Modal maskClosable={false}
        title="报警处理信息"
        footer={null}
        visible={showDealMsgBox}
        onCancel={() => {
          setShowDealMsgBox(false)
        }}>
        <div style={{ margin: 5 }}>报警原因：{showDealMsg.why || "未知"}</div>
        <div style={{ margin: 5 }}>处理人员：{showDealMsg.who || "未知"}</div>
        <div style={{ margin: 5 }}>处理结果：{showDealMsg.deal || "未知"}</div>
      </Modal>
      <Modal maskClosable={false}
        title="处理报警"
        footer={null}
        visible={showDeal}
        onCancel={() => {
          setShowDeal(false)
        }}>
        <Form
          name="basic"
          form={form}
          onFinish={values => {
            http.post("/v1/dealalarm", { alarm: dealList, msg: JSON.stringify(values) }).then(data => {
              if (data.code === 0) {
                var now_date = new Date();
                now_date.setHours(0);
                now_date.setMinutes(0);
                now_date.setSeconds(0);
                http.get("/v1/alarmday?day=" + formatDateTimes(now_date), {}).then(data => {
                  if (data.code === 0) {
                    for (var i = 0; i < data.data.length; i++) {
                      data.data[i]['key'] = data.data[i].id
                    }
                    setData(data.data)
                  }
                })
              }
            })
          }}
        >
          <Form.Item
            label="报警原因"
            name="why"
            rules={[{ required: true, message: '输入报警原因' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="处理人员"
            name="who"
            rules={[{ required: true, message: '输入处理人员' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="处理方法"
            name="deal"
            rules={[{ required: true, message: '输入处理方法' }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
        <Button type="primary" onClick={() => {
          form.submit()
        }}>
          确定
        </Button>
      </Modal>
    </div>
  )
}

export default DashBoard