import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Table, Button, Input, Select, Modal,} from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { ExclamationCircleOutlined } from '@ant-design/icons';

function SelectInput(props) {
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ ...props.style, padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>{props.addonBefore}</div>
            <Select disabled={props.disabled} value={props.defaultValue} style={{ ...props.style, flex: 1 }} onChange={(value) => { props.onChange && props.onChange(value) }}>
                {props.option.map((item, index) => (
                    <Select.Option key={index} value={item[props.keyword]}>{item[props.text]}</Select.Option>
                ))}
            </Select>
        </div>
    )
}

const GoodsContext = createContext();

function GoodsNew() {
    const { actGoods, dispatchGoods } = useContext(GoodsContext)
    const { state } = useContext(StateContext)
    const [savecycle, setSavecycle] = useState(0)
    const [defExpend,setDefExpend]=useState([]);
    useEffect(() => {
        var userLogin = state.systemuser.list[0];
        var list = [];
        state.systemuser.list.filter(x=>x.parent===userLogin.id).map(m=>list.push(m.id+""));
        setDefExpend(list);
        if (actGoods.save_cycle === -1) 
            setSavecycle(2)
        else if (actGoods.save_cycle === 100000000)
            setSavecycle(1)
        else if (actGoods.save_cycle === 0)
            setSavecycle(0)
        else
            setSavecycle(3)

    }, [])
    return (
        <div>
            <Input style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>物品名称:</span></span>} value={actGoods.name}
                onChange={(e) => { dispatchGoods({ type: 'update', goods: { ...actGoods, name: (e.target.value) } }) }} />
            <SelectInput style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>物品类型:</span></span>} defaultValue={actGoods.type} option={state.goodstype} keyword={"code"} text='value'
                onChange={value => { dispatchGoods({ type: 'update', goods: { ...actGoods, type: value } }) }} />
            {/* <SelectInput style={{ marginBottom: 10 }} addonBefore={<span style={{color:'red'}}>* <span style={{color:'#000'}}>所属单位:</span></span>} defaultValue={actGoods.user} option={state.transuser.filter(x=>x.users===state.main.id)} keyword={"code"} text='value'
                onChange={value => { dispatchGoods({ type: 'update', goods: { ...actGoods, type: value } }) }} /> */}
        </div>
    )
}

function GoodsManage() {
    const { state, dispatch } = useContext(StateContext)
    const [actGoods, dispatchGoods] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.goods
        } else if (action.type === 'clear') {
            return {
                type: '',
                name: '',
                users:action.users,
            }
        }
        return state
    }, []);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)
    useEffect(() => {
        http.get("/v1/transgoods", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'goods', goods: data.data })
            }else{
                dispatch({ type: 'goods', goods: [] })
            }
        })
        http.get("/v1/goodstype", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'goodstype', goodstype: data.data })
            }else{
                dispatch({ type: 'goodstype', goodstype: [] })
            }
        })
        // http.get("/v1/transuser", {}).then(data => {
        //     if (data.code === 0) {
        //         dispatch({ type: 'transuser', transuser: data.data })
        //     }
        // });
    }, [])
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            align: 'left',
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            align: 'left',
            render: (text, record) => (
                <div>
                    {
                        state.goodstype.filter(x => { return x.code === record.type }).length > 0 ? state.goodstype.filter(x => { return x.code === record.type })[0].value : '无'
                    }
                </div>
            )
        },
        // {
        //     title: '所属区域',
        //     dataIndex: 'district',
        //     key: 'district',
        //     align: 'center',
        //     render: (text, record) => (
        //         <div>
        //             {
        //                 state.district.filter(x => { return x.id === record.district }).length > 0 ? state.district.filter(x => { return x.id === record.district })[0].name : '无'
        //             }
        //         </div>
        //     )
        // },                
        {
            title: '操作',
            width: 200,
            dataIndex: 'operation',
            key: 'operation',
            align: 'right',
            width: '180px',
            render: (text, record) => (
                <div>
                    <Button size='small' type="primary" onClick={() => {
                        setShowFixModel(true)
                        //setActGoods(record)
                        dispatchGoods({ type: 'update', goods: record })
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={() => {
                        Modal.confirm({
                            centered: true,
                            title: `确定要删除物品<${record.name}>吗？`,
                            icon: <ExclamationCircleOutlined />,
                            content: `<${record.name}>删除之后将不可恢复！！`,
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/transgoods/" + record.id, { data: record }).then((data) => {
                                    http.get("/v1/transgoods", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'goods', goods: data.data })
                                        }
                                    })
                                })
                            },
                            onCancel() {
                                console.log('Cancel');
                            },
                        });
                        
                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]

    return (
        <div>
            <div style={{ float: 'right', marginBottom: 10 }}>
                <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 5 }}
                onClick={() => {
                    setShowNewModel(true)
                    let login = state.main;
                    dispatchGoods({ type: 'clear', users:login.company===1?login.id:login.belong });
                }}>添加物品</Button>
            </div>
            <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' dataSource={state.goods} />
            <Modal maskClosable={false} title="添加物品" visible={showNewModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.post("/v1/transgoods", actGoods).then((data) => {
                        http.get("/v1/transgoods", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'goods', goods: data.data })
                            }
                        })
                    })
                    setShowNewModel(false)
                    dispatchGoods({ type: 'clear', project_id: state.main.project_id })
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchGoods({ type: 'clear', project_id: state.main.project_id })
                }}>
                <GoodsContext.Provider value={{ actGoods, dispatchGoods }}>
                    <GoodsNew />
                </GoodsContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="修改地址" visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.put("/v1/transgoods/" + actGoods.id, actGoods).then((data) => {
                        http.get("/v1/transgoods", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'goods', goods: data.data })
                            }
                        })
                    })
                    setShowFixModel(false)
                    dispatchGoods({ type: 'clear', project_id: state.main.project_id })
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    dispatchGoods({ type: 'clear', project_id: state.main.project_id })
                }}>
                <GoodsContext.Provider value={{ actGoods, dispatchGoods }}>
                    <GoodsNew />
                </GoodsContext.Provider>
            </Modal>
        </div>
    )
}

export default GoodsManage