import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Table, Button, Input, Select, Modal, Form, DatePicker, Space,Card} from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import md5 from 'js-md5';
import {ExclamationCircleOutlined , SearchOutlined} from '@ant-design/icons';
import "./index.less"
import moment from 'moment';
const AreaContext = createContext();

function LocalUserNew() {
    const { actUser, dispatchUser, form, onfinishNew } = useContext(AreaContext)
    const { state } = useContext(StateContext)
    const [selectUserType, setSelectUserType] = useState(101)
    const formRef = React.createRef();
    const [defExpend,setDefExpend]=useState([]);

    useEffect(() => {
        form.setFieldsValue(actUser);
        var userLogin = state.systemuser.list[0];
        var list = [];
        state.systemuser.list.filter(x=>x.parent===userLogin.id).map(m=>list.push(m.id+""));
        setDefExpend(list);
    }, [])


    return (
        <div >
            <Form
                form={form}
                ref={formRef}
                onFinish={onfinishNew}>
                <Form.Item
                    label="用户名称"
                    name="name"
                    rules={[{ required: true, message: '输入用户名称' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="用户账号"
                    name="account"
                    rules={[{ required: true, message: '输入用户账号' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="登录密码"
                    rules={[
                        {
                            required: true,
                            message: '请输入登录密码',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="重复登录密码"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请再输入一次登录密码',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不相同'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    label="用户类型"
                    name="type"
                    dependencies={['parent']}
                    rules={[{ required: true, message: '用户类型' }]}>
                    <Select
                        // defaultValue={104}
                        style={{ width: '100%', flex: 1 }}
                        placeholder="用户类型"
                        onSelect={value=>{
                            value===104?dispatchUser({ type:'update',user:{role:'301,304'}}):dispatchUser({type:'update',user: {role:'301,302,304,305,307,308,309,310'}});
                            setSelectUserType(value);
                        }}
                    >
                        <Select.Option key={103} value={103}>管理用户</Select.Option> 
                        <Select.Option key={104} value={104}>普通用户</Select.Option>
                    </Select>
                </Form.Item>
                <div>
                    {
                        selectUserType>103?
                        <Card title="用户权限" style={{ width: '100%' }}>
                            <p style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                                <span>数据查询</span>
                                <span>参数查询</span>
                                <span>报警处理</span>
                            </p>
                            
                            <p style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                                <span>导出数据</span>
                                {/* <span>日志查询</span> */}
                                {/* <span>修改标题</span> */}
                                
                            </p>
                        </Card>
                        :
                        <Card title="用户权限" style={{ width: '100%' }}>
                            <p style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                                <span>用户管理</span>
                                <span>区域管理</span>
                                <span>设备管理</span>
                            </p>
                            <p style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                                <span>平面管理</span>
                                <span>数据查询</span>
                                <span>参数设置</span>
                            </p>
                            <p style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
                                <span>导出数据</span>
                                <span>报警处理</span>
                                <span>日志查询</span>
                            </p>
                            <p>修改标题</p>
                        </Card>
                    }
                    
                </div>
            </Form>

        </div>
    )
}
function LocalUserFix() {
    const { actUser, dispatchUser, form, onfinish } = useContext(AreaContext)
    const { state } = useContext(StateContext)

    useEffect(() => {
        form.setFieldsValue(actUser)
    }, [])

    return (
        <Form
            form={form}
            onFinish={onfinish}>
            <Form.Item
                label="用户名称"
                name="name"
                rules={[{ required: true, message: '输入用户名称' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                label="用户账号"
                name="account"
                rules={[{ required: true, message: '输入用户账号' }]}>
                <Input disabled />
            </Form.Item>
        </Form>
    )
}
function LocalUserManage() {
    const [form] = Form.useForm()
    const { state, dispatch } = useContext(StateContext)
    const [actUser, dispatchUser] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.user
        } else if (action.type === 'clear') {
            return {
                role: '',
                roles: [],
                password: "",
                phone: '',
                name: '',
                account: '',
                npw1: '',
                npw2: '',
                parent:'',
                type:''
            }
        }
        return state
    },[]);
    const [showNewModel, setShowNewModel] = useState(false)
    const [showFixModel, setShowFixModel] = useState(false)
    const [reSetPassWord, setReSetPassWord] = useState('')
    const [isreSetPassWord, setIsReSetPassWord] = useState(false);
    const [userParentRoles, setUserParentRoles] = useState('');
    const [showNotify, setShowNotify] = useState(false);
    const [todo_time, setTodo_time] = useState(moment().add(1,'years').format('yyyy-MM-DD'));
    useEffect(() => {
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data })
                // setLocalUser(data.data);
            }
        })
    }, [])


    const onfinish = (values) => {
        http.put("/v1/users/" + actUser.id, { ...actUser, ...values, role: '',forbiden: values.forbiden? 1 : 0, }).then((data) => {
            http.get("/v1/users", {}).then(data => {
                if (data.code === 0) {
                    dispatch({ type: 'systemuser', systemuser: data.data })
                }
            })
        })

        setShowFixModel(false)
        dispatchUser({ type: 'clear', project_id: state.main.project_id })
    }
    const onfinishNew = (values) => {
        console.log(actUser.role);
        http.post("/v1/users", { ...values,password:md5(values.password), role:actUser.role,parent:1 }).then((data) => {
            http.get("/v1/users", {}).then(data => {
                if (data.code === 0) {
                    dispatch({ type: 'systemuser', systemuser: data.data })
                }
            })
        })
        setShowNewModel(false)
    }
    const columns = [

        {
            title: '用户名称',
            dataIndex: 'name',
            key: 'id',
            width: 300,
            align: 'left',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='用户名称'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['name']
                    ? (record['name'].toString().toLowerCase().includes(value.toLowerCase()))
                    : '',
        },
        {
            title: '账号',
            dataIndex: 'account',
            key: 'account',
            align: 'center',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder='账号'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

            onFilter: (value, record) =>
                record['account']
                    ? (record['account'].toString().toLowerCase().includes(value.toLowerCase()))
                    : '',
        },
        {
            title: '用户类型',
            dataIndex: 'type',
            key: 'id',
            align: 'center',
            render: (text, record) => (
                <div>
                    {
                        record.type === 101 ? '系统管理员': record.type === 103 ? '管理用户' :  '普通用户'
                    }
                </div>
            ),
        },
        {
            title: '操作',
            dataIndex: 'operation',
            align: 'right',
            width: '400px',
            render: (text, record) => (
                <div>
                    {
                        (record.type === 102 || record.type === 103) &&
                        <Button size='small' style={{ marginLeft: '10px' }} type="primary" onClick={() => {
                            setShowNotify(true)
                            const user = state.systemuser.list.filter(x => { return x.id === record.id })
                            if (user.length > 0) {
                                dispatchUser({ type: 'update', user: user[0] })
                            }
                        }}>
                            添加提醒
                        </Button>
                    }

                    <Button size='small' style={{ marginLeft: '10px' }} type="primary" onClick={() => {
                        setReSetPassWord(record.account);
                        setIsReSetPassWord(true);
                    }}>
                        重置密码
                    </Button>
                    <Button size='small' style={{ marginLeft: '10px' }} type="primary" onClick={() => {
                        setShowFixModel(true)

                        const user = state.systemuser.list.filter(x => { return x.id === record.id })
                        if (user.length > 0) {
                            const parent = state.systemuser.list.filter(x => { return x.id === user[0].parent })
                            if (parent.length > 0) {
                                setUserParentRoles(parent[0].role)
                            }
                            dispatchUser({ type: 'update', user: { ...user[0], roles: user[0].role.split(',').map(Number) } })
                        }
                    }}>
                        修改
                    </Button>
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' }} onClick={() => {
                        Modal.confirm({
                            centered: true,
                            title: `确定要删除用户<${record.name}>吗？`,
                            icon: <ExclamationCircleOutlined />,
                            content: `账号${record.account},删除之后将不可恢复！！`,
                            okText: '删除',
                            okType: 'danger',
                            cancelText: '取消',
                            onOk() {
                                http.delete("/v1/users/" + record.id, { data: record }).then((data) => {
                                    http.get("/v1/users", {}).then(data => {
                                        if (data.code === 0) {
                                            dispatch({ type: 'systemuser', systemuser: data.data });
                                        }
                                    })
                                })
                            },
                            onCancel() {
                            },
                        });
                    }}>
                        删除
                    </Button>
                </div>
            ),
        },
    ]

    return (
        <div style={{ padding: 15 }}>
            <div style={{ float: 'right', marginBottom: 10 }}>
                <Button style={{ backgroundColor: 'green', color: '#fff', borderRadius: 5 }}
                    onClick={() => {
                        setShowNewModel(true)
                        dispatchUser({ type: 'clear' })
                    }}>新建用户</Button>
            </div>
            <Table className='userTable' 
             bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' 
            dataSource={state.systemuser.list} />
            <Modal maskClosable={false} title="新增用户"
                visible={showNewModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchUser({ type: 'clear'})
                }}>
                <AreaContext.Provider value={{ actUser, dispatchUser, form, onfinishNew }}>
                    <LocalUserNew />
                </AreaContext.Provider>
            </Modal>
            <Modal maskClosable={true} title="修改用户"
                visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    form.submit()
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}>
                <AreaContext.Provider value={{ actUser, dispatchUser, form, onfinish, userParentRoles }}>
                    <LocalUserFix />
                </AreaContext.Provider>
            </Modal>
            <Modal maskClosable={true} title="修改密码"
                destroyOnClose
                visible={reSetPassWord !== ''}
                closable={false}
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    form.submit();
                    setIsReSetPassWord(false);
                    
                }}
                onCancel={() => {
                    setReSetPassWord('')
                    setIsReSetPassWord(false);
                }}>
                <Form
                    name="basic"
                    form={form}
                    onFinish={values => {
                        http.post("/v1/userspassword", { who: state.main.account, password: md5(values.password), account: reSetPassWord, newpassword: md5(values.newpassword) }).then(data => {
                            if (data.code === 0) {

                            } else {
                                Modal.warning({
                                    title: data.code,
                                    content: data.msg,
                                });
                            }
                        })
                    }}
                >
                    <Form.Item
                        label="管理密码"
                        name="password"
                        rules={[{ required: true, message: '输入当前账号密码' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="新密码"
                        name="newpassword"
                        rules={[{ required: true, message: '输入被重置的新密码' }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="重复新密码"
                        name="newnewpassword"
                        dependencies={['newpassword']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '再次输入被重置的新密码',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newpassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('两次输入的密码不相同'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal maskClosable={true} title="增加提醒"
                visible={showNotify}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.post("/v1/userstodo", { account: actUser.account, todo: '到期年检',todo_time:todo_time}).then(res=>res.code!==0&&Modal.warning({
                        title: res.code,
                        content: res.msg,
                    }));
                    setShowNotify(false);
                }}
                onCancel={() => {
                    setShowNotify(false);
                    dispatchUser({ type: 'clear', project_id: state.main.project_id })
                }}
            >
                <div>
                    <Input addonBefore="提醒内容"  allowClear defaultValue='到期年检' disabled={true} />
                    <div style={{flexFlow:'row',display:'flex',marginTop:'10px',alignItems:'center'}}>
                        <span style={{fontSize:'14px',display:'inline-block',border:'1px solid #D9D9D9',padding:'4px 11px'}}>提醒日期</span>
                        <DatePicker onChange={(date,datestring)=>setTodo_time(datestring)}  defaultValue={moment().add(1,'years')}/>
                    </div>
                </div>    
            </Modal>
        </div>
    )
}

export default LocalUserManage