import React, { useState, useContext, useEffect } from 'react'
import { StateContext } from '../../../state';
import { StateDeviceContext } from '../../../state_device';
import http from '../../../../../utils/server'
import { Row, Col, Button, Spin, Modal, Input, Space, Divider, Tabs, message, InputNumber,Tag,Checkbox} from 'antd'
import './device.less'
import { Mode } from '../../../../../App';
import md5 from 'js-md5';
const { TabPane } = Tabs;

function formatTime(time){
    if(time){
       var strs= time.split(':');
       if(strs.length<2) return time;   
       strs[0].length===1&&(strs[0]='0'+strs[0]);
       strs[1].length===1&&(strs[1]='0'+strs[1]);
       return strs[0]+':'+strs[1];
    }
}
function DeviceSet(props) {
    const { state, dispatch } = useContext(StateContext)
    const { state_device, dispatch_device } = useContext(StateDeviceContext)
    const [device, setDevice] = useState(props.item)
    const [devices, setDevices] = useState({type:20,data:[]});
    const [serverIP, setServerIP] = useState({ validity: false, error: false, type: 26 })//设备ip端口
    const [deviceTime, setDeviceTime] = useState({ validity: false, error: false, type: 27 })//设备时间
    const [batteryAlarm, setBatteryAlarm] = useState({ validity: false, error: false, type: 7 })//电池报警

    const [store, setStore] = useState({ validity: false, error: false, type: 24 })//存储间隔
    const [revise, setRevise] = useState({ validity: false, error: false, type: 28 })//补偿
    const [devClose, setDevClose] = useState({ validity: true, error: false, type: 25 })//关机
    const [alarmInfo, setAlarmInfo] = useState({ validity: true, error: false, type: 20})//探头上下限
    const [devParam1, setDevParam1] = useState({ validity: true, error: false, type: 22})//获取设备字段1  发货方
    const [devParam2, setDevParam2] = useState({ validity: true, error: false, type: 23})//获取设备字段2  收货方

    const [load, setLoad] = useState(false)
    const [offline,setOffLine] = useState({offtime:10,interval:5,count:2});
    const [showAffirm,setShowAffirm] = useState(false);//设置参数身份确认弹窗
    const [affirmPwd,setAffirmPwd] = useState(''); //设置参数确认密码
    const [setState, setSetState] = useState(0);//设置状态 0不能设置 1普通设置 2超级设置
    const [isSet,setIsSet] = useState(false);//是否有设置权限
    const [isMore,setIsMore] = useState(false);//是否多测点设置
    const [devList, setDevList] = useState([]);//选中的主机名

    const [isShowSet,setIsShowSet] = useState(true);
    const [isCache,setIsCache] = useState({a2:false,a3:false,a4:false,a6:false,a7:false,a8:false,a9:false,aa:false,});//命令下发状态
    const [selDevNodes,setSelDevNodes] = useState([]);
    useEffect(() => {
        setSetState(state.setState);
        let role = state.main.role;
        setIsSet(role.match('305')===null?false:true)
        GetStatus()
        let devs = 
        state.main.type<102?  state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&
        x.Type===209)
        : 
        state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&x.ExprieStatus!==3&&
        x.Type===209)
        let devNodes = [];
        devs.map(m=>{
            let temp = devNodes.findIndex(x=>x.sn===m.Sn)
            if(temp===-1){
                devNodes.push({sn:m.Sn,name:m.MName,nodes:[{value:m.ID,label:m.Name,key:m.Node,checked:false}],all:false,indeterminate:false})
            }else{
                devNodes[temp].nodes.push({value:m.ID,label:m.Name,key:m.Node,checked:false})
            }
        })
        if(devNodes.length>0){
            devNodes[0].all = true
            devNodes[0].nodes.map(m=>{
                m.checked = true
            })
        }
        devNodes.sort((a,b)=>a.sn-b.sn);
        setDevList(devNodes)
        if(devs.length>0){
            let devsTemp = devs.filter(x=>x.Sn===devs[0].Sn);
            let devsData =[{Sn:devs[0].Sn,Params:devs[0].Params,Sensor:[],StoreInfo:devs[0].StoreInfo,Type:devs[0].Type,ExSensor:[]}];
            devsTemp.map(m=>{
                m.Sensor.Name = m.Name
                devsData[0].Sensor.push(m.Sensor)
                devsData[0].ExSensor.push(m.ExSensor)
            })
            let devsInfo = devices;
            devsInfo.data = devsData;
            console.log('devsInfo',devsInfo);
            setDevices(devsInfo)
        }
        setSelDevNodes(devs.sort((a,b)=>a.Sn-b.Sn))
    }, [])

    const ReadDeviceParams = (t, call) => {
        setLoad(true)
        http.post((props.cloud ? "/v3" : "/v1") + "/getdevice", { ...t, device: device.Sn, node: device.Node }).then(data => {
            setLoad(false)
            if (data.code == 0) {
                data.data.Name = device.Name;
                data.data.MName = device.MName;
                setDevice(data.data);
                call({ ...t, validity: true });
            }
        })
    }
    const SetDeviceOffLineAlarm = (t) => {
        setLoad(true)
        http.put((props.cloud ? "/v3" : "/v1") + "/deviceoffline/" + device.ID, { alarm: t.alarm ,offtime:t.offtime,interval:t.interval,count:t.count}).then(data => {
            setLoad(false)
            if (data.code == 0) {
                device.Sensor.OffLineAlarm=t.alarm;
                device.Sensor.OffLineTime=t.offtime;
                device.Sensor.OffLineAlarmInterval=t.interval;
                device.Sensor.OffLineAlarmCount=t.count;
                setDevice({ ...device})
            }
        })
    }
    const SetDeviceLinkAlarm = (t) => {
        setLoad(true)
        http.put((props.cloud ? "/v3" : "/v1") + "/devicelink/" + device.ID, { alarm: t }).then(data => {
            setLoad(false)
            if (data.code == 0) {
                device.Sensor.LinkAlarm=t;
                setDevice({ ...device})
            }
        })
    }
    const SetDeviceParams = (t) => {
        if (t.error) {
            Modal.error({
                title: '参数错误',
                centered: true,
                content: '请检查所设置的参数',
            });
        } else {
            setLoad(true)
            if(isMore){
                let devInfo = device;
                let checkedList = []
                devList.map(m=>{
                    m.nodes.map(mm=>{
                        if(mm.checked){
                            checkedList.push({sn:m.sn,node:mm.key})
                        }
                    })
                })
                let countMax = checkedList.length;
                let index = 0;
                let timer= setInterval(()=>{
                    console.log('starttimer',timer);
                    if(index<countMax){
                        devInfo.Node = checkedList[index].node
                        devInfo.Sn = checkedList[index].sn
                        http.post((props.cloud ? "/v3" : "/v1") + "/setdevice", { ...t, device: devInfo }).then(data => {
                            setLoad(false)
                        })
                    }else{
                        clearInterval(timer)
                    }
                    index++;
                },1000)
            }else{
                setTimeout(() => {
                    http.post((props.cloud ? "/v3" : "/v1") + "/setdevice", { ...t, device: device }).then(data => {
                        setLoad(false)
                    })
                }, 1000);
            }
            // setTimeout(() => {
            //     http.post((props.cloud ? "/v3" : "/v1") + "/setdevice", { ...t, device: device }).then(data => {
            //         setLoad(false)
            //     })
            // }, 1000);
        }
    }
    const SetDevicesParams = ()=> {
        setLoad(true)
        setTimeout(() => {
            http.post((props.cloud ? "/v3" : "/v1") + "/setdevices", { devices: devices }).then(data => {
                setLoad(false)
            })
        }, 1000);
    }
    const GetStatus=()=> {
        let setCatch = {a2:false,a3:false,a4:false,a6:false,a7:false,a8:false,a9:false,aa:false}
        http.get("/v1/getdevicecmdbuffer/"+device.Sn, {}).then(data => {
            if (data.code === 0 &&data.data.length>0) {
                data.data.map(m=>{
                    let cmd = (m.Cmd%255).toString(16);
                    if(cmd==='a4'){
                        console.log('a4');
                        setCatch={...setCatch,a4:true}
                    }
                    else if(cmd==='a2'){
                        console.log('a2');
                        setCatch={...setCatch,a2:true}
                    }
                    else if(cmd==='a3'){
                        console.log('a3');
                        setCatch={...setCatch,a3:true}
                    }
                    else if(cmd==='a6'){
                        console.log('a6');
                        setCatch={...setCatch,a6:true}
                    }
                    else if(cmd==='a7'){
                        console.log('a7');
                        setCatch={...setCatch,a7:true}
                    }
                    else if(cmd==='a8'){
                        console.log('a8');
                        setCatch={...setCatch,a8:true}
                    }
                    else if(cmd==='a9'){
                        console.log('a9');
                        setCatch={...setCatch,a9:true}
                    }
                    else if(cmd==='aa'){
                        console.log('aa');
                        setCatch={...setCatch,aa:true}
                    }
                })
                setIsCache((pre)=>{console.log(pre,setCatch);return setCatch})
            }else{
                console.log(200);
                setIsCache(setCatch)
            }
        })
    }
    return (
        <div>
            <Tabs defaultActiveKey="1" onChange={e=>{
                setIsMore(e==='1'?false:true)
            }} tabBarExtraContent={<Button onClick={()=>setShowAffirm(true)} disabled={!isSet}>设置参数</Button>}>
                <TabPane tab="单测点设置" key="1">
                    <div style={{ display: 'flex',  justifyContent: 'space-between', alignItems: 'center', padding: '0px 20px' }}>
                        <span>{device.Name}</span>
                        <span>{' 主机名：' + device.MName}</span>
                        <span>{' 主机编号：' + device.Sn}</span> 
                        <span>{' 测点ID: ' + device.Node}</span>
                        <Button onClick={()=>{
                            let nodeLen = selDevNodes.length;
                            let index = selDevNodes.findIndex(x=>x.Sn===device.Sn&&x.Node===device.Node);
                            if(index!==-1){
                                if(index>0){
                                    setDevice(selDevNodes[index-1])
                                }else{
                                    setDevice(selDevNodes[nodeLen-1])
                                } 
                            }
                        }}>上个测点</Button>
                        <Button onClick={()=>{
                            let nodeLen = selDevNodes.length;
                            let index = selDevNodes.findIndex(x=>x.Sn===device.Sn&&x.Node===device.Node);
                            if(index!==-1){
                                if(index<nodeLen-1){
                                    setDevice(selDevNodes[index+1])
                                }else{
                                    setDevice(selDevNodes[0])
                                } 
                            }
                        }}>下个测点</Button>  
                    </div>  
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="报警设置" key="1">
                            <div style={{height:'600px'}}>
                                <Spin spinning={load}>
                                <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                    {/* 温湿度预警 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>预警参数：</div>
                                        {/* <Space> */}
                                        <Input.Group style={{ width: 420 }} compact>
                                            <Input defaultValue={device.Sensor.TempWarningHigh} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: 0 } })
                                                }
                                            }} />
                                            <Input defaultValue={device.Sensor.TempWarningLow} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: 0 } })
                                                }
                                            }} />

                                            <Input defaultValue={device.Sensor.WarningInterval} addonBefore={"报警间隔"} addonAfter={"分钟"} style={{ width: 200, margin: 5 }}
                                            onChange={e => {
                                                var reg = /^[0]|[1-9]\d{0,2}$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: parseInt(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: 2 } })
                                                }
                                            }} />
                                        </Input.Group>

                                        <div style={{ flex: 1 }}></div>
                                        <Space>
                
                                            {
                                                setState>0&&
                                                <Button onClick={()=>{
                                                    setLoad(true);
                                                    props.changeSetState(1);
                                                    http.put((props.cloud ? "/v3" : "/v1") + "/devicewarning/" + device.ID, { 
                                                        temp_waning_high: device.Sensor.TempWarningHigh, 
                                                        temp_waning_low:device.Sensor.TempWarningLow,
                                                        humi_waning_high:device.Sensor.HumiWarningHigh,
                                                        humi_waning_low:device.Sensor.HumiWarningLow,
                                                        warning_interval:device.Sensor.WarningInterval
                                                        }).then(data=>{
                                                            setLoad(false);
                                                            if(data.code===0){
                                                                setDevice({
                                                                    ...device,
                                                                    TempWarningHigh:device.Sensor.TempWarningHigh,
                                                                    TempWarningLow:device.Sensor.TempWarningLow,
                                                                    WarningInterval:device.Sensor.WarningInterval
                                                                })
                                                            }
                                                        })
                                                }} >设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    {/* 温湿度报警 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>报警参数：</div>
                                        {/* <Space> */}
                                        <Input.Group style={{ width: 420 }} compact>
                                            <Input value={device.ExSensor.param?device.ExSensor.param[0].high:''} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    alarmInfo.error = false
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor, param: [{...device.ExSensor.param[0],high:parseFloat(e.target.value)}]}})
                                                } else {
                                                    alarmInfo.error = true
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor, param: [{...device.ExSensor.param[0],high:e.target.value}]}})
                                                }
                                            }} />
                                            <Input value={device.ExSensor.param?device.ExSensor.param[0].low:''} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    alarmInfo.error = false
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor, param: [{...device.ExSensor.param[0],low:parseFloat(e.target.value)}]}})
                                                } else {
                                                    alarmInfo.error = true
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor,param: [{...device.ExSensor.param[0],low:e.target.value}]}})
                                                }
                                            }} />
                                            <Input value={device.ExSensor?device.ExSensor.alarm_count:''} addonBefore={"温度报警次数"} addonAfter={"次"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^[0-9]*[1-9][0-9]*$/
                                                if (reg.test(e.target.value)) {
                                                    let count =Number(e.target.value);
                                                    if(count>255){
                                                        count=255
                                                    }
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor,  alarm_count: count } })
                                                } else {
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor, alarm_count: (e.target.value) } })
                                                }
                                            }} />
                                            <div>
                                                
                                                {
                                                    isCache.a4&& <div>
                                                        <Button type='text' onClick={()=>{
                                                            GetStatus()
                                                        }}>状态:</Button>
                                                        <Tag color= "green">已缓存</Tag>
                                                    </div>
                                                }
                                            </div>
                                            
                                            
                                        </Input.Group>

                                        {/* </Space> */}
                                        <div style={{ flex: 1 }}></div>
                                        <Space>
                                            <Button onClick={() => {
                                                ReadDeviceParams(alarmInfo, setAlarmInfo);
                                            }}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    props.changeSetState(1);
                                                    SetDeviceParams(alarmInfo)
                                                    setTimeout(() => {
                                                        GetStatus();
                                                    }, 3000);
                                                }} disabled={Mode!=='soc'&&localStorage.getItem('type_') === '104'}>设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    <Divider style={{ margin: '5px 0' }} />
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>电量报警：</div>
                                        <Input.Group style={{ width: 420 }} compact>
                                            <Input value={device.Params.BatPowerAlarm} addonBefore={"主机电量"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^[0-9]*[1-9][0-9]*$/
                                                if (reg.test(e.target.value)) {
                                                    batteryAlarm.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, BatPowerAlarm: Number(e.target.value) } })
                                                } else {
                                                    batteryAlarm.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, BatPowerAlarm: (e.target.value) } })
                                                }
                                            }} />
                                            <Input value={device.Sensor.BatPowerAlarm} addonBefore={"探头电量"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^[0-9]*[1-9][0-9]*$/
                                                if (reg.test(e.target.value)) {
                                                    batteryAlarm.error = false
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, BatPowerAlarm: Number(e.target.value) } })
                                                } else {
                                                    batteryAlarm.error = true
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, BatPowerAlarm: (e.target.value) } })
                                                }
                                            }} />
                                        </Input.Group>
                                        <div style={{ flex: 1 }}></div>
                                        <Space>
                                            <Button onClick={() => {ReadDeviceParams(batteryAlarm, setBatteryAlarm);}}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {SetDeviceParams(batteryAlarm);props.changeSetState(1);}} disabled={Mode!=='soc'&&localStorage.getItem('type_') === '104'}>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                </Row>
                            </Spin>
                            </div>
                        </TabPane>
                        <TabPane tab="基本设置" key="2">
                            <div style={{height:'600px'}}>
                                <Spin spinning={load}>
                                <Row gutter={[10, 20]} style={{ position: 'relative' }}>
                                    {/* IP端口 */}
                                    <Col span={12} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input style={{width:260}} value={device.Params.IPAddress} addonBefore={"IP地址："} placeholder='xxx.xxx.xxx.xxx:5566' onChange={e => {
                                                setDevice({ ...device, Params: { ...device.Params, IPAddress: e.target.value } })
                                            }} />
                                            {/* <Button onClick={() => {
                                                    ReadDeviceParams(serverIP, setServerIP);
                                                }}>读取</Button> */}
                                            {
                                                setState>0&&
                                                <Button onClick={() =>{ SetDeviceParams(serverIP);props.changeSetState(1);}} disabled={Mode!=='soc'&&localStorage.getItem('type_') === '104'}>设置</Button>
                                            }
                                            <div>
                                                
                                                {
                                                    isCache.a9&&
                                                    <div>
                                                        <Button type='text' onClick={()=>{
                                                            GetStatus()
                                                        }}>状态:</Button>
                                                        <Tag color= "green">已缓存</Tag>
                                                    </div>
                                                }
                                            </div>
                                        </Space>
                                    </Col>
                                    {/* 补偿 */}
                                    {
                                    setState>1&&
                                        <Col span={12} style={{ display: 'flex' }}>
                                        <Space>
                                            <div>补偿：</div><div style={{color: '#1890ff'}}>温度：</div>
                                            <InputNumber style={{width:80,margin:0}}  value = {device.ExSensor.param?device.ExSensor.param[0].compensate:''} placeholder='0' onChange={e => {
                                                let value = e;
                                                setDevice({ ...device, ExSensor: { ...device.ExSensor, param: [{...device.ExSensor.param[0],compensate:parseFloat(value)}]}})
                                            }} />
                                            <div style={{color: '#1890ff'}}>湿度：</div>
                                            <InputNumber style={{width:80}} value={device.Sensor.HumiCompensate}  placeholder='0' onChange={e => {

                                                setDevice({ ...device, Sensor: { ...device.Sensor, HumiCompensate:e } })
                                            }}  />
                                            <Button onClick={() => {ReadDeviceParams(revise, setRevise);}}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() =>{ SetDeviceParams(revise); setTimeout(() => {
                                                    GetStatus();
                                                    props.changeSetState(1);
                                                }, 3000);}}  >设置</Button>
                                            }
                                            <div>
                                                {
                                                    isCache.aa&&
                                                    <div>
                                                        <Button type='text' onClick={()=>{
                                                            GetStatus()
                                                        }}>状态:</Button>
                                                        <Tag color= "green">已缓存</Tag>
                                                    </div>
                                                }
                                            </div>
                                        </Space>
                                        </Col>
                                    }
                                    {/* 设备时间 */}
                                    <Col span={24} style={{ display: 'flex'}}>
                                        <Space>
                                            <Input value={device.Params.DeviceTime} addonBefore={"设备时间："} disabled />
                                            <Button onClick={() => {ReadDeviceParams(deviceTime, setDeviceTime);}}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() =>{SetDeviceParams(deviceTime);GetStatus();props.changeSetState(1);} } disabled={Mode!=='soc'&&localStorage.getItem('type_') === '104'}>设置</Button>
                                            }
                                            <div>
                                                {
                                                    isCache.a3&&
                                                    <div>
                                                        <Button type='text' onClick={()=>{
                                                            GetStatus()
                                                        }}>状态:</Button>
                                                        <Tag color= "green">已缓存</Tag>
                                                    </div>
                                                }
                                            </div>
                                        </Space>
                                    </Col>
                                    
                                    {/* 关机 */}
                                    <Col span={24} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input addonBefore={"设备操作："} disabled value={"请谨慎操作"} />
                                            <Button onClick={()=>{
                                                // setDevClose({...device, Params: {...device.Params}});
                                                SetDeviceParams(devClose);
                                                setTimeout(() => {
                                                    GetStatus();
                                                }, 3000);}} disabled={Mode!=='soc'&& localStorage.getItem('type_') === '104'}>关机</Button>
                                                <div>
                                                {
                                                    isCache.a8&&
                                                    <div>
                                                        <Button type='text' onClick={()=>{
                                                            GetStatus()
                                                        }}>状态:</Button>
                                                        <Tag color= "green">已缓存</Tag>
                                                    </div>
                                                }
                                            </div>
                                            {/* <Button>重启</Button> */}
                                        </Space>
                                    </Col>
                                    {/* 发货方 */}
                                    <Col span={24} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input addonBefore={"发货方："} value ={ device.ExSensor.msg?device.ExSensor.msg[0]:''} onChange={e => {
                                                setDevice({ ...device, ExSensor: { ...device.ExSensor, msg:[e.target.value,device.ExSensor.msg[1]] } })
                                            }} />
                                            <Button onClick={()=>{
                                                ReadDeviceParams(devParam1,setDevParam1);
                                                }} disabled={Mode!=='soc'&& localStorage.getItem('type_') === '104'}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    SetDeviceParams(devParam1);
                                                    setTimeout(() => {
                                                        GetStatus();
                                                        props.changeSetState(1);
                                                    }, 3000);
                                                }} disabled={Mode!=='soc'&&localStorage.getItem('type_') === '104'}>设置</Button>
                                            }
                                            <div>
                                                {
                                                    isCache.a6&&
                                                    <div>
                                                        <Button type='text' onClick={()=>{
                                                            GetStatus()
                                                        }}>状态:</Button>
                                                        <Tag color= "green">已缓存</Tag>
                                                    </div>
                                                }
                                            </div>
                                        </Space>
                                    </Col>
                                    {/* 收货方 */}
                                    <Col span={24} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input addonBefore={"收货方："} value ={ device.ExSensor.msg?device.ExSensor.msg[1]:''} onChange={e => {
                                                setDevice({ ...device, ExSensor: { ...device.ExSensor, msg:[device.ExSensor.msg[0],e.target.value] } })
                                            }} />
                                            <Button onClick={()=>{
                                                ReadDeviceParams(devParam2,setDevParam2);
                                                }} disabled={Mode!=='soc'&& localStorage.getItem('type_') === '104'}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    SetDeviceParams(devParam2);setTimeout(() => {
                                                        GetStatus();
                                                        props.changeSetState(1);
                                                    }, 3000);
                                                }} disabled={Mode!=='soc'&&localStorage.getItem('type_') === '104'}>设置</Button>
                                            }
                                            <div>
                                                {
                                                    isCache.a7&&
                                                    <div>
                                                        <Button type='text' onClick={()=>{
                                                            GetStatus()
                                                        }}>状态:</Button>
                                                        <Tag color= "green">已缓存</Tag>
                                                    </div>
                                                }
                                            </div>
                                        </Space>
                                    </Col>
                                    {/* 设置存储间隔 */}
                                    <Col span={24} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input value={device.Params.NorStoreInt} addonBefore={"正常存储间隔："} addonAfter={"分钟"} onChange={e => {
                                                var reg = /^[0-9]*[1-9][0-9]*$/
                                                if (reg.test(e.target.value)) {
                                                    store.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, NorStoreInt: Number(e.target.value) } })
                                                } else {
                                                    store.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, NorStoreInt: (e.target.value) } })
                                                }
                                            }} />
                                            <Input value={device.Params.AlmStoreInt} addonBefore={"报警存储间隔："} addonAfter={"分钟"} onChange={e => {

                                                var reg = /^[0-9]*[1-9][0-9]*$/
                                                if (reg.test(e.target.value)) {
                                                    store.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: Number(e.target.value) } })
                                                } else {
                                                    store.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: (e.target.value) } })
                                                }
                                            }}/>
                                            <Button onClick={() => {ReadDeviceParams(store, setStore);}}>读取</Button>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {SetDeviceParams(store);
                                                    setTimeout(() => {
                                                        GetStatus();
                                                        props.changeSetState(1);
                                                    }, 3000);}} disabled={Mode!=='soc'&&localStorage.getItem('type_') === '104'}>设置</Button>
                                            }
                                            <div>
                                                {
                                                    isCache.a2&&
                                                    <div>
                                                        <Button type='text' onClick={()=>{
                                                            GetStatus()
                                                        }}>状态:</Button>
                                                        <Tag color= "green">已缓存</Tag>
                                                    </div>
                                                }
                                            </div>
                                        </Space>
                                    </Col>
                                    {/*探头链接失败  */}
                                    <Col span={12} >
                                        
                                        <div>探头连接失败报警：<Button onClick={() => {
                                            SetDeviceLinkAlarm(device.Sensor.LinkAlarm === 0 ? 1 : 0);
                                        }}>{device.Sensor.LinkAlarm === 0 ? "已关闭" : "已打开"}</Button></div>
                                    </Col>
                                    {/* 离线报警 */}
                                    <Col span={20} style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                        <div>离线报警：</div>
                                        <Input defaultValue={device.Sensor.OffLineTime}  addonBefore={"离线间隔："} addonAfter={'分'} style={{width:200}} autoComplete='off'
                                        onChange={e=>{
                                            setOffLine({...offline,offtime:e.target.value*1})
                                        }}/>
                                        <Input defaultValue={device.Sensor.OffLineAlarmInterval}  addonBefore={"报警间隔："} addonAfter={'分'} style={{width:200}} autoComplete='off'
                                        onChange={e=>{
                                            setOffLine({...offline,interval:e.target.value*1})
                                        }}/>
                                        <Input defaultValue={device.Sensor.OffLineAlarmCount}  addonBefore={"报警次数："} style={{width:160}} autoComplete='off'
                                        onChange={e=>{
                                            setOffLine({...offline,count:e.target.value*1})
                                        }}/>
                                        <Button onClick={() => {
                                            SetDeviceOffLineAlarm({...offline,alarm:device.Sensor.OffLineAlarm === 0 ?1:0});
                                        }}>{device.Sensor.OffLineAlarm === 0 ? "已关闭" : "已打开"}</Button>
                                        
                                    </Col>
                                    {/* <Col span={24} style={{ display: 'flex',alignItems:'center' }}>
                                        <Button onClick={()=>{
                                        }}>空库设置</Button>
                                    </Col> */}
                                </Row>
                            </Spin>
                            </div>
                        </TabPane>
                    </Tabs>
                </TabPane>
                {
                    state.type===100&&
                    <TabPane tab="多测点设置" key="2">
                        <div style={{ padding: '0px 20px',overflow:'scroll' ,maxHeight:300,}}> 
                            <div>
                                <Checkbox  
                                onChange={e=>{
                                    let devInfo= devices;
                                    if(e.target.checked){
                                        devList.map(m=>{
                                            m.all = true;
                                            m.nodes.map(mn=>{
                                                mn.checked = true
                                            })
                                        })
                                        let devsTemp = 
                                        state.main.type<102?  state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&
                                        (x.Type===209))
                                        : 
                                        state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&x.ExprieStatus!==3&&
                                        (x.Type===209));
                                        let devsData=[]
                                        devsTemp.map(m=>{
                                            let temp =devsData.findIndex(x=>x.Sn===m.Sn);
                                            m.Sensor.Name = m.Name
                                            if(temp===-1){
                                                devsData.push({Sn:m.Sn,Params:m.Params,Sensor:[m.Sensor],StoreInfo:m.StoreInfo,Type:m.Type,ExSensor:[m.ExSensor]})
                                            }else{
                                                devsData[temp].Sensor.push(m.Sensor)
                                                devsData[temp].ExSensor.push(m.ExSensor)
                                            }
                                        })
                                        devsData.sort((a,b)=>a.Sn-b.Sn)
                                        devInfo.data=devsData;
                                    }else{
                                        devList.map(m=>{
                                            m.all = false;
                                            m.nodes.map(mn=>{
                                                mn.checked = false
                                            })
                                        })
                                        devInfo.data =[]
                                    }
                                    let devs = devList.concat();
                                    setDevList(devs)
                                    setDevices(devInfo)
                                }}>
                                    全选
                                </Checkbox>
                            </div>
                            {
                                devList.map(m=>{
                                    return(
                                    <div style={{display:'flex',flexDirection:'row'}} >
                                        <div style={{whiteSpace:'nowrap',paddingBottom:20}}>
                                            <span style={{ marginLeft: '10px',color:'#1890ff' }}>主机名:</span> 
                                            <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{m.name}</span>
                                        </div>
                                        <div style={{whiteSpace:'nowrap',paddingBottom:20}}>
                                            <span style={{ marginLeft: '10px' ,color:'#1890ff'}}>主机编号:</span> 
                                            <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{m.sn}</span>
                                        </div>
                                        
                                        <div style={{whiteSpace:'nowrap',paddingBottom:20}}>
                                            <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10,color:'#1890ff' }}>测点</span>
                                            <Checkbox indeterminate={m.indeterminate} 
                                            onChange={e=>{
                                                let devInfo= devices;
                                                let tempdevInfo = state.main.type<102?  state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&
                                                    (x.Type===209))
                                                    : 
                                                    state_device.devicestate.filter(x=>x.Node!==0&&x.District===props.item.District&&x.ExprieStatus!==3&&
                                                    (x.Type===209));
                                                m.indeterminate = false
                                                let devsData=[]
                                                if(e.target.checked){
                                                    m.nodes.map(n=>{
                                                        n.checked = true;
                                                    })
                                                    m.all = true;
                                                    tempdevInfo.filter(x=>x.Sn===m.sn).map(n=>{
                                                        let temp = devInfo.data.findIndex(x=>x.Sn===m.sn);
                                                        n.Sensor.Name = n.Name
                                                        if(temp===-1){
                                                            devsData.push({Sn:m.Sn,Params:m.Params,Sensor:[m.Sensor],StoreInfo:m.StoreInfo,Type:m.Type,ExSensor:[m.ExSensor]})
                                                        }else{
                                                            devsData[temp].Sensor.push(m.Sensor)
                                                            devsData[temp].ExSensor.push(m.ExSensor)
                                                        }
                                                    })
                                                    devInfo.data.sort((a,b)=>a.Sn-b.Sn)
                                                }else{
                                                    m.all = false;
                                                    m.nodes.map(n=>{
                                                        n.checked = false
                                                    })
                                                    devInfo.data = devInfo.data.filter(x=>x.Sn!==m.sn)
                                                }
                                                let templist = devList.concat()
                                                setDevList(templist)
                                                setDevices(devInfo)
                                            }} checked={m.all}>
                                                全选
                                            </Checkbox>
                                        </div>
                                        <div style={{whiteSpace:'nowrap',paddingBottom:20}}>
                                        {
                                            m.nodes.map(mm=>{
                                                return(
                                                    <Checkbox value={mm.value} checked={mm.checked}
                                                    onChange={e=>{
                                                        let devInfo= devices;
                                                        let temp = state_device.devicestate.filter(x=>x.ID === mm.value);
                                                        let n = temp[0];
                                                        let index = devInfo.data.findIndex(x=>x.Sn===n.Sn)
                                                        if(e.target.checked){
                                                            mm.checked = true;
                                                            n.Sensor.Name = n.Name
                                                            if(index===-1){
                                                                devInfo.data.push({Sn:n.Sn,Params:n.Params,Sensor:[n.Sensor],StoreInfo:n.StoreInfo,Type:n.Type,ExSensor:[n.ExSensor]})
                                                                devInfo.data.sort((a,b)=>a.Sn-b.Sn)
                                                            }else{
                                                                devInfo.data[index].Sensor.push(n.Sensor);
                                                                devInfo.data[index].ExSensor.push(n.ExSensor);
                                                                devInfo.data[index].Sensor.sort((a,b)=>a.Id-b.Id)
                                                            }
                                                            
                                                        }else{
                                                            mm.checked = false
                                                            let sensonTemp =devInfo.data[index].Sensor;
                                                            sensonTemp= sensonTemp.filter(x=>x.Id!==n.Node);
                                                            if(sensonTemp.length>0){
                                                                devInfo.data[index].Sensor = sensonTemp;
                                                            }else{
                                                                devInfo.data=devInfo.data.filter(x=>x.Sn!==n.Sn)
                                                            }
                                                            let exSensonTemp =devInfo.data[index].ExSensor;
                                                            exSensonTemp= exSensonTemp.filter(x=>x.Id!==n.Node);
                                                            if(exSensonTemp.length>0){
                                                                devInfo.data[index].ExSensor = exSensonTemp;
                                                            }else{
                                                                devInfo.data=devInfo.data.filter(x=>x.Sn!==n.Sn)
                                                            }
                                                        }
                                                        setDevices(devInfo)
                                                        let ttt=devList.filter(x=>x.sn===m.sn)[0].nodes
                                                        // console.log(ttt);
                                                        let checkNodelen= ttt.filter(x=>x.checked).length
                                                        if(checkNodelen===0||checkNodelen===ttt.length){
                                                            m.indeterminate = false
                                                        }else{
                                                            m.indeterminate = true
                                                        }
                                                        if(checkNodelen===ttt.length){
                                                            m.all = true
                                                        }else{
                                                            m.all = false
                                                        }
                                                        let templist=devList.concat()

                                                        setDevList(templist)
                                                    }}
                                                    >{mm.label}</Checkbox>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>)
                                })
                            }
                            
                        </div>
                        <Tabs defaultActiveKey="1" tabBarExtraContent={
                            isShowSet&&
                            <Button 
                            disabled={!isShowSet}
                            onClick={()=>{
                                SetDevicesParams()
                                console.log('devices',devices);
                            }}>设置</Button>}
                            onChange={key=>{
                                setIsShowSet(true);
                                if(key==='1'){//上下限
                                    devices.type = 5;
                                }
                                if(key==='3'){//预警
                                    devices.type = 8;
                                }
                                if(key==='6'){//补偿
                                    devices.type = 10;
                                }
                                if(key==='7'){//回传间隔
                                    devices.type = 3;
                                }
                                if(key==='8'){//存储间隔
                                    devices.type = 9;
                                }
                                if(key==='9'){//基本设置
                                    setIsShowSet(false)
                                }
                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                setDevices(devsTemp)
                            }}
                        >
                        <TabPane tab="报警" key="1">
                            <div style={{height:'400px',overflow:'scroll'}}>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>(
                                                m.Sensor.map(n=>{
                                                    return(
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} key={m.Sn+''+n.Id}>
                                                            <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{n.Name}</div>
                                                            <div style={{marginLeft:5,width:30}}>主机</div>
                                                            <div style={{marginLeft:5}}>{m.Sn}</div>
                                                            <div style={{marginLeft:5,width:30}}>测点</div>
                                                            <div style={{marginLeft:5}}>{n.Id}</div>
                                                            <Input.Group style={{ width: 420 }} compact>
                                                                <Input value={n.ExSensor.param?n.ExSensor.param[0].high:''} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.ExSensor.param[0].high = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    } 
                                                                }} />
                                                                <Input value={n.ExSensor.param?n.ExSensor.param[0].low:''} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.ExSensor.param[0].low = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }} />
                                                                <Input value={n.ExSensor?n.ExSensor.alarm_count:''} addonBefore={"温度报警次数"} addonAfter={"次"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                                    var reg = /^[0-9]*[1-9][0-9]*$/
                                                                    if (reg.test(e.target.value)) {
                                                                        let count =Number(e.target.value);
                                                                        if(count>255){
                                                                            count=255
                                                                        }
                                                                        n.ExSensor.alarm_count = count
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    } 
                                                                }} />
                                                                <div>
                                                                    
                                                                    {
                                                                        isCache.a4&& <div>
                                                                            <Button type='text' onClick={()=>{
                                                                                GetStatus()
                                                                            }}>状态:</Button>
                                                                            <Tag color= "green">已缓存</Tag>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                
                                                                
                                                            </Input.Group>

                                                        </Col>
                                                    )
                                                })
                                                
                                            ))
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane>

                        {/* <TabPane tab="预警" key="3">
                            <div style={{height:'400px',overflow:'scroll'}}>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>(
                                                m.Sensor.map(n=>{
                                                    return(
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} key={m.Sn+''+n.Id}>
                                                            <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{n.Name}</div>
                                                            <div style={{marginLeft:5,width:40}}>主机</div>
                                                            <div style={{marginLeft:5}}>{m.Sn}</div>
                                                            <div style={{marginLeft:5,width:40}}>测点</div>
                                                            <div style={{marginLeft:5}}>{n.Id}</div>
                                                            <Input.Group  compact>
                                                                <Input defaultValue={n.TempWarningHigh} addonBefore={"温度上限"} style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.TempWarningHigh = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }} />
                                                                <Input defaultValue={n.TempWarningLow} addonBefore={"温度下限"} style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.TempWarningLow = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }} />
                                                                <Input defaultValue={n.HumiWarningHigh} addonBefore={"湿度上限"}  style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.HumiWarningHigh = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }}  disabled={n.HumiEnable!==0||!n.HumiSwitch}/>
                                                                <Input defaultValue={n.HumiWarningLow} addonBefore={"湿度下限"}  style={{ width: 150, margin: 5 }} onChange={e => {
                                                                    var reg = /^(-?\d+)(\.\d+)?$/
                                                                    if (reg.test(e.target.value)) {
                                                                        n.HumiWarningLow = parseFloat(e.target.value)
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    } 
                                                                }} disabled={n.HumiEnable!==0||!n.HumiSwitch}/>
                                                                <Input defaultValue={n.WarningInterval} addonBefore={"报警间隔"}  style={{ width: 150, margin: 5 }} maxLength={3}
                                                                onChange={e => {
                                                                    var reg = intReg
                                                                    let val = e.target.value===''?1:e.target.value;
                                                                    if (reg.test(val)) {
                                                                        n.TempWarningHigh =val*1;
                                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                        setDevices(devsTemp)
                                                                    }
                                                                }} />
                                                            </Input.Group>
                                                        </Col>
                                                    )
                                                })
                                                
                                            ))
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane> */}


                        {
                            setState>1 &&
                            <TabPane tab="补偿" key="6">
                            <div style={{height:'400px',overflow:'scroll'}}>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>(
                                                m.Sensor.map(n=>{
                                                    return(
                                                        <Col span={24} style={{ display: 'flex', alignItems: 'center' }} key={m.Sn+''+n.Id}>
                                                            <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{n.Name}</div>
                                                            <div style={{marginLeft:5,width:30}}>主机</div>
                                                            <div style={{marginLeft:5}}>{m.Sn}</div>
                                                            <div style={{marginLeft:5,width:30}}>测点</div>
                                                            <div style={{marginLeft:5}}>{n.Id}</div>
                                                            <Space>
                                                                <div>补偿：</div><div style={{color: '#1890ff'}}>温度：</div>
                                                                <InputNumber style={{width:80,margin:0}}  value = {n.TempCompensate} placeholder='0' onChange={e => {
                                                                    n.TempCompensate = e;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                }} />
                                                                <div style={{color: '#1890ff'}}>湿度：</div>
                                                                <InputNumber style={{width:80}} value={n.HumiCompensate}  placeholder='0' onChange={e => {
                                                                    n.HumiCompensate = e;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                }}  />
                                                            </Space>
                                                        </Col>
                                                    )
                                                })
                                                
                                            ))
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                            </TabPane>
                        }
                        <TabPane tab="回传间隔" key="7">
                            <div style={{height:'400px',overflow:'scroll'}}>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>{
                                                return(
                                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }} key={m.Sn}>
                                                        {/* <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{n.Name}</div> */}
                                                        <div style={{marginLeft:5,width:30}}>主机</div>
                                                        <div style={{marginLeft:5}}>{m.Sn}</div>
                                                        {/* <div style={{marginLeft:5,width:30}}>测点</div> */}
                                                        {/* <div style={{marginLeft:5}}>{n.Id}</div> */}
                                                        <Input style={{width:260}} value={m.Params.RealTimeInt} addonBefore={"回传间隔："} addonAfter={"秒"} onChange={e => {
                                                            var reg =  /^[\d]{0,4}$/
                                                            let val = e.target.value===''?0:e.target.value;
                                                            if (reg.test(val)) {
                                                                m.Params.RealTimeInt = val*1;
                                                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                setDevices(devsTemp)
                                                                // setDevice({ ...device, Params: { ...device.Params, RealTimeInt: Number(val) } })
                                                            } 
                                                        }} />
                                                    </Col>
                                                )                                                
                                            })
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane>
                        <TabPane tab="存储间隔" key="8">
                            <div style={{height:'400px',overflow:'scroll'}}>
                                <Spin spinning={load}>
                                    <Row gutter={[10, 10]} style={{ position: 'relative' }}>
                                        {
                                            devices.data.map(m=>{
                                                return(
                                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }} key={m.Sn}>
                                                        {/* <div style={{width:150,overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'}}>{n.Name}</div> */}
                                                        <div style={{marginLeft:5,width:30}}>主机</div>
                                                        <div style={{marginLeft:5}}>{m.Sn}</div>
                                                        {/* <div style={{marginLeft:5,width:30}}>测点</div> */}
                                                        {/* <div style={{marginLeft:5}}>{n.Id}</div> */}
                                                        <Space>
                                                            <Input value={m.Params.NorStoreInt} addonBefore={"正常存储间隔："} addonAfter={"分钟"} onChange={e => {
                                                                let reg =  /^[0-9]*[1-9][0-9]*$/;
                                                                let val = e.target.value===''?0:e.target.value;
                                                                if (reg.test(val)) {
                                                                    m.Params.NorStoreInt = val*1;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                    // setDevice({ ...device, Params: { ...device.Params, NorStoreInt: Number(val) } })
                                                                }
                                                            }} />
                                                            <Input value={m.Params.AlmStoreInt} addonBefore={"报警存储间隔："} addonAfter={"分钟"} onChange={e => {
                                                                let reg = /^[0-9]*[1-9][0-9]*$/;
                                                                let val = e.target.value===''?0:e.target.value;
                                                                if (reg.test(val)) {
                                                                    m.Params.AlmStoreInt = val*1;
                                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                                    setDevices(devsTemp)
                                                                    // setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: Number(val) } })
                                                                } 
                                                            }}/>
                                                        </Space>
                                                    </Col>
                                                )                                                
                                            })
                                        }
                                        
                                    </Row>
                                </Spin>
                            </div>
                        </TabPane>
                        <TabPane tab="基本设置" key="9">
                            <div style={{height:'400px',overflow:'scroll'}}>
                                <Spin spinning={load}>
                                <Row gutter={[10, 20]} style={{ position: 'relative' }}>
                                    {/* 温湿度预警 */}
                                    {/* <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{width:50}}>预警：</div>
                                        <Input.Group>
                                            <Input defaultValue={device.Sensor.TempWarningHigh} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningHigh: 0 } })
                                                }
                                            }} />
                                            <Input defaultValue={device.Sensor.TempWarningLow} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempWarningLow: 0 } })
                                                }
                                            }} />
                                            <Input defaultValue={device.Sensor.HumiWarningHigh} addonBefore={"湿度上限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningHigh: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningHigh: 0 } })
                                                }
                                            }}  disabled={device.Humi===0}/>
                                            <Input defaultValue={device.Sensor.HumiWarningLow} addonBefore={"湿度下限"} addonAfter={"%RH"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningLow: parseFloat(e.target.value) } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiWarningLow: 0 } })
                                                }
                                            }} disabled={device.Humi===0}/>
                                            <Input defaultValue={device.Sensor.WarningInterval} addonBefore={"报警间隔"} addonAfter={"分钟"} style={{ width: 200, margin: 5 }} maxLength={3}
                                            onChange={e => {
                                                var reg = intReg
                                                let val = e.target.value===''?1:e.target.value;
                                                if (reg.test(val)) {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: val*1 } })
                                                } else {
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, WarningInterval: 2 } })
                                                }
                                            }} />
    
                                        </Input.Group>
                                        <Space>

                                            {
                                                setState>0&&
                                                <Button onClick={()=>{
                                                    setLoad(true);
                                                    http.put((props.cloud ? "/v3" : "/v1") + "/devicewarning/" + device.ID, { 
                                                        temp_waning_high: device.Sensor.TempWarningHigh, 
                                                        temp_waning_low:device.Sensor.TempWarningLow,
                                                        humi_waning_high:device.Sensor.HumiWarningHigh,
                                                        humi_waning_low:device.Sensor.HumiWarningLow,
                                                        warning_interval:device.Sensor.WarningInterval
                                                        }).then(data=>{
                                                            setLoad(false);
                                                            if(data.code===0){
                                                                setDevice({
                                                                    ...device,
                                                                    TempWarningHigh:device.Sensor.TempWarningHigh,
                                                                    TempWarningLow:device.Sensor.TempWarningLow,
                                                                    HumiWarningHigh:device.Sensor.HumiWarningHigh,
                                                                    HumiWarningLow:device.Sensor.HumiWarningLow,
                                                                    WarningInterval:device.Sensor.WarningInterval
                                                                })
                                                            }
                                                        })
                                                }} >设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col> */}
                                    <Divider style={{ margin: '1px 0' }} />
                                    {/* 温湿度报警 */}
                                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>报警：</div>
                                        <Input.Group style={{ width: 420 }} compact>
                                            <Input value={device.ExSensor.param?device.ExSensor.param[0].high:''} addonBefore={"温度上限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    alarmInfo.error = false
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor, param: [{...device.ExSensor.param[0],high:parseFloat(e.target.value)}]}})
                                                } else {
                                                    alarmInfo.error = true
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor, param: [{...device.ExSensor.param[0],high:e.target.value}]}})
                                                }
                                            }} />
                                            <Input value={device.ExSensor.param?device.ExSensor.param[0].low:''} addonBefore={"温度下限"} addonAfter={"℃"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^(-?\d+)(\.\d+)?$/
                                                if (reg.test(e.target.value)) {
                                                    alarmInfo.error = false
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor, param: [{...device.ExSensor.param[0],low:parseFloat(e.target.value)}]}})
                                                } else {
                                                    alarmInfo.error = true
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor,param: [{...device.ExSensor.param[0],low:e.target.value}]}})
                                                }
                                            }} />
                                            <Input value={device.ExSensor?device.ExSensor.alarm_count:''} addonBefore={"温度报警次数"} addonAfter={"次"} style={{ width: 200, margin: 5 }} onChange={e => {
                                                var reg = /^[0-9]*[1-9][0-9]*$/
                                                if (reg.test(e.target.value)) {
                                                    let count =Number(e.target.value);
                                                    if(count>255){
                                                        count=255
                                                    }
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor,  alarm_count: count } })
                                                } else {
                                                    setDevice({ ...device, ExSensor: { ...device.ExSensor, alarm_count: (e.target.value) } })
                                                }
                                            }} />
                                            <div>
                                                
                                                {
                                                    isCache.a4&& <div>
                                                        <Button type='text' onClick={()=>{
                                                            GetStatus()
                                                        }}>状态:</Button>
                                                        <Tag color= "green">已缓存</Tag>
                                                    </div>
                                                }
                                            </div>
                                            
                                            
                                        </Input.Group>
                                        <Space>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    devices.type = 5;
                                                    devices.data.map(m=>{
                                                        m.Sensor.map(n=>{
                                                            n.TempAlarmHigh = device.Sensor.TempAlarmHigh
                                                            n.TempAlarmLow = device.Sensor.TempAlarmLow
                                                            n.HumiAlarmHigh = device.Sensor.HumiAlarmHigh
                                                            n.HumiAlarmLow = device.Sensor.HumiAlarmLow
                                                        })
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    SetDevicesParams()
                                                }} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    <Divider style={{ margin: '1px 0' }} />

                                    <Divider style={{ margin: '1px 0' }} />

                                    <Col span={8} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input style={{width:260}} value={device.Params.IPAddress} addonBefore={"IP地址："} placeholder='xxx.xxx.xxx.xxx:5566' onChange={e => {
                                                setDevice({ ...device, Params: { ...device.Params, IPAddress: e.target.value } })
                                            }} />

                                            {
                                                setState>0&&state.main.type<103&&
                                                <Button onClick={() =>{ 
                                                    // SetDeviceParams(serverIP);
                                                    devices.type = 1;
                                                    devices.data.map(m=>{
                                                        m.Params.IPAddress = device.Params.IPAddress
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    SetDevicesParams()
                                                }} 
                                                disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                            
                                        </Space>
                                    </Col>
                                    <Col span={8} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input style={{width:260}} value={device.Params.RealTimeInt} addonBefore={"回传间隔："} addonAfter={"秒"} onChange={e => {
                                                var reg =  /^[\d]{0,4}$/
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    setDevice({ ...device, Params: { ...device.Params, RealTimeInt: Number(val) } })
                                                } else {
                                                    setDevice({ ...device, Params: { ...device.Params, RealTimeInt: val } })
                                                }
                                            }} />
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    devices.type = 3;
                                                    devices.data.map(m=>{
                                                        m.Params.RealTimeInt = device.Params.RealTimeInt
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    SetDevicesParams()
                                                }} 
                                                disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    
                                    
                                    <Col span={8} style={{ display: 'flex' }}>
                                        {
                                            setState>1?
                                            <Space>
                                                <div>补偿：</div><div style={{color: '#1890ff'}}>温度：</div>
                                                <InputNumber style={{width:80,margin:0}}  value = {device.Sensor.TempCompensate} placeholder='0' onChange={e => {
                                                    let value = e;
                                                    setDevice({ ...device, Sensor: { ...device.Sensor, TempCompensate: value } })
                                                }} />
                                                <div style={{color: '#1890ff'}}>湿度：</div>
                                                <InputNumber style={{width:80}} value={device.Sensor.HumiCompensate}  placeholder='0' onChange={e => {

                                                    setDevice({ ...device, Sensor: { ...device.Sensor, HumiCompensate:e } })
                                                }}  />
                                                {
                                                    setState>0&&
                                                    <Button onClick={() =>{ 
                                                        devices.type = 10;
                                                        devices.data.map(m=>{
                                                            m.Sensor.map(n=>{
                                                                n.TempCompensate = device.Sensor.TempCompensate
                                                                n.HumiCompensate = device.Sensor.HumiCompensate
                                                            })
                                                        })
                                                        let devsTemp = JSON.parse(JSON.stringify(devices));
                                                        setDevices(devsTemp)
                                                        SetDevicesParams()
                                                    }}  >设置</Button>
                                                }
                                            </Space>
                                        : <div></div>
                                        }
                                    </Col>
                                    
                                    
                                    <Col span={8} style={{ display: 'flex'}}>
                                        <Space>
                                            <Input value={device.Params.DeviceTime} addonBefore={"设备时间："} disabled />
                                            {
                                                setState>0&&
                                                <Button onClick={() =>{
                                                    devices.type = 2;
                                                    devices.data.map(m=>{
                                                        m.Params.DeviceTime = device.Params.DeviceTime
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                    SetDevicesParams()
                                                } } disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col>
                                    

                                    <Col span={8} style={{ display: 'flex' }}>
                                        <Space>
                                            <Input addonBefore={"设备操作："} disabled value={"请谨慎操作"} />
                                            <Button onClick={()=>{
                                                // setDevClose({...device, Params: {...device.Params}});
                                                devices.type = 11;
                                                devices.data.map(m=>{
                                                    m.Params= device.Params
                                                })
                                                let devsTemp = JSON.parse(JSON.stringify(devices));
                                                setDevices(devsTemp)
                                                SetDevicesParams()
                                            }} disabled={Mode!=='soc'&& state.main.type === 104}>关机</Button>
                                            {/* <Button>重启</Button> */}
                                        </Space>
                                    </Col>
                        
                                    <Col span={12} style={{ display: 'flex',paddingRight:50}}>
                                        <Space>
                                            <Input value={device.Params.NorStoreInt} addonBefore={"正常存储间隔："} addonAfter={"分钟"} onChange={e => {
                                                var reg =  /^[0-9]*[1-9][0-9]*$/
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    store.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, NorStoreInt: Number(val) } })
                                                } else {
                                                    store.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, NorStoreInt: val } })
                                                }
                                            }} />
                                            <Input value={device.Params.AlmStoreInt} addonBefore={"报警存储间隔："} addonAfter={"分钟"} onChange={e => {

                                                var reg = /^[0-9]*[1-9][0-9]*$/
                                                let val = e.target.value===''?0:e.target.value;
                                                if (reg.test(val)) {
                                                    store.error = false
                                                    setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: Number(val) } })
                                                } else {
                                                    store.error = true
                                                    setDevice({ ...device, Params: { ...device.Params, AlmStoreInt: val } })
                                                }
                                            }}/>
                                            {
                                                setState>0&&
                                                <Button onClick={() => {
                                                    devices.type = 9;
                                                    devices.data.map(m=>{
                                                        m.Params.NorStoreInt = device.Params.NorStoreInt
                                                        m.Params.AlmStoreInt = device.Params.AlmStoreInt
                                                    })
                                                    let devsTemp = JSON.parse(JSON.stringify(devices));
                                                    setDevices(devsTemp)
                                                }} disabled={Mode!=='soc'&&state.main.type === 104}>设置</Button>
                                            }
                                        </Space>
                                    </Col>

                                    

                                </Row>
                            </Spin>
                            </div>
                        </TabPane>
                        </Tabs>  
                    </TabPane>
               
                }
            </Tabs>  
            
            <Modal title='身份认证' centered visible={showAffirm} destroyOnClose={true} 
            onOk={()=>{
                let s = 0
                if(affirmPwd.startsWith("admin")){
                    
                    if(md5(affirmPwd.slice(5))===state.main.password){
                        // setSetState(2);
                        s=2;
                        setShowAffirm(false);
                    }else{
                        s=0;
                        // setSetState(0);
                        message.warn('密码错误');
                    }
                }else{
                    if(md5(affirmPwd)===state.main.password){
                        s=1;
                        // setSetState(1);
                        setShowAffirm(false);
                    }else{
                        s=0;
                        // setSetState(0);
                        message.warn('密码错误');
                    }
                }
                setSetState(s);
                props.changeSetState(s);
                dispatch({type:'super',super:s})
                dispatch({type:'setState',setState:s===0?0:1})
            }}  onCancel={()=>setShowAffirm(false)}>
                <Input.Password addonBefore='输入密码:' onChange={e=>setAffirmPwd(e.target.value)} />
            </Modal>
        </div>
    )
}

export default DeviceSet