import React, { useContext, useState, useEffect } from 'react'
import {  Button, Select, DatePicker, Slider, message, Spin, Cascader } from 'antd'
import { StateContext } from '../../../state';
import { Map, Marker, Polyline } from "react-amap";
import {
    FastBackwardOutlined,
    FastForwardOutlined,
    CaretRightOutlined,
    SwapOutlined,
    PauseOutlined, CloseOutlined, UpCircleTwoTone
} from '@ant-design/icons';
import moment from 'moment';
import http from '../../../../../utils/server'
import { Mode } from '../../../../../App'

// const Acccc = props=>{
//     const map = props._map_;
//     if(!map){
//         console.log('组件必须作为 Map 的子组件使用');
//         return;
//     }
//     map.plugins('AMap.Geocoder',()=>{
//         const geoloc = new window.AMap.Geocoder({});
//       console.log(geoloc);
//     })
// }
let interval = ''

function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};
function exportCsv(obj) {
    if (obj.length === 0) return
    // var title = obj.title;
    var titleForKey = Object.keys(obj.data[0]);
    var data = obj.data;
    var str = [];
    str.push(obj.title.join(",") + "\n");
    for (var i = 0; i < data.length; i++) {
        var temp = [];
        for (var j = 0; j < titleForKey.length; j++) {
            temp.push(data[i][titleForKey[j]]);
        }
        str.push(temp.join(",") + "\n");
    }
    var blob = new Blob(['\uFEFF' + str.join('')], {
        type: 'text/plain;charset=utf-8',
    });
    var downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "export.csv";
    downloadLink.click();
}
function MapData() {
    const { state, dispatch } = useContext(StateContext)
    const [loading, setLoading] = useState(false);
    const [selDev, setSelDev] = useState(null);
    const [selDevice, setSelDevice] = useState([]);
    const [selNodes, setSelNodes] = useState([]);
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [data, setData] = useState([])
    const [dataF, setDataF] = useState([])
    const [mapCenter, setMapCenter] = useState({ longitude: 116, latitude: 39 });
    const [devP, setDevP] = useState({ longitude: 116, latitude: 39 });
    const [mapZoom, setMapZoom] = useState(4)
    const [mapMarkVisibel, setMapMarkVisibel] = useState(false)
    const [pLineVisibel, setPLineVisible] = useState(false);
    const [sliderStatus, setSilderStatus] = useState(1)
    const [sliderRun, setSilderRun] = useState([])
    const { RangePicker } = DatePicker;
    const [nodeFliters, setNodeFliters] = useState([]);
    const [markerRota, setMarkerRota] = useState(0);
    var userdistrictlist;
    useEffect(() => {
        interval = setInterval(() => {
            if (sliderStatus === 0) {
                // console.log(sliderRun, data.length - 1);
                let a = sliderRun
                if (a == data.length - 1) {
                    a = 0
                    setSilderStatus(1);
                    setSilderRun(0);
                } else {
                    a = a + 1
                }
                setSilderRun(a)
                datadik(data, a)
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [sliderStatus, sliderRun])
    useEffect(async () => {
        http.get("/v1/devicestatus", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'devicestate', devicestate: data.data })
            }
        })
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'device', device: data.data })
                let arrDev = new Array();
                data.data.filter(d => (d.type === 202 || d.type === 203||d.type ===209)&&new Date(d.expire).getTime()>new Date().getTime()).map(m => 
                    arrDev.findIndex(x=>x.label.includes(m.sn))===-1&&arrDev.push({ label: m.m_name+'-'+m.sn, value: m.id })
                );
                setNodeFliters(arrDev);
                setSelNodes(arrDev);
            }
        })
        await http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) });
            }
        })

    }, []);
    function datadik(list, end) {
        var newp = new Array();
        var marks = new Array();
        var timesamp = 0;
        // var lngc = 0, latc = 0
        list.map((item, index) => {
            if (index <= end) {
                var timestamp = new Date(item.data_time).getTime() / 1000;
                if (index === 0) {
                    timesamp = timestamp;
                } else {
                    timestamp = timestamp - timesamp;
                }
                // lngc = lngc + item.gps_lng
                // latc = latc + item.gps_lat
                newp.push({ longitude: item.gps_lng, latitude: item.gps_lat, sp: item.gps_speed, ag: item.gps_dir, tm: timestamp });
                marks.push({ position: { longitude: item.gps_lng, latitude: item.gps_lat } });
                setDevP({ longitude: item.gps_lng, latitude: item.gps_lat });
                setMarkerRota(item.gps_dir);
            }
        })
        setDataF(newp)
    }
    if (Mode==='soc') {
         //登录用户是普通用户
         if(state.main.type===104){
            const ud = state.district;
            userdistrictlist = new Array();
            ud.map(item => {
                var u = new Object;
                u.key = item.id;
                u.title = item.name;
                u.label = item.name;
                u.text = item.name;
                u.value =item.id;
                u.name = item.name;
                // u.id = item.id
                userdistrictlist.unshift(u);
            })
        }else if(state.systemuser.treeM){
            userdistrictlist = JSON.parse(JSON.stringify(state.systemuser.treeM));
            if (userdistrictlist && userdistrictlist.length > 0) {
                 addUserDistrict(userdistrictlist);
            }
        }
    }
    if (Mode!=='soc' && state.district) {
        userdistrictlist = new Array();
        state.district.map(item => {
            var u = new Object;
            u.key = item.id;
            u.title = item.name;
            u.label = item.name;
            u.text = item.name;
            u.value = item.id;
            u.name = item.name;
            // u.id = item.id
            userdistrictlist.push(u);
        })

    }
    function addUserDistrict(userlist) {
        for (var i = 0; i < userlist.length; i++) {
            if (!userlist[i].children) {
                let ud=[];
                userlist[i].id&& (ud = state.district.filter(x => x.user == userlist[i].id));//筛查userlist[i]创建的所有区域
                if (ud.length > 0) {
                    userlist[i].children = [];
                    ud.map(item => {
                        var u = new Object;
                        u.key = item.id;
                        u.title = item.name;
                        u.label = item.name;
                        u.text = item.name;
                        u.value = 'dis'+item.id;
                        u.name = item.name;
                        // u.id = item.id
                        userlist[i].children.unshift(u);
                    })
                }else{
                    // userlist[i].id&&(userlist[i].disabled=true);
                    userlist[i].id&&(userlist.splice(i,1));
                }
            } else {
                let ud=[];
                userlist[i].id&& (ud = state.district.filter(x => x.user == userlist[i].id));//筛查userlist[i]创建的所有区域
                if (ud.length > 0) {
                    ud.map(item => {
                        var u = new Object;
                        u.key = item.id;
                        u.title = item.name;
                        u.label = item.name;
                        u.text = item.name;
                        u.value = 'dis'+item.id;
                        u.name = item.name;
                        // u.id = item.id;
                        userlist[i].children.unshift(u);
                    })
                }
                addUserDistrict(userlist[i].children);
            }
        }
    }

    return (<>
        <Spin spinning={loading} size="large" tip="加载中...">
            <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10 }}>选择区域</span>
                <Cascader style={{ width: 300, marginLeft: 10 }}
                    options={userdistrictlist} expandTrigger="hover" placeholder="选择区域" autoFocus onChange={val => {
                        setSelNodes(nodeFliters);
                        setSelDev(null);
                        if(val.length===0) return
                        let arrDev = new Array();
                        state.device.filter(x => (x.type === 202 || x.type === 203||x.type === 209) && (x.district === val[val.length - 1].slice(3)*1)&&new Date(x.expire).getTime()>new Date().getTime()).sort((a, b) => a.sn - b.sn).map(m => {
                            arrDev.findIndex(x=>x.label.includes(m.sn))===-1&&arrDev.push({ label: m.m_name+'-'+m.sn, value: m.id });
                        })
                        if (arrDev.length === 0) {
                            message.info('该区域下暂无车载设备')
                            setSelNodes([]);
                        } else {
                            setSelNodes(arrDev);
                        }
                        
                    }} showSearch={(inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)} />

                <span style={{ fontSize: 14, marginLeft: 20 }}>选择测点</span>
                <Select
                    showSearch
                    style={{ width: 300, marginLeft: 10 }}
                    placeholder="选择测点"
                    options={selNodes}
                    value={selDev}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onSelect={(d) => {
                        setSilderRun(0);
                        setSilderStatus(1);
                        setData([]);
                        const sd = state.devicestate.filter(x => { return x.ID == d })
                        if (sd.length > 0) {
                            setSelDevice(sd[0])
                            setSelDev(d)
                            if(sd[0].Status.GPSLng!==0&&sd[0].Status.GPSLat!==0){
                                setMapCenter({ longitude: sd[0].Status.GPSLng, latitude: sd[0].Status.GPSLat });
                                setMarkerRota(sd[0].Status.GPSDir);
                            }
                            
                        } else {
                            setSelDevice([])
                            setSelDev('')
                        }
                    }}
                />

                <RangePicker style={{ marginLeft: 20,borderRadius:10 }}
                    allowEmpty={[false,true]}
                    ranges={{
                        今天: [moment().startOf('day'), moment()],
                        '前一天': [moment().subtract(1, 'days'), moment()],
                        '前三天': [moment().subtract(3, 'days'), moment()],
                        '前五天': [moment().subtract(5, 'days'), moment()],
                        '前七天': [moment().subtract(5, 'days'), moment()],
                        '一小时': [moment().subtract(1, 'hours'), moment()],
                        '三小时': [moment().subtract(3, 'hours'), moment()],
                        '五小时': [moment().subtract(5, 'hours'), moment()],
                        '六小时': [moment().subtract(6, 'hours'), moment()],
                    }}
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    onChange={(dates, dateStrings) => {
                        if(dates===null){
                            setStartTime('');
                            setEndTime('');
                        }else{
                            setStartTime(dateStrings[0]+':00');
                            dates[1]!==null&&setEndTime(dateStrings[1]+':00');
                        } 
                    }}
                />

                <div style={{ flex: 1 }}></div>
                {data.length > 0 && <Button style={{ marginRight: 5 }} onClick={() => {
                    exportCsv({
                        title: [
                            "id",
                            "device",
                            "save_time",
                            "node",
                            "data_time",
                            "temp",
                            "temp_h",
                            "temp_l",
                            "humi",
                            "humi_h",
                            "humi_l",
                            "gps",
                            "lbs_cell",
                            "lbs_id",
                            "gps_lng",
                            "gps_lat",
                            "gps_dir",
                            "gps_speed",
                            "power",
                            "battery",
                            "csq",
                            "alarm_status",
                            "temp_alarm",
                            "humi_alarm",
                            "temp_switch",
                            "humi_switch",
                            "humi_enable",
                            "name",
                            "districtname",
                            "user"],
                        data: [...data]
                    })
                }}>导出GPS数据</Button>}
                <Button type={"primary"}  style={{borderRadius:10}}
                    onClick={() => {
                    if (selDevice.Sn == undefined || selDevice.Sn == '' || selDevice.Node == undefined || selDevice.Node == '') return message.warning('未选择设备');
                    setLoading(true);
                    var now_date = new Date(); now_date.setHours(0); now_date.setMinutes(0); now_date.setSeconds(0);
                    let url = "/v1/history?device=" + selDevice.Sn + "&node=" + selDevice.Node + "&starttime=" ;
                    url+= startTime===''?formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime()):
                        endTime===''?startTime+"&endtime=" + formatDateTimes(new Date().getTime()):startTime+"&endtime=" +endTime;
                    // if (startTime === '') url = "/v1/history?device=" + selDevice.Sn + "&node=" + selDevice.Node + "&starttime=" + formatDateTimes(now_date.getTime()) + "&endtime=" + formatDateTimes(new Date().getTime())
                    fetch(url, { 
                        method: 'get',
                        credentials: 'include',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        })
                    }).then(response => response.json()).then((data) => {
                        if (data.code === 0) {
                            setLoading(false);
                            if(data.data===null)return message.warn('暂无数据');
                            const vgpsdata = data.data.filter(x => { return x.gps_lng !== 0 && x.gps_lat !== 0 &&(x.gps!==0||x.lbs_cell!==0)})
                            if(vgpsdata.length===0) return message.warn('暂无有效定位数据');
                            setMapCenter({ longitude: vgpsdata[0].gps_lng, latitude: vgpsdata[0].gps_lat });
                            // console.log(vgpsdata[0].gps_lng,vgpsdata[0].gps_lat);
                            setMapZoom(10);
                            setMapMarkVisibel(true);
                            datadik(vgpsdata, vgpsdata.length)
                            setSilderRun(0);
                            setSilderStatus(1);
                            setData(vgpsdata)
                            
                        }
                    })
                }}>查询</Button>
            </div>
            <div style={{ marginTop: 5, height: window.innerHeight - 140, position: 'relative' }}>
                {data.length > 0 ?
                    <div style={{ position: 'absolute', top: 10, right: 10, width: 350, background: 'rgba(255,255,255,0.8)', zIndex: 99, padding: 5 }}>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: 14, marginRight: 10 }}>时间轴</div>
                            <div style={{ flex: 1 }}></div>
                            <FastBackwardOutlined onClick={() => { setSilderStatus(2); setSilderRun(sliderRun === 0 ? data.length - 1 : sliderRun - 1); datadik(data, sliderRun === 0 ? data.length - 1 : sliderRun - 1) }} />
                            <FastForwardOutlined onClick={() => { setSilderStatus(3); setSilderRun(sliderRun === data.length - 1 ? 0 : sliderRun + 1); datadik(data, sliderRun === data.length - 1 ? 0 : sliderRun + 1) }} />
                            {sliderStatus > 0 ? <CaretRightOutlined onClick={() => { setSilderStatus(0);  setPLineVisible(true); }} /> : <PauseOutlined onClick={() => { setSilderStatus(1); }} />}
                            <SwapOutlined onClick={() => { setSilderStatus(4); setSilderRun(data.length - 1); datadik(data, data.length - 1) }} />
                            <CloseOutlined onClick={() => {
                                setPLineVisible(false);
                                setSilderStatus(1)
                            }} />
                        </div>
                        <Slider
                            style={{ flex: 1 }}
                            max={data.length - 1}
                            value={sliderRun}
                            onChange={value => {
                                setSilderRun(value)
                                datadik(data, value)
                            }}
                            tipFormatter={null}
                        />
                        <div>时间点：{sliderRun>=0&& data[sliderRun].data_time}</div>
                    </div> : null}
                <Map plugins={['ToolBar']} useAMapUI={true}
                events={{
                    created: ins => { 
                        console.log(ins);
                        
                    },
                    click: () => { console.log('You Clicked The Map') }
                    }} 
                    zoom={mapZoom} center ={mapCenter} 
                >
                    <Polyline
                        path={dataF} visible={pLineVisibel} showDir={true} style={{ strokeWeight: 8 }}
                    />
                    {/* <Markers
                    markers={dataMarks}
                    render={extData=>(<UpCircleTwoTone />)}
                /> */}
                    <Marker position={devP} visible={mapMarkVisibel}>
                        <UpCircleTwoTone style={{ fontSize: 25 }} rotate={markerRota} />
                    </Marker>
                </Map>
            </div>
        </Spin>
    </>
    )
}

export default MapData