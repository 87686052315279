import Login from "../page/login/Login"
import Loyout from "../page/layout/index"
import User from "../page/user/User"

import DashBoard from '../page/layout/page/DashBoard/index'
import History from '../page/layout/page/Monitor/History/index'
import MapData from '../page/layout/page/Monitor/Map/index'
import Alarm from '../page/layout/page/Monitor/Alarm/index'
import Data from '../page/layout/page/Monitor/Data/index'
import Plane from "../page/layout/page/Monitor/Data/plane"
import BackUP from '../page/layout/page/Monitor/BackUP/index'
import AreaManage from '../page/layout/page/Manage/Area/index'
import DeviceManage from '../page/layout/page/Manage/Device/index'
import HMIManage from '../page/layout/page/Manage/HMI/index'
import UserManage from '../page/layout/page/Manage/User/index'
import LocalUserManage from '../page/layout/page/Manage/LocalUser/index'
import VideoManage from '../page/layout/page/Manage/Video/index'
import TransportIndex from '../page/layout/page/Manage/Transport/index'
import AlarmUserManage from '../page/layout/page/Manage/AlarmUser/index'
import Operation from '../page/layout/page/Monitor/Operation/index'
// import Permission from '../page/layout/page/Manage/Permission/index'
// import PermissionType from '../page/layout/page/Manage/PermissionType/index'
import PayManage from '../page/layout/page/Monitor/Pay/index'
import Help from "../page/layout/page/Help/index"

export default [


    { path: "/login", name: "Login", component: Login },
    { path: "/404", name: "404", component: User },
    {
        path: "/",
        name: "Loyout",
        component: Loyout,
        auth: true,
        children: [
            { path: "/", name: "Loyout", component: DashBoard },
            { path: "/monitor/data", name: "Loyout", component: Data },
            { path: "/monitor/data/plane/:id", name: "Loyout", component: Plane },
            { path: "/monitor/alarm", name: "Loyout", component: Alarm },
            { path: "/monitor/map", name: "Loyout", component: MapData },
            { path: "/monitor/records", name: "Loyout", component: History },
            { path: "/monitor/operation", name: "Loyout", component: Operation },
            { path: "/monitor/backup", name: "Loyout", component: BackUP },
            { path: "/monitor/pay", name: "Loyout", component: PayManage },

            { path: "/manage/area", name: "区域管理", component: AreaManage },
            { path: "/manage/device", name: "Loyout", component: DeviceManage },
            { path: "/manage/transport", name: "Loyout", component: TransportIndex },
            { path: "/manage/hmi", name: "Loyout", component: HMIManage },
            { path: "/manage/user", name: "Loyout", component: UserManage },
            { path: "/manage/localuser", name: "Loyout", component: LocalUserManage },
            { path: "/manage/alarmuser", name: "Loyout", component: AlarmUserManage },
            { path: "/manage/video", name: "Loyout", component: VideoManage },
            // { path: "/manage/permission", name: "Loyout", component: Permission },
            // { path: "/manage/permissiontype", name: "Loyout", component: PermissionType },

            { path: "/help", name: "Loyout", component: Help },
        ]
    },
];