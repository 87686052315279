import { Card, Collapse  } from "antd";
import React from "react";
import helpIndex from '../../../../../public/assets/help-index.png';
import apkDown from '../../../../../public/assets/apkDown.png';
import userInfo from '../../../../../public/assets/userInfo.png';
import indexAlarm from '../../../../../public/assets/indexAlarm.png';
import showData from '../../../../../public/assets/showData.png';
import showData1 from '../../../../../public/assets/showData1.png';
import indexHistory from '../../../../../public/assets/indexHistory.png';
import indexMap from '../../../../../public/assets/indexMap.png';
import helpHistory from '../../../../../public/assets/helpHistory.png';
import helpAlarmData from '../../../../../public/assets/helpAlarmData.png';
import helpLogData from '../../../../../public/assets/helpLogData.png';
import helpuser from '../../../../../public/assets/helpuser.png';
import helpuserAdd from '../../../../../public/assets/helpuserAdd.png';
import helpMangerAlarm from '../../../../../public/assets/helpMangerAlarm.png';
import helpMangerAlarmAdd from '../../../../../public/assets/helpMangerAlarmAdd.png';


const { Panel } = Collapse;
function Help(params) {
    return(
        <div>
            <Collapse>
                <Panel header="首页" key="1">
                    <Card title='首页' cover={<img alt="首页" src={helpIndex} />}>
                        <div>
                            <h3>导航栏点击不同的菜单跳转到对应页面</h3>
                        </div>
                        <div>
                            <h3>标题栏左边展示公司logo和名称 右侧显示声音报警开关，app下载，账号信息等</h3>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                <Card title='apk下载' cover={<img alt="apk下载" src={apkDown} />} style={{width:300}}>
                                </Card>
                                <Card title='账号信息' cover={<img alt="账号信息" src={userInfo} />} style={{width:500}}>
                                    <p>单击账号名称弹出账号信息窗口，显示http接口所用token和修改密码、退出登录等操作</p>
                                </Card>               
                            </div>
                        </div>
                        <div>
                            <h3>内容区分为信息统计和当日报警展示两大类</h3>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                <Card title='信息统计类型有'  style={{width:200}}>
                                    <p>设备在线比例</p>
                                    <p>设备总数</p>
                                    <p>在线设备数量</p>
                                    <p>当日报警数量</p>
                                    <p>短信条数</p>
                                </Card>
                                <Card title='当日报警' cover={<img alt="报警信息" src={indexAlarm} />} style={{width:900}}>
                                    <p>当有设备发生报警时，此页面显示报警设备信息，点击右侧处理报警按钮，可以记录报警产生原因，处理人员，处理方法</p>
                                </Card>               
                            </div>
                        </div>
                    </Card>
                </Panel>
                <Panel header="数据监控-实时数据-卡片视图" key="2">
                    <Card title='数据监控-实时数据监控' cover={<img alt="实时数据监控" src={showData} />}>
                        <div >
                            <h3>内容区分为菜单栏和实时数据展示两大类</h3>
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                <Card title='菜单栏'  style={{width:600}}>
                                    <p>从左到右依次为隐藏/显示用户导航、视图切换、设备信息检索、区域显示总开关、展示全部设备复选框(默认显示在线设备)、自动刷新、手动刷新</p>
                                </Card>
                                <Card title='实时数据展示' >
                                    <p>区域展示和区域下测点数据展示</p>
                                </Card>               
                            </div>
                        </div>
                    </Card>
                </Panel>
                <Panel header="数据监控-实时数据-列表视图" key="3">
                    <Card title='列表视图' cover={<img alt="列表视图" src={showData1} />} >
                        <p>通过菜单栏上左侧的视图切换按钮进行列表和卡片视图切换</p>
                    </Card>
                </Panel>
                <Panel header="数据监控-实时数据-历史数据查询" key="4">
                    <Card  cover={<img alt="历史数据查询" src={indexHistory} style={{width:'80%'}}/>} >
                        <p>当点击历史数据按钮时，进入历史数据对话框，可进行单测点历史记录（曲线、图表）的查询、导出等</p>
                        <p>上方左侧显示测点的名称、主机、用户和区域的信息。右侧可进行查询时间选择。不选择默认为当日。数据间隔选择、查询、导出(图片、EXCEL、PDF)</p>
                        <p>下方是内容展示方式为:曲线或表格</p>
                    </Card>
                </Panel>
                <Panel header="数据监控-实时数据-参数设置（管理员权限用户）" key="5">
                    <Card title='参数查询/设置'  >
                        <p>找到要设置参数设备，双击设备显示区域进行参数查询/设置</p>
                        <p>设置参数时，要先读取，再设置，避免设置错误。温湿度报警数据会自动读取可直接设置</p>
                        <p>超标报警设置起始时间99:99结束时间99:99，为此组报警全天报警，起始时间0:0结束时间0:0为此组报警关闭</p>
                    </Card>
                </Panel>
                <Panel header="数据监控-实时数据-轨迹回放" key="6">
                    <Card  cover={<img alt="轨迹数据查询" src={indexMap} style={{width:'80%'}}/>} >
                        <p>可通过单击定位标志进入轨迹回放界面。</p>
                        <p>默认时间为当天、可选择开始结束时间或者单击预设时间段进行查询和轨迹回放</p>
                    </Card>
                </Panel>
                <Panel header="数据监控-历史数据" key="7">
                    <Card  cover={<img alt="历史数据查询" src={helpHistory} style={{width:'80%'}}/>} >
                        <p>点击右侧导航历史数据，进入设备历史数据查询、导出界面（EXCEL、PDF、图片三种形式）</p>
                        <p>可根据测点和主机两种方式查询:测点模式可自由选择测点数量,主机模式为主机下所有测点。</p>
                        <p>默认时间为当天、可选择开始结束时间或者单击预设时间段进行查询和轨迹回放</p>
                        <p>默认普通报表形式，勾选周期报表，可选择月周日三种形式报表，可自由设定每天的时间节点。</p>
                    </Card>
                </Panel>
                <Panel header="数据监控-轨迹回放" key="8">
                    <Card >
                        <p>点击右侧导航轨迹位置，进入轨迹位置查询界面，可通过区域和测点选择想要查看的设备</p>
                        <p>和实时数据-轨迹回放类似</p>
                    </Card>
                </Panel>
                <Panel header="数据监控-报警数据" key="9">
                    <Card  cover={<img alt="报警数据查询" src={helpAlarmData} style={{width:'80%'}}/>} >
                        <p>点击左侧导航栏报警数据进入报警数据管理页面，默认展示当日报警数据</p>
                        <p>可根据用户、区域、时间（默认时间为当天、可选择开始结束时间或者单击预设时间段）进行报警信息内容查询</p>
                        <p>展示内容可根据用户、区域、主机、测点、类型、状态等方式进行筛选或排序</p>
                        <p>未处理报警可选中前方复选框（可多选），单击报警处理进行处理。也可下载导出报警数据PDF</p>
                    </Card>
                </Panel>
                <Panel header="数据监控-操作日志" key="10">
                    <Card  cover={<img alt="操作日志查询" src={helpLogData} style={{width:'80%'}}/>} >
                        <p>点击左侧导航栏操作日志进入操作日志管理页面，可根据用户、操作类型、及时间进行操作日志内容查询。</p>
                    </Card>
                </Panel>
                <Panel header="数据监控-备份管理" key="11">
                    
                </Panel>
                <Panel header="管理-订单管理" key="12">
                    
                </Panel>
                <Panel header="管理-视频管理" key="13">
                    
                </Panel>
                <Panel header="管理-用户管理" key="14">
                    <p style={{fontWeight:'bold'}}>点击左侧菜单栏 管理下“用户管理”可进行用户账号管理包括新增用户,修改用户名称,修改密码,删除用户（删除需谨慎，用户下有设备、区域、下级用户，订单时无法删除）、区域管理、区域分配、短信分配、添加提醒 、设置LOGO。</p>
                    <Card  cover={<img alt="用户管理" src={helpuser} style={{width:'80%'}}/>} >
                        <p>上方左侧单击选择用户 根据用户筛选相关用户信息</p>
                        <p>上方右侧为新建用户按钮  单击弹出新建用户窗口</p>
                        <div style={{display:'flex'}}>
                            <img src={helpuserAdd} alt="新建用户" style={{width:'20%'}} />
                            <div>
                                <p>1、单击上级用户右侧选择框在下拉列表中选择上级用户</p>
                                <p>2、填写用户名称</p>
                                <p>3、建立新组织需要勾选创建新组织，在下方所属组织一栏填写组织名称</p>
                                <p>4、已存在组织中添加其他类型账号，不要勾选创建新组织，下方所属组织一栏会显示组织名称不能修改</p>
                                <p>5、填写用户登录账号</p>
                                <p>6、填写用户登录密码</p>
                                <p>7、确认登录密码</p>
                                <p>8、选择用户类型。用户类型分为高级用户、管理用户、和普通用户。 注：新组织管理员只能是高级用户或管理用户。组织成员只能是管理用户或普通用户</p>
                                <p>9、选择权限 只能选择上级用户所拥有的的权限。1、基本权限：查看信息2、报警管理：添加删除修改报警联系人3、历史数据导出：导出历史数据为pdf、Excel等类型
                                    4、设置设备参数：能够在实时数据中 对进行基本、报警参数进行设置5、订单管理：订单的添加6、设备管理：设备的添加修改删除7、区域管理：区域的添加修改删除
                                </p>
                            </div>
                            
                        </div>    
                    </Card>
                </Panel>
                <Panel header="管理-报警管理" key="16">
                    <p style={{fontWeight:'bold'}}>点击左侧菜单栏 管理下“报警管理”可进行短信、微信报警联系人的添加修改删除操作</p>
                    <Card  cover={<img alt="报警管理" src={helpMangerAlarm} style={{width:'80%'}}/>} >
                        <p>上方左侧单击选择用户 根据用户筛选关联用户的报警联系人</p>
                        <p>上方右侧为添加报警联系人按钮  单击弹出添加报警联系人窗口</p>
                        <div style={{display:'flex'}}>
                            <img src={helpMangerAlarmAdd} alt="添加报警联系人" style={{width:'20%'}} />
                            <div>
                                <p>1、单击关联用户右侧选择框在下拉列表中选择关联用户</p>
                                <p>2、单击绑定区域右侧选择框在下拉列表中选择关联用户下的区域。可多选。选中区域内的设备发生相关报警时通知该联系人</p>
                                <p>3、填写联系人姓名 方便管理（查找、修改等）</p>
                                <p>4、填写短信号码：该号码用来接收设备报警短信。（确保号码正确和关联用户有短信条数）</p>
                                <p>5、单击短信报警类型右侧选择框在下拉列表中选择绑定区域内设备发生哪些类型的报警时推送报警短信（类型有：温度超标、湿度超标、温度预警、湿度预警、主机电源、主机	门控、设备离线、设备到期、探头连接、探头电源、探头门控）</p>
                                <p>6、打开微信扫一扫二维码关注公众号进行微信绑定。注：微信报警必须扫码绑定、二维码  有时效2分钟后过期 左侧为扫描关注成功状态</p>
                                <p>7、单击微信报警类型右侧选择框在下拉列表中选择绑定区域内设备发生哪些类型的报警时推送微信消息（类型有：温度超标、湿度超标、温度预警、湿度预警、主机电源、主机	门控、设备离线、设备到期、探头连接、探头电源、探头门控）</p>
                                <p>8、报警时段：默认为全天推送。单击右下角时间选择框，选择时段。可设置多个时段。选择时段后，只有相应时段内设备发生报警才会推送报警信息</p>
                                <p>9、延时：填写延时时间，单位为分。延时时间内，设备恢复正常则不推送报警信息，否则到达时间后推送报警。</p>
                                <p style={{color:'red'}}>注：只用短信报警、无须进行6、7操作;       只用微信报警、无须进行5操作</p>
                            </div>
                            
                        </div>    
                    </Card>
                </Panel>
            </Collapse>
        </div>
    )
}
export default Help;