import React from 'react'
import { Tabs } from 'antd'
import TansportManage from './tansport'
import AddressManage from './address'
import GoodsManage from './goods'
import TransuserManage from './transuser'

const { TabPane } = Tabs;

function TransportIndex() {
    return (
        <div>
            <div style={{textAlign:'center'}}>
                没有添加过收发货地址的:一、请先点击账号管理添加账号 二、点击地址管理添加地址和联系人 三、点击物品管理添加运送物品 四、最后添加订单
            </div>
            <Tabs style={{padding:10}}>
                <TabPane tab="订单管理" key="1">
                    <TansportManage />
                </TabPane>
                <TabPane tab="账号管理" key="2">
                    <TransuserManage />
                </TabPane>
                <TabPane tab="地址管理" key="3">
                    <AddressManage />
                </TabPane>
                <TabPane tab="物品管理" key="4">
                    <GoodsManage />
                </TabPane>
                
            </Tabs>
            
        </div>

    )
}

export default TransportIndex