import React, { useEffect, useState } from 'react';
import { Button, message, Input, Checkbox,Modal } from 'antd';
import './Login.less';
import { UserOutlined, UnlockOutlined } from '@ant-design/icons';
import http from "../../utils/server";
import md5 from 'js-md5';
import { useHistory } from 'react-router-dom';
import { Mode } from '../../App';
import backgroundJpg from '../../../public/assets/background.jpeg';
function Login() {
    let history = useHistory()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSaveLogin,setIsSaveLogin] = useState(false);
    // const [myConf,setMyConf] = useState({}); 
    const [modal, contextHolder] = Modal.useModal();
    const config = {
        title: '系统提示',
        content: (
        <>
            <div>端口打开失败</div>
        </>
        ),
    };
    let timerOut='';
    // let isSaveLogin = false;
    useEffect(()=>{
        if (window.CefSharp !== undefined) {
            setTimeout(() => {
                http.get('/s/getserver',{}).then(data=>{
                  if(data.code===0){
                    if(!data.data.PortStatus){
                        modal.error(config);
                        message.error("端口打开失败");
                    }
                  }
                })
              }, 60000);
            window.CefSharp.BindObjectAsync("bound");
            let myConf =window.bound.getConfig();
            setUsername(myConf.isSaveLogin?myConf.userLogin:'');
            setPassword(myConf.isSaveLogin?myConf.pwd:'');
            setIsSaveLogin(myConf.isSaveLogin);
            // isSaveLogin = myConf.isSaveLogin;
            
        }
    },[])
    useEffect(()=>{
        timerOut = setTimeout(() => {
            if(isSaveLogin){
                clearTimeout(timerOut);
                loginIn(username,password,true);
            }
        }, 5000);
    },[isSaveLogin,username,password])
    function loginIn(username, password,isAutoLogin) {
        clearTimeout(timerOut);
        if (username === '' || password === '' || username === undefined) {
            message.error({ content: '信息不完善', key: '1' });
        } else {
            http.post("/s/login", { account: username, password: md5(password) }).then(data => {
                if (data.code === 0) {
                    if (window.CefSharp !== undefined) {
                        window.CefSharp.BindObjectAsync("bound");
                        isAutoLogin?window.bound.setLoginMode(true,username,password):
                        window.bound.setLoginMode(false,username,password);
                        window.bound.getIsLogin&&window.bound.getIsLogin(true);
                        window.bound.setToken(data.data.token)
                    }
                    localStorage.setItem("token", data.data.token);
                    localStorage.setItem("tokenis", true);
                    // localStorage.setItem("type_",data.data.type );
                    // localStorage.setItem("role",data.data.role );
                    // localStorage.setItem("user_", data.data.name);
                    // localStorage.setItem("account_", data.data.account);
                    localStorage.getItem('show')===null&&localStorage.setItem('show','0');
                    message.success("登录成功");
                    history.push('/');
                } else {
                    message.error({ content: data.msg, key: '2' })
                    setIsSaveLogin(false)
                    // isSaveLogin = false;
                }
            })
        }
    }


    return (
        <div className="body1" style={{backgroundImage:`url(${backgroundJpg})`}}>
            <div style={{height:100,padding:20}}>
                <div><span style={{fontSize:40,color:'#fff'}}>{Mode !== 'soc'?"自动化监测软件":'温湿度监测云平台'}</span></div>
                <div style={{paddingLeft:80}}><span style={{fontSize:30,color:'#fff'}}>专业、专注</span></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' ,marginTop:150}}>
                <div style={{ flex: 1 }}></div>
                <div className='loginBox'>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>{Mode !== 'soc'?"自动化监测软件":'温湿度监测云平台V3.0'}</div>
                    {/* {Mode==='soc'&&<div ><a href="http://trs.hbpckj.com">订单查询系统</a></div>} */}
                    </div>
                    <br />
                    <br />
                    <Input
                        size={'large'}
                        prefix={<UserOutlined />}
                        placeholder='用户名'
                        value={username}
                        onChange={(e) => { setUsername(e.target.value) }}
                        autoFocus={true}
                        onPressEnter={(e)=>{
                            loginIn(document.getElementsByTagName('input')[0].value, document.getElementsByTagName('input')[1].value,isSaveLogin?true:false);
                        }}
                    />
                    <br />
                    <br />
                    <Input
                        size={'large'}
                        prefix={<UnlockOutlined />}
                        placeholder='密码'
                        value={password}
                        type='password'
                        onChange={(e) => setPassword(e.target.value)}
                        onPressEnter={(e)=>{
                            loginIn(document.getElementsByTagName('input')[0].value, document.getElementsByTagName('input')[1].value,isSaveLogin?true:false);
                         }}
                    />
                    <br />
                    <br />
                    {
                        Mode!=='soc'&&
                        <Checkbox checked={isSaveLogin} onChange={e=>{
                            clearTimeout(timerOut)
                            let temp = e.target.checked;
                            setIsSaveLogin(temp);
                            if (window.CefSharp !== undefined ) {
                                if(temp){
                                    timerOut=setTimeout(() => {
                                        loginIn(username, password,true)
                                    }, 2000);
                                }else{
                                    window.bound.setLoginMode(false,username,password);
                                }
                            }
                            
                        }}>自动登录</Checkbox>
                    }
                    {/* <p style={{ color: '#222', marginBottom: 0, cursor: 'pointer' }} >onClick={() => this.tabChange(2)}> 忘记密码? </p> */}
                    <br />
                    <br />
                    <br />
                    <Button size={'large'} type="primary" block onClick={() => loginIn(username, password,isSaveLogin?true:false)}>登录</Button>
                </div>
                <div style={{ flex: 1 }}></div>
            </div>
        </div>
    )
}
export default Login;