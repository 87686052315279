import React, { createContext, useContext, useEffect, useState, useReducer } from 'react'
import { Button, Input, Select, Modal, TreeSelect, Card, Steps, Tooltip, message, DatePicker, Slider, Row, Col, Tabs, Table} from 'antd'
import { StateContext } from '../../../state';
import http from '../../../../../utils/server'
import { ContainerTwoTone, CarTwoTone, CheckCircleTwoTone, ExclamationCircleOutlined,
    CloseCircleTwoTone, ProfileTwoTone, EditTwoTone, DeleteTwoTone, CaretRightOutlined,
    PauseOutlined, UpCircleTwoTone
} from '@ant-design/icons';
import { Map, Polyline, Marker } from "react-amap";
import { Line } from '@ant-design/charts';
import unLocation from '../../../../../../public/assets/unLocation.png'

const { Step } = Steps;

function SelectInput(props) {
    if(props.option){
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ ...props.style, padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}>{props.addonBefore}</div>
                <Select disabled={props.disabled} value={props.defaultValue} style={{ ...props.style, flex: 1 }} 
                    onChange={(value) => { props.onChange && props.onChange(value) }}
                    optionFilterProp="children" showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {props.option.map((item, index) => (
                        <Select.Option key={index} value={item[props.keyword]}>{props.text1?item[props.text]+"("+item[props.text1]+')':item[props.text]}</Select.Option>
                    ))}
                </Select>
            </div>
        )
    }else{
        return null
    }
    
}
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};

const TransportContext = createContext();

function TransportNew() {
    const { actTransport, dispatchTransport } = useContext(TransportContext)
    const { state } = useContext(StateContext)

    const [defExpend,setDefExpend]=useState([]);
    const [selUser,setSelUsers]=useState(0);
    // const [selDis,setSelDis]=useState('');
    const [devs,setDevs]=useState([]);//设备选项
    const [selDev,setSelDev]=useState('');
    useEffect(() => {
        var list = [];
        state.systemuser.list.filter(x=>x.parent===state.main.id).map(m=>list.push(m.id+""));
        setDefExpend(list);
        setSelUsers(state.main.id)
        if(actTransport.device){
            let selDev;
            state.device.filter(x=>x.id===actTransport.device).length>0&&
            (selDev=state.device.filter(x=>x.id===actTransport.device)[0]);
            selDev&&setSelDev(selDev);
            state.district.filter(x=>x.id===selDev.district).length>0&&
            setSelUsers(state.district.filter(x=>x.id===selDev.district)[0].user);
        }

    }, [])
    return (
        <div>
            <Input style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>订单编号</span>} value={actTransport.serial}
                onChange={e => { dispatchTransport({ type: 'update', transport: { ...actTransport, serial: e.target.value } }) }} 
                onBlur={e=>{
                    var temp = e.target.value;
                    if(temp.length<6) return message.error('订单编号最小长度为6');
                }}
            />
            <Input style={{ marginBottom: 5 }} addonBefore=" 订单名称" value={actTransport.name}
                onChange={(e) => { dispatchTransport({ type: 'update', transport: { ...actTransport, name: e.target.value } }) }} />
            <div style={{ display: 'flex' }}>
                <div style={{padding: '0 11px', paddingTop: 4, backgroundColor: '#fafafa', border: '1px solid #d9d9d9', borderRight: '0px', borderRadius: 2 }}><span><span style={{color:'red'}}>* </span>所在用户</span></div>
                <TreeSelect  style={{ flex: 1 }}  treeData={state.systemuser.treeM} treeDefaultExpandedKeys={defExpend} defaultValue={state.main.name} 
                    onSelect={value=>{

                        setSelUsers(value);
                        actTransport.users = value;
                        // setSelDis('');
                        setSelDev('');
                        dispatchTransport({ type: 'update', transport: { ...actTransport,device:'' } });
                    }} showSearch 
                    filterTreeNode ={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                >
                    
                </TreeSelect>
            </div>
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>所属区域</span>} defaultValue={selDev.district_name} option={state.district.filter(x=>x.user===selUser)} keyword={"id"} text='name'
                onChange={value => { 
                    dispatchTransport({ type: 'update', transport: { ...actTransport,  device:''} }) ;
                    let devs=[];
                    state.device.filter(x=>x.district===value).map(item=>{
                        devs.findIndex(x => x.sn === item.sn) === -1 && devs.push(item);
                    });
                    setDevs(devs);
                    // setSelDis(value);

                    }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>记录设备</span>} defaultValue={actTransport.device} option={devs} keyword={"id"} text='m_name' text1="sn"
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, device: value } }) }} />
                 <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>发货单位</span>} defaultValue={actTransport.fromuser} option={state.transuser&&state.transuser.filter(x=>x.type===901&&x.users===selUser)} keyword={"id"} text='name'
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, fromuser: value,from:'',fromaddressuser:''} }) }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>发货地址</span>} defaultValue={actTransport.from} option={state.address&&state.address.filter(x=>x.user===actTransport.fromuser&&x.type===401)} keyword={"id"} text={`address`}
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, from: value } }) }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>发货联系人</span>}defaultValue={actTransport.fromaddressuser} option={state.addressuser&&state.addressuser.filter(x=>x.user===actTransport.fromuser)} keyword={"id"} text={`name`}
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, fromaddressuser: value } }) }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>收货单位</span>}defaultValue={actTransport.touser} option={state.transuser&&state.transuser.filter(x=>x.type===902&&x.users===selUser)} keyword={"id"} text='name'
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, touser: value,to:'', toaddressuser:''} }) }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>收货地址</span>}defaultValue={actTransport.to} option={state.address&&state.address.filter(x=>x.user===actTransport.touser&&x.type===402)} keyword={"id"} text='address'
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, to: value } }) }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>收货联系人</span>} defaultValue={actTransport.toaddressuser} option={state.addressuser&&state.addressuser.filter(x=>x.user===actTransport.touser)} keyword={"id"} text={`name`}
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, toaddressuser: value } }) }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span> </span> 运输单位</span>} defaultValue={actTransport.tranuser} option={state.transuser&&state.transuser.filter(x=>x.type===903&&x.users===selUser)} keyword={"id"} text='name'
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, tranuser: value,tranaddressuser:'' } }) }} />
            {/* <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}}>* </span>运输方地址</span>} defaultValue={actTransport.tran} option={state.address.filter(x=>x.user===actTransport.tranuser&&x.type===403)} keyword={"id"} text='address'
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, tran: value } }) }} /> */}
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span > </span>运输联系人</span>} defaultValue={actTransport.tranaddressuser} option={state.addressuser&&state.addressuser.filter(x=>x.user===actTransport.tranuser)} keyword={"id"} text={`name`}
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, tranaddressuser: value } }) }} />
            <SelectInput style={{ marginBottom: 5 }} addonBefore={<span><span style={{color:'red'}} >*  </span>运输物品</span>} defaultValue={actTransport.goods} option={state.goods&&state.goods.filter(x=>x.users===selUser)} keyword={"id"} text='name'
                onChange={value => { dispatchTransport({ type: 'update', transport: { ...actTransport, goods: value } }) }} />
        </div>
    )
}

function TransportManage() {
    const { state, dispatch } = useContext(StateContext)
    const [actTransport, dispatchTransport] = useReducer((state, action) => {
        if (action.type === 'update') {
            return action.transport
        } else if (action.type === 'clear') {
            return {
                name: '',
                serial: '',
                start_time: formatDateTimes(new Date()),
                end_time: formatDateTimes(new Date()),
                device: '',
                users: action.users,
                from: '',
                to: '',
                goods: '',
                fromuser:'',
                touser:'',
                status:0,
                errmsg:'',
            }
        }
        return state
    }, []);
    const [showNewModel, setShowNewModel] = useState(false);//新建订单窗口
    const [showFixModel, setShowFixModel] = useState(false);//修改订单窗口
    const [showErrorModel, setShowErrorModel] = useState(false);//订单异常窗口
    const [showDetailModel, setShowDetailModel] = useState(false);//订单异常窗口
    const [querySerial, setQuerySerial] = useState('');//订单编号方式查询
    const [queryStatus, setQueryStatus] = useState(-1);//订单状态方式查询
    const [queryStartTime, setQueryStartTime] = useState('');//订单状态方式查询
    const [queryResult, setQueryResult] = useState([]);//订单查询结果
    const [dataMap, setDataMap] = useState([])//订单轨迹数据
    const [markP, setmarkP] = useState({ });//当前轨迹点
    const [mapCenter, setMapCenter] = useState({ longitude: 116, latitude: 39 });//地图中心
    const [mapLine, setMapLine] = useState([]);//订单轨迹线
    const [mapLine1, setMapLine1] = useState([]);//订单轨迹线
    const [markerRota, setMarkerRota] = useState(0);//订单轨迹角度
    const [mapZoom, setMapZoom] = useState(15);//地图缩放级别
    const [sliderStatus, setSilderStatus] = useState(0)
    const [sliderRun, setSilderRun] = useState([])
    const [dataHis, setDataHis] = useState([])//订单温湿度数据
    const [dataF, setDataF] = useState({ tmin: 0, tmax: 0, tavg: 0, hmin: 0, hmax: 0, havg: 0 })//订单温湿度数据 最大 最小 平均值
    const [dataChart, setdataChart] = useState([])//图表数据
    const [selNodeColums, setSelNodeColums] = useState([]);//表格中间列
    const [selNode, setSelNode] = useState([]);//设备的测点
    const [isLocation, setIsLocation] = useState(false);//是否定位

    const configtemp = {
        data: dataChart,
        padding: 'auto',
        xField: 'data_time',
        height: 200,
        padding: 'auto',
        yField: 'temp',
        seriesField:'name',
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    var i = v.indexOf('.');
                    return i===-1?v:v.slice(0,i+2);
                },
            },
            tickCount:10
        },
        slider: {
            start: 0,
            end: 1,
          },
    };
    const confighumi = {
        data: dataChart,
        padding: 'auto',
        xField: 'data_time',
        height: 200,
        padding: 'auto',
        yField: 'humi',
        seriesField:'name',
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    var i = v.indexOf('.');
                    return i===-1?v:v.slice(0,i+2);
                },
            },
            tickCount:10
        },
        slider: {
            start: 0,
            end: 1,
          },
    };
    const columnsLeft = [
        {
            title: '时间',
            dataIndex: 'data_time',
            key: 'id',
            align: 'center  ',
            width: 160,
            fixed: true,
        },
    ]
    const columnsRight = [

        {
            title: '电源',
            dataIndex: 'power',
            key: 'id',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <div>{record.power === 0 ? "正常" : "电池"}</div>
            )
        },
        {
            title: '电量',
            dataIndex: 'battery',
            key: 'id',
            align: 'center',
            width: 80,
        },
    ]

    let interval = ''
    let timerOut=null;//状态切换防抖
    function datadik(list, end) {
        if(end===list.length-1)return;
        setMapLine(list.slice(end+1));
        setMapLine1(list.slice(0,end+1));
        if(end===list.length-1)return;
        var ll = 0.1;
        mapZoom>15?ll=0.05:ll=0.1;
        if(Math.abs(list[end+1].longitude-mapCenter.longitude)>ll||Math.abs(list[end+1].latitude-mapCenter.latitude)>ll){
            setMapCenter({ longitude: list[end+1].longitude, latitude: list[end+1].latitude });
        };
        setmarkP({ longitude: list[end+1].longitude, latitude: list[end+1].latitude });
        setMarkerRota(list[end+1].ag);
    }
    useEffect(() => {
        //联系人
        http.get("/v1/transaddressuser", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'addressuser', addressuser: data.data })
            }else{
                dispatch({ type: 'addressuser', addressuser: [] })
            }
        })
        // 订单
        http.get("/v1/transport", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'transport', transport: data.data });
                setQueryResult(data.data);
            }else{
                dispatch({ type: 'transport', transport: [] });
            }
        })
        // 设备
        http.get("/v1/device", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'device', device: data.data })
            }
        })
        // 地址
        http.get("/v1/transaddress", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'address', address: data.data })
            }else{
                dispatch({ type: 'address', address: [] })
            }
        })
        // 运送物品
        http.get("/v1/transgoods", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'goods', goods: data.data })
            }else{
                dispatch({ type: 'goods', goods: [] })
            }
        })
        // 用户
        http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemsuer', systemsuer: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
            }
        })
        // 区域
        http.get("/v1/district", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'district', district: data.data })
            }
        })
        // 三方账号
        http.get("/v1/transuser", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'transuser', transuser: data.data })
            }else{
                dispatch({ type: 'transuser', transuser: [] })
            }
        })
        
    }, [])
    useEffect(() => {
        interval = setInterval(() => {
            if (sliderStatus > 0) {
                let a = sliderRun
                if (a === dataMap.length - 1) {
                    a = 0
                    setSilderStatus(0);
                } else {
                    a = a + 1
                }
                setSilderRun(a);
                datadik(dataMap, a);
            }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [sliderStatus, sliderRun])
    return (
        <div>
            
            <div style={{display:'flex',justifyContent:'space-between',marginBottom:20}}>
                <div style={{display:'flex',flex:'row',alignItems:'center'}}>
                    <div style={{width:100}}><span>订单编号:</span></div>
                    <Input onChange={e=>setQuerySerial(e.target.value)}/>
                </div>
                <div style={{display:'flex',flex:'row',alignItems:'center',}}>
                    <div style={{width:80}}><span>订单状态:</span></div>
                    <Select style={{width:150}} onChange={value=>setQueryStatus(value*1)}>
                        <option value='-1'>------</option>
                        <option value='0'>订单未开始</option>
                        <option value='1'>订单运送</option>
                        <option value='2'>订单结束</option>
                        <option value='3'>订单异常</option>
                    </Select>
                </div>
                <div style={{display:'flex',flex:'row',alignItems:'center'}}>
                    <div style={{width:100}}><span>开始时间:</span></div>
                    <DatePicker showTime style={{borderRadius:10}} onChange={(value, dateString)=>setQueryStartTime(dateString)}/>
                </div>
                <Button style={{backgroundColor:'#06F',color:'#fff',borderRadius:10}} 
                    onClick={() => {
                        var temp= state.transport;
                        querySerial!==''&&(temp=temp.filter(x=>x.serial===querySerial));
                        queryStatus!==-1&&(temp=temp.filter(x=>x.status===queryStatus));
                        queryStartTime!==''&&(temp=temp.filter(x=>x.start_time>queryStartTime));
                        setQueryResult(temp);
                }}>查询</Button>
                {/* 新建订单按钮 */}
                <Button style={{backgroundColor:'#06F',color:'#fff',borderRadius:10}} 
                    onClick={() => {
                    setShowNewModel(true);
                    dispatchTransport({ type: 'clear',users:state.main.id })
                }}>新建订单</Button>
            </div>
            {/* 订单展示 */}
            <div>
            {
                queryResult.sort((a,b)=>b.id-a.id).map((item,index)=>(
                    <Card title={
                        <div style={{display:'flex',flex:'row',justifyContent:'space-between',marginRight:20}}>
                            <div><span>订单编号:{item.serial}</span></div>
                            <div>订单状态:订单{item.status===3?'异常':item.status===1?'派送中':item.status===2?'结束':'未开始'}</div>
                            <div>订单名称:{item.name}</div>
                        </div>} key={index} headStyle={{ backgroundColor:'#0066FF',color:'#fff'}}
                        extra={
                            <div>
                                <Tooltip placement="top" title='详细信息'><Button ghost icon={<ProfileTwoTone twoToneColor='#bbb'/>} onClick={()=>{
                                    setShowDetailModel(true);dispatchTransport({ type: 'update',transport:item});
                                    let dev = state.device.filter(x=>x.id===item.device)[0];
                                    let nodes=[];
                                    let strNode = ''
                                    state.device.filter(x=>x.sn===dev.sn&&x.node!==0).map(item=>{
                                        nodes.push(item);
                                        strNode+=item.node+','
                                    })
                                    strNode = strNode.slice(0,-1);
                                    // let url = "/v1/history?device=" + dev.sn + "&node=" + dev.node + "&starttime=" + item.start_time + "&endtime=";
                                    let url = "/v1/transhistory?device=" + dev.sn + "&node=" + strNode + "&starttime=" + item.start_time + "&endtime=";
                                    item.status>1?url+=item.end_time:url+=formatDateTimes(new Date());
                                    http.get(url,{})
                                    .then((data) => {
                                        if (data.code === 0) {
                                            if(data.data===null) return message.warn('暂无数据');
                                            let realData = data.data.filter(x=>x.node!==0)
                                            setDataHis(realData);
                                            let gpsdata = realData.filter(x => { return x.gps_lng !== 0 && x.gps_lat !== 0&&(x.gps!==0||x.lbs_cell!==0) }) ;
                                            //创建表格中间列
                                            let nodeCols=[];
                                            nodes[0].humi_switch===0?                               
                                            nodes.map(item=>{
                                                nodeCols.push({
                                                    title: `主机:${item.device}-测点ID:${item.node})`,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '温度(℃)',
                                                            dataIndex: 'temp' + item.node,
                                                            align: 'center',
                                                            width: 100,
                                                            render: (text, record) => (
                                                                <div>
                                                                    <div>{ record['temp_switch' + item.node] === 0 ? "--" : record['temp' + item.node] > 102.1 ? " NL" :record['temp' + item.node]}</div>
                                                                    {record['temp_switch' + item.node] === 0 ? null : <div style={{ fontSize: 10, color: '#aaa' }}>上限:{record['temp_h' + item.node]}-下限:{record['temp_l' + item.node]}</div>}
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            title: '状态',
                                                            dataIndex: 'temp_alarm' + item.node,
                                                            align: 'center',
                                                            width: 60,
                                                            render: (text, record) => (
                                                                <div>{record['temp_switch' + item.node] === 0 ? "--" :record['temp_alarm' + item.node] === 0 ? '正常' : '报警'}</div>
                                                            )
                                                        },
                                                    ]
                                                })
                                            }):
                                            nodes.map(item=>{
                                                nodeCols.push({
                                                    title: `${item.name}-(区域:${item.district_name}-主机:${item.m_name}-测点ID:${item.node})`,
                                                    align: 'center',
                                                    children: [
                                                        {
                                                            title: '温度(℃)',
                                                            dataIndex: 'temp' + item.node,
                                                            align: 'center',
                                                            render: (text, record) => (
                                                                <div>
                                                                    <div>{ record['temp_switch' + item.node] === 0 ? "--" : record['temp' + item.node] > 102.1 ? " NL" :record['temp' + item.node]}</div>
                                                                    {record['temp_switch' + item.node] === 0 ? null : <div style={{ fontSize: 10, color: '#aaa' }}>上限:{record['temp_h' + item.node]}-下限:{record['temp_l' + item.node]}</div>}
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            title: '状态',
                                                            dataIndex: 'temp_alarm' + item.node,
                                                            align: 'center',
                                                            width: 60,
                                                            render: (text, record) => (
                                                                <div>{record['temp_switch' + item.node] === 0 ? "--" :record['temp_alarm' + item.node] === 0 ? '正常' : '报警'}</div>
                                                            )
                                                        },
                                                        {
                                                            title: '湿度(%RH)',
                                                            dataIndex: 'humi' + item.node,
                                                            align: 'center',
                                                            render: (text, record) => (
                                                                <div>
                                                                    <div>{ record['humi_switch' + item.node] === 0 ? "--" : record['humi_enable' + item.node]===0 ?"--":record['humi' + item.node] > 102.1 ? " NL" : record['humi' + item.node]}</div>
                                                                    {record['humi_switch' + item.node] === 0 || record['humi_enable' + item.node]===0 ? null : <div style={{ fontSize: 10, color: '#aaa' }}>上限:{record['humi_h' + item.node]}-下限:{record['humi_l' + item.node]}</div>}
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            title: '状态',
                                                            dataIndex: 'humi_alarm' + item.node,
                                                            width: 60,
                                                            align: 'center',
                                                            render: (text, record) => (
                                                                <div>{record['humi_switch' + item.node] === 0 ? "--" : record['humi_enable' + item.node]===0 ?"--" :record['humi_alarm' + item.node] == 0 ? '正常' : '报警'}</div>
                                                            )
                                                        },
                                                    ]
                                                })
                                            })
                                            setSelNodeColums(nodeCols)
                                            setSelNode(nodes);
                                            //表格数据
                                            let tableData = [];
                                            realData.sort((a,b)=>a.id-b.id).map(item => {
                                                let index = tableData.findIndex(x=>x.data_time===item.data_time);
                                                if(index===-1){
                                                    tableData.push({
                                                        battery:item.battery,
                                                        csq:item.csq,
                                                        data_time:item.data_time,
                                                        ['humi'+item.node]:item.humi,
                                                        ['humi_alarm'+item.node]:item.humi_alarm,
                                                        ['humi_enable'+item.node]:item.humi_enable,
                                                        ['humi_h'+item.node]:item.humi_h,
                                                        ['humi_l'+item.node]:item.humi_l,
                                                        ['humi_switch'+item.node]:item.humi_switch,
                                                        ['temp'+item.node]:item.temp,
                                                        ['temp_alarm'+item.node]:item.temp_alarm,
                                                        ['temp_h'+item.node]:item.temp_h,
                                                        ['temp_l'+item.node]:item.temp_l,
                                                        ['temp_switch'+item.node]:item.temp_switch
                                                    })
                                                }else{
                                                    tableData[index]['humi'+item.node]=item.humi;
                                                    tableData[index]['humi_alarm'+item.node]=item.humi_alarm;
                                                    tableData[index]['humi_enable'+item.node]=item.humi_enable;
                                                    tableData[index]['humi_h'+item.node]=item.humi_h;
                                                    tableData[index]['humi_l'+item.node]=item.humi_l;
                                                    tableData[index]['humi_switch'+item.node]=item.humi_switch;
                                                    tableData[index]['temp'+item.node]=item.temp;
                                                    tableData[index]['temp_alarm'+item.node]=item.temp_alarm;
                                                    tableData[index]['temp_h'+item.node]=item.temp_h;
                                                    tableData[index]['temp_l'+item.node]=item.temp_l;
                                                    tableData[index]['temp_switch'+item.node]=item.temp_switch;
                                                }
                                            })
                                            setDataHis(tableData.reverse());
                                            let ar = realData.filter(x => x.temp<102&&x.humi<102);
                                            let arLen = ar.length;
                                            if(arLen===0) return message.warn('暂无有效数据');
                                            var tavg = 0
                                            var tmax = -100
                                            var tmin = 100
                                            var havg = 0
                                            var hmax = 0
                                            var hmin = 100
                                            ar.map(item => {
                                                item.name="测点"+item.node;
                                                if(item.humi_switch!==0&&item.humi_enable!==0){
                                                    item.legendName="湿度";
                                                }
                                                if (item.temp > tmax) {
                                                    tmax = item.temp
                                                }
                                                if (item.temp < tmin) {
                                                    tmin = item.temp
                                                }
                                                tavg = tavg + item.temp
                                                if (item.humi > hmax) {
                                                    hmax = item.humi
                                                }
                                                if (item.humi < hmin) {
                                                    hmin = item.humi
                                                }
                                                havg = havg + item.humi
                                            }
                                            )
                                            tavg = tavg / arLen
                                            havg = havg / arLen
                                            setDataF({ tmax, tmin, tavg, hmin, hmax, havg });
                                            setdataChart(ar);
                                            if(gpsdata.length>0){
                                                var newp = [];
                                                gpsdata.map(item=>newp.push({ longitude: item.gps_lng, latitude: item.gps_lat, sp: item.gps_speed, ag: item.gps_dir, data_time:item.data_time ,gps:item.gps }))
                                                datadik(newp, 0);
                                                setSilderRun(0);
                                                setDataMap(newp);
                                                setMapLine(newp);
                                                setmarkP({longitude:gpsdata[0].gps_lng,latitude: gpsdata[0].gps_lat});
                                                setMapCenter({longitude:gpsdata[0].gps_lng,latitude: gpsdata[0].gps_lat});
                                                setIsLocation(true);
                                            }else{
                                                message.warn('暂无有效定位数据');
                                            }
                                            
                                        }
                                    })
                                }}/></Tooltip>
                                {/* <Tooltip placement="top" title='修改订单'><Button style={{marginLeft:5,marginRight:5}} ghost icon={<EditTwoTone twoToneColor='#bbb'/>} onClick={()=>{
                                    setShowFixModel(true);
                                    dispatchTransport({ type: 'update',transport:item});
                                }}/></Tooltip>
                                <Tooltip placement="top" title='删除订单'><Button ghost icon={<DeleteTwoTone twoToneColor='#bbb'/>} onClick={()=>{
                                    Modal.confirm({
                                        centered: true,
                                        title: `确定要删除订单<${item.serial}>吗？`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: `订单编号<${item.serial}>删除之后将不可恢复！！`,
                                        okText: '删除',
                                        okType: 'danger',
                                        cancelText: '取消',
                                        onOk() {
                                            http.delete("/v1/transport/" + item.id, { data: item }).then((data) => {
                                                http.get("/v1/transport", {}).then(data => {
                                                    if (data.code === 0) {
                                                        dispatch({ type: 'transport', transport: data.data });
                                                        setQueryResult(data.data);
                                                    }
                                                })
                                            })
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });
                                }} /></Tooltip> */}
                            </div>
                        }
                    >
                        <div style={{display:'flex',flex:'row',}}>
                            {/* 发货方信息 */}
                            <div style={{flex:1}}>
                                <div style={{marginTop:10}}><span>{`发货公司:${state.transuser&&state.transuser.filter(x=>x.id===item.fromuser).length>0?state.transuser.filter(x=>x.id===item.fromuser)[0].name:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`发货地址:${state.address.filter(x=>x.id===item.from).length>0?state.address.filter(x=>x.id===item.from)[0].address:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`联系人:${state.addressuser.filter(x=>x.id===item.fromaddressuser).length>0?state.addressuser.filter(x=>x.id===item.fromaddressuser)[0].name:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`联系电话:${state.addressuser.filter(x=>x.id===item.fromaddressuser).length>0?state.addressuser.filter(x=>x.id===item.fromaddressuser)[0].phone:''}`}</span></div>
                            </div>
                            {/* 订单运送信息 */}
                            <div style={{flex:3,marginLeft:50,marginRight:50}}>
                                {/* 运送设备 物品信息 操作按钮 */}
                                <div style={{display:'flex',flex:'row',justifyContent:'space-between'}}> 
                                    <div style={{marginTop:10}}><Button onClick={()=>{
                                        clearTimeout(timerOut);
                                        timerOut = setTimeout(() => {
                                            if(item.status===0){
                                                item.status=1;
                                            }else if(item.status===1){
                                                item.status=2;
                                            }
                                            http.put("/v1/transportstatus/" + item.id, item).then((data) => {
                                                http.get("/v1/transport", {}).then(data => {
                                                    if (data.code === 0) {
                                                        dispatch({ type: 'transport', transport: data.data })
                                                    }
                                                })
                                            })
                                        }, 500);
                                        
                                    }}>{item.status===1?'结束订单':item.status===0?'开始订单':null}</Button></div>
                                    <div style={{marginTop:10}}><span>{`运送设备:${state.device.filter(x=>x.id===item.device).length>0?state.device.filter(x=>x.id===item.device)[0].m_name:'' }`}</span></div>
                                    <div style={{marginTop:10}}><span>{`运送物品:${state.goods.filter(x=>x.id===item.goods).length>0?state.goods.filter(x=>x.id===item.goods)[0].name:'' }`}</span></div>
                                    <div style={{marginTop:10}}><Button onClick={()=>{setShowErrorModel(true); dispatchTransport({ type: 'update',transport:item});}}>{item.status>1?null:'异常订单'}</Button></div>
                                </div>
                                {/* 订单状态 */}
                                <div style={{display:'flex',flex:'row',marginTop:15,marginBottom:10}}> 
                                    <Steps current={item.status>2?2:item.status}  status={item.current===3&&'error'}>
                                        <Step title="准备中" icon={<ContainerTwoTone  twoToneColor='#06F'/>} />
                                        <Step title="运送中" icon={<CarTwoTone twoToneColor={item.status>0?'#06F':'#ccc'}/>} />
                                        <Step title={item.status===3?'异常':"完成"} icon={item.status===3?<CloseCircleTwoTone twoToneColor='red'/>:<CheckCircleTwoTone twoToneColor={item.status===2?'#06F':'#ccc'}/>} />
                                    </Steps>
                                </div>
                                {/* 开始结束时间 异常处理时间  异常原因 */}
                                <div style={{display:'flex',flex:'row',justifyContent:'space-between'}}>
                                    <div>{item.status>0?`开始时间:${item.start_time}`:''}</div>
                                    <div>{item.status===3?`订单异常:${item.errmsg}`:''}</div>
                                    <div>{item.status===3?`异常时间:${item.end_time}`:item.status===2?`结束时间:${item.end_time}`:`` }</div>
                                </div>
                            </div>
                            {/* 收货方信息 */}
                            <div style={{flex:1}}>
                                <div style={{marginTop:10}}><span>{`收货公司:${state.transuser&&state.transuser.filter(x=>x.id===item.touser).length>0?state.transuser.filter(x=>x.id===item.touser)[0].name:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`收货地址:${state.address.filter(x=>x.id===item.to).length>0?state.address.filter(x=>x.id===item.to)[0].address:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`联系人:${state.addressuser.filter(x=>x.id===item.toaddressuser).length>0?state.addressuser.filter(x=>x.id===item.toaddressuser)[0].name:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`联系电话:${state.addressuser.filter(x=>x.id===item.toaddressuser).length>0?state.addressuser.filter(x=>x.id===item.toaddressuser)[0].phone:''}`}</span></div>
                            </div>
                        </div>
                    </Card>
                ))
            }
            </div>
            {/* <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' dataSource={state.transport} /> */}
            
            <Modal maskClosable={false} title="新增订单" visible={showNewModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    if(actTransport.serial.length<6) return message.error('订单编号最小长度为6');
                    actTransport.tranaddressuser===''&&(actTransport.tranaddressuser=0)
                    http.post("/v1/transport", {...actTransport}).then((data) => {
                        http.get("/v1/transport", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'transport', transport: data.data })
                                setQueryResult(data.data);
                            }
                        })
                    })
                    // setShowNewModel(false)
                    // dispatchTransport({ type: 'clear'})
                }}
                onCancel={() => {
                    setShowNewModel(false)
                    dispatchTransport({ type: 'clear' })
                }}>
                <TransportContext.Provider value={{ actTransport, dispatchTransport ,state}}>
                    <TransportNew />
                </TransportContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="修改订单" visible={showFixModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    http.put("/v1/transport/" + actTransport.id, actTransport).then((data) => {
                        http.get("/v1/transport", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'transport', transport: data.data });
                                setQueryResult(data.data);
                            }
                        })
                    })
                    setShowFixModel(false);
                    dispatchTransport({ type: 'clear'});
                }}
                onCancel={() => {
                    setShowFixModel(false)
                    dispatchTransport({ type: 'clear' })
                }}>
                <TransportContext.Provider value={{ actTransport, dispatchTransport }}>
                    <TransportNew />
                </TransportContext.Provider>
            </Modal>
            <Modal maskClosable={false} title="订单异常" visible={showErrorModel}
                destroyOnClose
                cancelText='取消'
                okText='确定'
                onOk={() => {
                    actTransport.status=3;
                    http.put("/v1/transport/" + actTransport.id, actTransport).then((data) => {
                        http.get("/v1/transport", {}).then(data => {
                            if (data.code === 0) {
                                dispatch({ type: 'transport', transport: data.data });
                                setQueryResult(data.data);
                            }
                        })
                    })
                    setShowErrorModel(false);
                    dispatchTransport({ type: 'clear'});
                }}
                onCancel={() => {
                    setShowErrorModel(false)
                    dispatchTransport({ type: 'clear' })
                }}>
                <div>
                    <Input style={{ marginBottom: 5 }} addonBefore="异常原因" value={actTransport.errmsg}
                    onChange={(e) => { dispatchTransport({ type: 'update', transport: { ...actTransport, errmsg: e.target.value } }) }} />
                </div>
            </Modal>
            <Modal maskClosable={false} title="订单详情" visible={showDetailModel} width='100%' style={{ top: 20 }}
                destroyOnClose
                footer={null}
                onCancel={() => {
                    setShowDetailModel(false);
                    setSilderStatus(0);
                }}>
                    {/* 头部信息 */}
                <div >
                    <Card title={
                        <div style={{display:'flex',flex:'row',justifyContent:'space-between',marginRight:20}}>
                            <div><span>订单编号:{actTransport.serial}</span></div>
                            <div>订单状态:订单{actTransport.status===3?'异常':actTransport.status===1?'派送中':actTransport.status===2?'结束':'未开始'}</div>
                            <div>订单名称:{actTransport.name}</div>
                        </div>}  headStyle={{ backgroundColor:'#0066FF',color:'#fff'}}
                    >
                        <div style={{display:'flex',flex:'row',}}>
                            {/* 发货方信息 */}
                            <div style={{flex:1}}>
                                <div style={{marginTop:10}}><span>{`发货公司:${state.transuser&&state.transuser.filter(x=>x.id===actTransport.fromuser).length>0?state.transuser.filter(x=>x.id===actTransport.fromuser)[0].name:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`发货地址:${state.address.filter(x=>x.id===actTransport.from).length>0?state.address.filter(x=>x.id===actTransport.from)[0].address:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`联系人:${state.addressuser.filter(x=>x.id===actTransport.fromaddressuser).length>0?state.addressuser.filter(x=>x.id===actTransport.fromaddressuser)[0].name:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`联系电话:${state.addressuser.filter(x=>x.id===actTransport.fromaddressuser).length>0?state.addressuser.filter(x=>x.id===actTransport.fromaddressuser)[0].phone:''}`}</span></div>
                            </div>
                            {/* 订单运送信息 */}
                            <div style={{flex:3,marginLeft:50,marginRight:50}}>
                                {/* 运送设备 物品信息 操作按钮 */}
                                <div style={{display:'flex',flex:'row',justifyContent:'space-between'}}> 
                            
                                    <div style={{marginTop:10}}><span>{`运送设备:${state.device.filter(x=>x.id===actTransport.device).length>0?state.device.filter(x=>x.id===actTransport.device)[0].m_name:'' }`}</span></div>
                                    <div style={{marginTop:10}}><span>{`运送物品:${state.goods.filter(x=>x.id===actTransport.goods).length>0?state.goods.filter(x=>x.id===actTransport.goods)[0].name:'' }`}</span></div>
                                    
                                </div>
                                {/* 订单状态 */}
                                <div style={{display:'flex',flex:'row',marginTop:15,marginBottom:10}}> 
                                    <Steps current={actTransport.status>2?2:actTransport.status}  status={actTransport.current===3&&'error'}>
                                        <Step title="准备中" icon={<ContainerTwoTone  twoToneColor='#06F'/>} />
                                        <Step title="运送中" icon={<CarTwoTone twoToneColor={actTransport.status>0?'#06F':'#ccc'}/>} />
                                        <Step title={actTransport.status===3?'异常':"完成"} icon={actTransport.status===3?<CloseCircleTwoTone twoToneColor='red'/>:<CheckCircleTwoTone twoToneColor={actTransport.status===2?'#06F':'#ccc'}/>} />
                                    </Steps>
                                </div>
                                {/* 开始结束时间 异常处理时间  异常原因 */}
                                <div style={{display:'flex',flex:'row',justifyContent:'space-between'}}>
                                    <div>{actTransport.status>0?`开始时间:${actTransport.start_time}`:''}</div>
                                    <div>{actTransport.status===3?`订单异常:${actTransport.errmsg}`:''}</div>
                                    <div>{actTransport.status===3?`异常时间:${actTransport.end_time}`:actTransport.status===2?`结束时间:${actTransport.end_time}`:`` }</div>
                                </div>
                            </div>
                            {/* 收货方信息 */}
                            <div style={{flex:1}}>
                                <div style={{marginTop:10}}><span>{`收货公司:${state.transuser&&state.transuser.filter(x=>x.id===actTransport.touser).length>0?state.transuser.filter(x=>x.id===actTransport.touser)[0].name:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`收货地址:${state.address.filter(x=>x.id===actTransport.to).length>0?state.address.filter(x=>x.id===actTransport.to)[0].address:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`联系人:${state.addressuser.filter(x=>x.id===actTransport.toaddressuser).length>0?state.addressuser.filter(x=>x.id===actTransport.toaddressuser)[0].name:''}`}</span></div>
                                <div style={{marginTop:10}}><span>{`联系电话:${state.addressuser.filter(x=>x.id===actTransport.toaddressuser).length>0?state.addressuser.filter(x=>x.id===actTransport.toaddressuser)[0].phone:''}`}</span></div>
                            </div>
                        </div>
                    </Card>
                </div>
                
                <Row>
                    <Col span={24}>
                        <div>
                            
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 ,marginTop:20}}>
                                <div style={{ width: 150 }}>温度（℃）：</div>
                                <div style={{ width: 100 }}>上限值：{dataChart.length > 0 ? dataChart[0].temp_h : ''}</div>
                                <div style={{ width: 100 }}>下限值：{dataChart.length > 0 ? dataChart[0].temp_l : ''}</div>
                                <div style={{ width: 100 }}>最大值：{dataF.tmax.toFixed(2)}</div>
                                <div style={{ width: 100 }}>最小值：{dataF.tmin.toFixed(2)}</div>
                                <div style={{ width: 100 }}>平均值：{dataF.tavg.toFixed(2)}</div>
                            </div>

                            {
                                dataHis.length>0&&dataHis[0].humi_switch===1&&
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                                    <div style={{ width: 150 }}>相对湿度（%RH）：</div>
                                    <div style={{ width: 100 }}>上限值：{dataChart.length > 0 ? dataChart[0].humi_h : ''}</div>
                                    <div style={{ width: 100 }}>下限值：{dataChart.length > 0 ? dataChart[0].humi_l : ''}</div>
                                    <div style={{ width: 100 }}>最大值：{dataF.hmax.toFixed(2)}</div>
                                    <div style={{ width: 100 }}>最小值：{dataF.hmin.toFixed(2)}</div>
                                    <div style={{ width: 100 }}>平均值：{dataF.havg.toFixed(2)}</div>
                                </div>
                            } 
                        </div>
                        <Tabs>
                            <Tabs.TabPane tab="曲线显示" key="1">
                                <Row style={{ margin: '10px 0', border: '1px solid #f0f0f0', padding: 10 }}>
                                    <Col span={24}>
                                        <div>温度曲线</div>
                                        <Line {...configtemp} />
                                    </Col>
                                    {
                                        dataHis.length>0&&dataHis[0].humi_switch===1&&
                                        <Col span={24}>
                                            <div>湿度曲线</div>
                                            <Line {...confighumi} />
                                        </Col>
                                    }
                                </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="报表显示" key="2">
                                <Table bordered pagination={{ hideOnSinglePage: true,pageSize:5 }} rowKey={record=>record.id} 
                                 columns={[...columnsLeft, ...selNodeColums, ...columnsRight]}
                                 scroll={{ x: 300 + selNode.length * 240 }}
                                size='small' dataSource={dataHis} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="轨迹回放" key="3">
                            <div style={{ marginTop: 5, height: window.innerHeight - 500, position: 'relative' }}>
                            {dataMap.length > 0 ?
                                <div style={{ position: 'absolute', tob: 10, right: 5, width: 350, background: 'rgba(0,0,0,0.6)', zIndex: 2000, padding: 5 }}>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontSize: 14, marginRight: 10 ,color:'#fff'}}>时间轴</div>
                                        <div style={{ flex: 1 }}></div>
                                        {/* <FastBackwardOutlined onClick={() => { setSilderStatus(2); }} /> */}
                                        {/* <FastForwardOutlined onClick={() => { setSilderStatus(3); }} /> */}
                                        {sliderStatus > 0 ? <PauseOutlined style={{color:'#fff'}} onClick={() => { setSilderStatus(0) }} /> : <CaretRightOutlined style={{color:'#fff'}} onClick={() => { setSilderStatus(1) ;setMapZoom(10)}} />}

                                    </div>
                                    <Slider
                                        style={{ flex: 1 }}
                                        max={dataMap.length - 1}
                                        value={sliderRun}
                                        onChange={value => {
                                            setSilderRun(value)
                                            // datadik(dataMap, value)
                                        }}
                                        tipFormatter={null}
                                    /> 
                                    <div style={{color:'#fff'}}>时间点：{dataMap[sliderRun]&&dataMap[sliderRun].data_time}</div>
                                </div> : null
                            }

                            <Map plugins={['ToolBar']} center={mapCenter} events={{
                                // created: (ins) => { console.log(ins) },
                                // click: () => { console.log('You Clicked The Map') }
                            }} zoom={10}>
                                <Marker position={markP} >
                                {
                                    isLocation?
                                    <UpCircleTwoTone  style={{fontSize:25}} rotate={markerRota}/>
                                    :
                                    <img src={unLocation} style={{width:30,height:30}} />
                                }
                                </Marker>

                                <Polyline
                                    path={mapLine} showDir={true} style={{ strokeWeight: 8 }}
                                />
                                <Polyline
                                    path={mapLine1}  style={{ strokeWeight: 8,strokeColor:'#2AB9EE' }}
                                />
                            </Map>
                        </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
                
            </Modal>
        </div>
    )
}

export default TransportManage