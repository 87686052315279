import React, { useEffect, useState, useContext } from 'react'
import { Button, Menu, } from 'antd';
import menulist from './menu'
import { StateContext } from './state';
import { Link } from 'react-router-dom';
// import { MenuOutlined, AppstoreOutlined } from '@ant-design/icons';
import './silder.less'
import logo from '../../../public/assets/meihao.ico'
import logo1 from '../../../public/assets/meihao.jpg'
const { SubMenu } = Menu;
function Sider() {

    const [SiderWidth, setSiderWidth] = useState(200)
    const [SelectMenu, setSelectMenu] = useState(["1"]);
    const { state, dispatch } = useContext(StateContext)
    useEffect(() => {
        var path = window.location.hash
        for (var i = 0; i < menulist.length; i++) {

            if ('#' + menulist[i].path === path) {
                setSelectMenu([menulist[i].key])
                break
            }
            if (menulist[i].child) {
                for (var j = 0; j < menulist[i].child.length; j++) {
                    if ('#' + menulist[i].child[j].path === path) {
                        setSelectMenu([menulist[i].key])
                        break
                    }
                }
            }
        }
        // dispatch({ type: 'panes', panes:[{title:'仪表盘',content:menulist[0].path,key:menulist[0].key}] })
    }, [])
    return (
        <div style={{ transition: 'width .25s linear', backgroundColor: '#000C17', width: SiderWidth, display: 'flex', flexDirection: 'column' }} className="leftNav">
            <div style={{ height: 36 ,fontSize:22,color:'#fff',backgroundColor:'#002140' ,textAlign:'center',cursor:'pointer'}}onClick={() => {
                let temp = document.getElementsByClassName('tableshow');
                if (SiderWidth === 200) {
                    setSiderWidth(80);
                        temp.length>0&&(temp[0].className=temp[0].className+" tableshow1")
                } else {
                    setSiderWidth(200);
                    temp.length>0&&(temp[0].className=" tableshow")
                }
            }}>
                
                {
                    // SiderWidth === 200?
                    // <img width={120} src={logo1} />
                    // :
                    // <img width={32} src={logo} />
                    // <span>&nbsp;&nbsp;美 好 冠 群 </span>
                    SiderWidth === 200 ?'<':'>'
                }
            </div>
            {/* <div style={{ flex: 1 }}> */}
            <Menu  defaultSelectedKeys={SelectMenu} defaultOpenKeys={['2', '3']} mode="inline" theme='dark' inlineCollapsed={SiderWidth !== 200}>
                {
                    menulist.map((item, index) => { return(
                        item.u_type >= state.main.type && (item.child ?
                            <SubMenu key={item.key} icon={item.icon} title={item.name}>
                                {
                                    item.child.map((citem, cindex) => {  return (
                                        (!citem.auth || (citem.auth && state.main.type === 101)) && citem.soc && citem.u_type >= state.main.type && (
                                            <Menu.Item key={citem.key} icon={citem.icon} >
                                                <Link to={citem.path}>{citem.name}</Link>
                                                {/* <Button type="text" style={{color:'#fff'}} onClick={()=>{
                                                    let tpanes=state.panes;
                                                    console.log(tpanes.findIndex(x=>x.key===citem.key)===-1);
                                                    tpanes.findIndex(x=>x.key===citem.key)===-1&&
                                                    tpanes.push({title:citem.name,content:citem.path,key:citem.key});
                                                    dispatch({ type: 'panes', panes:tpanes })
                                                }}><Link to={citem.path}>{citem.name}</Link></Button> */}
                                            </Menu.Item>
                                        ) 
                                    )})
                                }
                            </SubMenu> :
                            <Menu.Item key={item.key} icon={item.icon}><Link to={item.path}>{item.name}</Link></Menu.Item>)

                    )})
                }
            </Menu>
            {/* </div> */}
            {/* <div onClick={() => { setSiderWidth(SiderWidth === 200 ? 80 : 200) }} style={{ height: 48, backgroundColor: '#001529', textAlign: 'center', padding: 12, cursor: 'pointer' }}>
                {
                    SiderWidth === 200 ?
                        <DoubleLeft theme="outline" size="24" fill="#fff" /> :
                        <DoubleRight theme="outline" size="24" fill="#fff" />
                }
            </div> */}
        </div>
    )
}

export default Sider